import Bullets from "../../../../../commons/bullets/Bullets";
import Button from "../../../../../commons/buttons/Button";
import Flex from "../../../../../commons/flex/Flex";
import { ReactComponent as CIconLeft } from "../../../../../assets/icons/chevron-left.svg";
import { ReactComponent as CIconRight } from "../../../../../assets/icons/chevron-right.svg";
import useTabs from "./useTabs";

interface ITabs {
  t: any;
  bookClassClick: () => void;
  catalogClick: () => void;
  myBooksClick: () => void;
}

export default function TabsMobile(props: ITabs) {
  const { tabs, onClickLeftArrow, onClickRightArrow, current } = useTabs(props);

  return (
    <>
      <Button onClick={onClickLeftArrow} custom="circle" icon={<CIconLeft />} />
      <Flex customClass="aCenter">
        <Bullets qty={tabs.length} defaultSelect={current} />
        <Flex customClass={"tabs"}>
          <Button selected={true} text={tabs[current].text} />
        </Flex>
      </Flex>
      <Button
        onClick={onClickRightArrow}
        custom="circle"
        icon={<CIconRight />}
      />
    </>
  );
}
