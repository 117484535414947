import IRequestCase from "../../types/IRequestCase";
import request from "../clientTatum";

const get = (email: string) => {
  return request.get(`/users/${email}`);
};

const post = (params: IRequestCase, session: string) => {
  return request.post(`/books`, params, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": session,
    },
  });
};

export default {
  get,
  post,
};
