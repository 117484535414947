import defaultImg from "../../../../assets/imgs/default.png";
import Flex from "../../../../commons/flex/Flex";
import MenuCard from "../../../../commons/menuCard/MenuCard";
import Body from "./body";
import Flag from "../../../../commons/flag/flag";
import useImage from "../../../../hooks/useImage";

interface ICard {
  t: any;
  title?: string;
  thumb?: string;
  author?: string;
  lang?: string;
  onClickCard: () => void;
  isVoted: boolean;
  isClassBook: boolean;
  isUserPackage: boolean;
  packageIdentifier: string;
  isPremium: boolean;
  isTeacher: boolean;
  onClickRemoveFromMyBooks: () => void;
  showMenu: boolean;
}

export default function Card(props: ICard) {
  const { imageDimensions } = useImage(props.thumb || defaultImg);

  return (
    <Flex onClick={props.onClickCard} customClass={"card"}>
      <Flex customClass={"column"}>
        <Flex customClass={"spaceBtw gap10 zIndex"}>
          <Flag lang={props.lang} />
          {props.isUserPackage && props.showMenu && (
            <MenuCard
              onClickRemoveFromMyBooks={props.onClickRemoveFromMyBooks}
              t={props.t}
            />
          )}
        </Flex>

        <figure className={`${imageDimensions.isWide ? "more" : ""}`}>
          <img
            className={`${imageDimensions.isWide ? "more" : ""}`}
            draggable="false"
            loading="lazy"
            src={props.thumb || defaultImg}
            alt="image"
            title="image"
          />
        </figure>

        <Body title={props.title} author={props.author} />
      </Flex>
    </Flex>
  );
}
