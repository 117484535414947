import { useEffect, useState } from "react";
import useManager from "../../../../hooks/useManager";
import IOption from "../../../../types/IOption";
import { isBrowser, isMobile } from "react-device-detect";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import Observer from "../../../../manager/Observer";
import INotify from "../../../../types/INotify";

export default function useHeader(props: any) {
  const manager = useManager();
  const { isPortrait } = useWindowDimensions();
  const initial: IOption[] = [
    { selected: true, text: props.t("moreRecents") },
    { selected: false, text: props.t("moreReadings") },
    { selected: false, text: props.t("orderAsc") },
    { selected: false, text: props.t("orderDesc") },
  ];
  const [data, setData] = useState<IOption[]>(initial);
  const [move, setMove] = useState<boolean>(false);

  useEffect(() => {
    const observer = new Observer();
    manager.get().addObserver(observer);
    observer.callback = (props: INotify) => {
      if (props.hasOwnProperty("expand")) setMove(!props.expand!);
    };
    return () => {
      manager.get().removeObserver(observer);
    };
  }, []);

  useEffect(() => {
    if (!isPortrait && move) {
      setMove(false);
    }
  }, [isPortrait]);

  return {
    data,
    isBrowser,
    isPortrait,
    isMobile,
    move,
  };
}
