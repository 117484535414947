import useHome from "./useHome";
import IRequestStatus from "../../types/IRequestStatus";
import Loader from "../../commons/loader/Loader";
import SideBar from "../../commons/sideBar/sideBar";
import Flex from "../../commons/flex/Flex";
import Header from "./components/header/header";
import Cards from "./components/cards/cards";
import HeaderMobile from "./components/header/headerMobile";
import Footer from "./components/footer/Footer";

export default function Home() {
  const {
    requestStatus,
    t,
    data,
    showTo,
    onClickFilterApply,
    packages,
    onFindChange,
    findValue,
    bookClassClick,
    catalogClick,
    myBooksClick,
    onSelectChange,
    avatar,
    isPremium,
    isTeacher,
    getMoreResults,
    showMenu,
    isMobile,
    initial,
    lang,
  } = useHome();

  if (requestStatus === IRequestStatus.PENDING) {
    return <Loader />;
  }

  return (
    <>
      <Flex customClass={"column"}>
        {isMobile && (
          <HeaderMobile
            t={t}
            bookClassClick={bookClassClick}
            catalogClick={catalogClick}
            myBooksClick={myBooksClick}
            onFindChange={onFindChange}
            findValue={findValue}
            avatar={avatar}
            onSelectChange={onSelectChange}
          />
        )}

        {!isMobile && (
          <Header
            t={t}
            bookClassClick={bookClassClick}
            catalogClick={catalogClick}
            myBooksClick={myBooksClick}
            onFindChange={onFindChange}
            findValue={findValue}
            avatar={avatar}
            onSelectChange={onSelectChange}
          />
        )}

        <Cards
          packages={packages}
          isTeacher={isTeacher}
          isPremium={isPremium}
          t={t}
          showTo={showTo}
          getMoreResults={getMoreResults}
          showMenu={showMenu}
          initial={initial}
        />
      </Flex>
      <SideBar t={t} onClickFilterApply={onClickFilterApply} data={data} />
      <Footer lang={lang} t={t} />
    </>
  );
}
