import { useContext, useEffect, useRef, useState } from "react";

import { useNavigate } from "react-router-dom";
import globalContext from "../../../../context/Context";
import IUser from "../../../../types/IUser";

import useManager from "../../../../hooks/useManager";

import useOutside from "../../../../hooks/useOutside";
import authz from "../../../../api/services/authz";

export default function useLogout() {
  const myRef = useRef(null);
  const manager = useManager();
  const navigate = useNavigate();

  const { user, setUser } = useContext(globalContext);
  const outSide = useOutside({ el: myRef, cls: "logout" });

  useEffect(() => {
    if (outSide) {
      manager.notify({ logout: false });
    }
  }, [outSide]);

  async function close(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    event.stopPropagation();
    authz
      .logout(user.session_id)
      .then(() => {
        closeSession();
      })
      .catch(() => {
        closeSession();
      });
  }

  function closeSession() {
    manager.notify({ logout: true });
    localStorage.removeItem("tatum_rps");
    setUser && setUser({} as IUser);
    navigate("/");
  }

  return {
    close,
    myRef,
  };
}
