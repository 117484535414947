import { useContext, useEffect, useState } from "react";
import IRequestStatus from "../../types/IRequestStatus";
import { useTranslation } from "react-i18next";
import packages from "../../api/services/packages";
import IPackages, { PackagesMetada } from "../../types/IPackages";
import IFilter from "../../types/IFIlter";
import globalContext from "../../context/Context";
import cases from "../../api/services/cases";
import useSort from "../../hooks/useSort";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { isMobile, isTablet } from "react-device-detect";
import ILanguage from "../../types/ILanguage";
import useUser from "../../hooks/useUser";

interface IState {
  requestStatus: IRequestStatus;
  serverError: string;
}

const FILTERS = [
  "areas",
  "subjects",
  "values",
  "education_levels",
  "typologies",
  "creators",
] as const;

export default function useHome() {
  const { t } = useTranslation();

  const windowDimensions = useWindowDimensions();

  const {
    sortPacakgesByMoreRecents,
    sortPacakgesAlphabetical,
    sortPacakgesByMoreReadings,
    preparePackageMetadata,
  } = useSort();

  const [data, setData] = useState<IPackages>();
  const [lazy, setLazy] = useState<number>(initWith().add);
  const [showTo, setShowTo] = useState<number>(initWith().add);
  const [multiFilter, setMultiFilter] = useState<IFilter[]>([]);
  const [packagesMetadata, setPackagesMetadata] = useState<PackagesMetada[]>(
    []
  );
  const [toggle, setToogle] = useState<PackagesMetada[]>([]);

  const [find, setFind] = useState<string>("");
  const { getCodeLanguage } = useUser();
  const { user, setUser } = useContext(globalContext);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<string>("0");
  const [state, dispatch] = useState<IState>({
    requestStatus: IRequestStatus.PENDING,
    serverError: "",
  });
  const [lastClick, setLastClick] = useState<string>("");

  useEffect(() => {
    setLazy(initWith().add);
    return () => {
      setLazy(initWith().add);
    };
  }, [windowDimensions]);

  useEffect(() => {
    cases
      .get(user.username)
      .then((resCase) => {
        packages
          .get(user.session_id)
          .then((result) => {
            const res = preparePackageMetadata(resCase, result, user);

            setData(res as IPackages);

            const _user = { ...user };

            _user.language_guid = result.data.data.languages.find(
              (lang: ILanguage) => {
                return lang.code.toLowerCase() === getCodeLanguage();
              }
            ).guid;

            setUser && setUser(_user);

            setPackagesMetadata(
              sortPacakgesByMoreRecents(result.data.data.packages_metadata)
            );
            setToogle(
              sortPacakgesByMoreRecents(result.data.data.packages_metadata)
            );

            dispatch({
              requestStatus: IRequestStatus.SUCCESS,
              serverError: state.serverError,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    refreshFilter();
    return () => {};
  }, [find, toggle]);

  useEffect(() => {
    if (data && data.packages_metadata) {
      sortByDropDown(data.packages_metadata, sortBy);
      if (lastClick === "user") {
        myBooksClick();
      }
      if (lastClick === "class") {
        bookClassClick();
      }

      refreshFilter();
    }

    return () => {};
  }, [multiFilter]);

  function initWith() {
    return {
      qty: Math.floor(windowDimensions.width! / 330),
      add: Math.floor(windowDimensions.width! / 330) + 10,
    };
  }

  function refreshFilter() {
    window.scrollTo({ top: 0, behavior: "smooth" });
    let result: any = [];
    let temp: any = [];

    const languages = getFilter("languages");
    const ages = getFilter("ages");
    const areas = getFilter("areas");
    const subjects = getFilter("subjects");
    const values = getFilter("values");
    const levels = getFilter("education_levels");
    const typologies = getFilter("typologies");
    const genres = getFilter("genres");

    if (languages.length) {
      languages.forEach((item) => {
        result = result.concat(
          toggle.filter(
            (res: PackagesMetada) => res.language_guid === item.guid
          )
        );
      });
    } else {
      result = result.length ? result : toggle;
    }

    if (ages.length) {
      ages.forEach((item) => {
        temp = result.filter(
          (res: PackagesMetada) => res.age_guid === item.guid
        );
      });
      result = temp;
    }

    if (genres.length) {
      genres.forEach((item) => {
        temp = result.filter(
          (res: PackagesMetada) => res.genre_guid === item.guid
        );
      });
      result = temp;
    }

    result = setFilter(levels, result, 3);
    result = setFilter(areas, result, 0);
    result = setFilter(subjects, result, 1);
    result = setFilter(values, result, 2);
    result = setFilter(typologies, result, 4);

    result = [...new Set(result)];

    if (find.length > 0) {
      result = result.filter(
        (res: PackagesMetada) =>
          res.title?.toLowerCase().includes(find.toLowerCase()) ||
          res.creators?.filter((item) =>
            item.name.toLowerCase().includes(find.toLowerCase())
          ).length > 0 ||
          res.collection?.toLowerCase().includes(find.toLowerCase())
      );
    }

    switch (sortBy) {
      case "0":
        result = sortPacakgesByMoreRecents(result);
        break;
      case "1":
        result = sortPacakgesByMoreReadings(result);
        break;
      default:
        result = sortPacakgesAlphabetical(result, sortBy);
    }

    setPackagesMetadata(result);
  }

  async function applyFilters(filters: IFilter[]) {
    setMultiFilter(filters.filter((item) => item.add === true));
  }

  function filterCommons(
    guid: string,
    dataFiltered: PackagesMetada[],
    index: number
  ) {
    const filteredData = dataFiltered?.filter((item: PackagesMetada) => {
      return item[FILTERS[index]].some((res) => {
        return res.guid === guid;
      });
    });

    return filteredData;
  }

  function getFilter(filter: string) {
    return multiFilter.filter((item) => item.tag === filter);
  }

  function setFilter(obj: any, result: PackagesMetada[], index: number) {
    let temp: any = [];
    if (obj.length) {
      obj.forEach((item: IFilter) => {
        temp = temp.concat(filterCommons(item.guid, result, index));
      });
      return temp;
    }
    return result;
  }

  async function onFindChange(value: string) {
    setFind(value);
  }

  function bookClassClick() {
    const result = data?.packages_metadata?.filter(
      (res: PackagesMetada) => res.classBook === true
    );
    sortByDropDown(result as PackagesMetada[], sortBy);
    setLastClick("class");
    setShowMenu(false);
  }

  function catalogClick() {
    setShowMenu(false);
    sortByDropDown(data?.packages_metadata as PackagesMetada[], sortBy);
    setLastClick("catalog");
  }

  function myBooksClick() {
    const result = data?.packages_metadata?.filter(
      (res: PackagesMetada) => res.userPackage !== false
    );
    setLastClick("user");

    sortByDropDown(result as PackagesMetada[], sortBy);
    setShowMenu(true);
  }

  async function onSelectChange(index: string) {
    setSortBy(index);
    sortByDropDown(packagesMetadata, index);
  }

  function sortByDropDown(data: PackagesMetada[], index: string) {
    let result: PackagesMetada[] = [];

    switch (index) {
      case "0":
        result = sortPacakgesByMoreRecents(data);
        break;
      case "1":
        result = sortPacakgesByMoreReadings(data);
        break;
      default:
        result = sortPacakgesAlphabetical(data, index);
    }

    setShowTo(initWith().add);
    setToogle(result);
  }

  function getMoreResults() {
    if (data && data.packages_metadata.length > showTo) {
      setShowTo(showTo + lazy);
    }
  }

  return {
    requestStatus: state.requestStatus,
    serverError: state.serverError,
    t: t,
    data: data as IPackages,
    packages: packagesMetadata,
    showTo: showTo,
    onClickFilterApply: applyFilters,
    onFindChange: onFindChange,
    findValue: find,
    bookClassClick: bookClassClick,
    catalogClick: catalogClick,
    myBooksClick: myBooksClick,
    avatar: user.avatar,
    onSelectChange,
    isTeacher: user.rol.toLowerCase().match(/teacher/) || false,
    isPremium: user.is_verified,
    getMoreResults,
    showMenu,
    isMobile: isMobile && !isTablet,
    initial: initWith().add,
    lang: user.language,
  };
}
