export const eu = {
  accept: "Onartu",
  annotations: "Notak",
  areas: "Arloak",
  authentication_error:
    "Datuak okerrak direla dirudi. Berrikusi eta saiatu berriz.",
  book_abstract: "Liburuaren laburpena",
  catalog_header: "Katalogoa",
  clear_filters: "Garbitu iragazkiak",
  collections: "Bildumak",
  bookmarks: "Markagailuak",
  books: "Liburuak",
  filter_catalog_option: "Katalogoa",
  filter_my_books_option: "Nire liburuak",
  filter_teacher_books_option: "Ikasgelako liburuak",
  filters_title: "Iragazi",
  genres: "Generoa",
  languages: "Hizkuntzak",
  login_with_google: "Hasi saioa Googlerekin",
  login_legend: "HASI SAIOA",
  login_submit: "Hasi saioa",
  logo_login: "imgs/login/logo.png",
  logout: "Saioa itxi",
  no_results: "Ez da emaitzarik aurkitu",
  open: "irakurri",
  password_lost: "Pasahitza ahaztu dut",
  password_placeholder: "Pasahitza",
  password_recovery_header: "¿Olvidaste tu contraseña?",
  password_recovery_legend:
    "Escribe tu dirección de correo electrónico y te enviaremos los datos de acceso.",
  password_recovery_submit: "Recuperar",
  password_recovery_ok:
    "En breve recibirás un correo electrónico con los datos de acceso",
  password_recovery_error:
    "El correo electrónico proporcionado no corresponde a ningún usuario registrado",
  publishers: "Argitaletxeak",
  read: "Irakurrita ",
  ages: "Gomendatutako adina",
  remove_from_my_books: 'Kendu "Nire liburuak" ataletik',
  search: "Bilatu",
  search_input: "Bilatu ...",
  signin_legend: "Oraindik konturik ez baduzu,",
  signin_link: "suscr&iacute;bete aqu&iacute;",
  subjects: "Gaiak",
  username_placeholder: "Helbide elektronikoa",
  values: "Balioak",
  education_levels: "Niveles educativos",
  typologies: "Baliabideak",
  view_abstract: "Laburpena",
  requestCase: "Bozkatu",
  addClass: "Gehitu nire gelara",
  addBook: "Gehitu liburua",
  add: "Gehitu",
  selectClasses: "Aukeratu gela bat edo gehiago liburua gehitzeko",
  wait: "Itxaron...",
  addWithSuccess: "Liburua behar bezala gehitu zaio ikasgelari.",
  cancel: "Utzi",
  detective: "detektibea",
  detectives: "detektibe",
  noDetectives: "Detektiberik gabe",
  requestCaseSuccess: "Botoa emanda",
  requestCaseDescription: (
    <>
      <p>
        <strong>
          Liburu honi buruzko kasu bat izatea gustatuko litzaizuke?
        </strong>
      </p>
      <p>
        Ikasleekin irakurgai hau lantzeko proposamen didaktiko bat izan nahi
        baduzu, egin klik.
      </p>
      <p>
        Boto kontaketak lagunduko digu Ta-tumen eskuragarri egongo diren
        hurrengo kasuak erabakitzen.
      </p>
      <p>
        Azkenean kasu hau sortuko bagenu, posta elektronikoa bidaliko genizuke
        jakinarazteko.
      </p>
      sendWithSuccess: "Jaso dugu zure botoa.<p>Eskerrik asko.</p>
    </>
  ),
  requestTitle: "Bozkatu",
  moreReadings: "Irakurrienak",
  moreRecents: "Berrienak",
  orderAsc: "Ordenatu alfabetikoki",
  orderDesc: "Ordenatu Z-tik-A-ra",
  orderBy: "Ordenatu...",
  urlSuscribe: "https://family.ta-tum.com/#subscribe",
  applyFilters: "Iragazkiak aplikatu",
  legal: "Lege Oharra",
  privacy: "Pribatutasuneko Politika",
  cookies: "Cookieen Politika",
  edelvives: "Grupo Edelvives",
  edelvivesLink: "https://www.edelvives.com/es/index",
};
