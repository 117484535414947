const iframeCode = `<div class="tyc_legal">
   <h1>AVISO LEGAL</h1>
   <p>En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y
	   Comercio Electrónico, a continuación se exponen los datos identificativos del titular del sitio web <a target="_blank"
		href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> (en adelante, el <strong>“Sitio Web”</strong>):</p>
   <p><strong>TITULAR</strong>: Grupo Editorial Luis Vives (en adelante, “GRUPO EDELVIVES”)</p>
   <p><strong>CIF</strong>: R 5000274 J</p>
   <p><strong>Domicilio</strong>: Ctra. de Madrid km. 315,700; C.P. 50.012 Zaragoza (España)</p>
   <p>Entidad perteneciente al Instituto de Hermanos Maristas de la Enseñanza; Inscrita en el Registro de Entidades
	   Religiosas del Ministerio de Justicia, con el número 1334-i/28-SE/B.</p>
   <p>El acceso, navegación y utilización del Sitio Web implica la aceptación expresa y sin reservas de todos los
	   términos de las presentes condiciones de uso, teniendo la misma validez y eficacia que cualquier contrato celebrado
	   por escrito y firmado.</p>
   <p>Su observancia y cumplimiento será exigible respecto de cualquier persona que acceda, navegue o utilice la Web. Si
	   Ud. no está de acuerdo con los términos expuestos, no acceda, navegue o utilice ninguna página del sitio web.</p>
   <h3>CONDICIONES GENERALES DE USO</h3>
   <p>Las presentes condiciones de uso regulan el acceso, navegación y utilización del Sitio Web, sin perjuicio que
	   <strong>GRUPO EDELVIVES</strong>
	   se reserva el derecho a modificar la presentación, configuración y contenido de la Web, así como las
	   condiciones requeridas para su acceso y/o utilización. El acceso y utilización de los contenidos del Sitio Web tras
	   la entrada en vigor de sus modificaciones o cambios suponen la aceptación de los mismos.</p>
   <p>No obstante, el acceso a determinados contenidos y la utilización de determinados servicios puede encontrarse
	   sometido a determinadas condiciones particulares, que serán, en todo caso, claramente mostradas y deberán ser
	   aceptadas expresamente por parte de los usuarios. Estas condiciones particulares, podrán sustituir, completar o, en
	   su caso, modificar las presentes condiciones de uso particulares.</p>
   <p><strong>GRUPO EDELVIVES</strong> se reserva el derecho a modificar los términos y condiciones aquí estipuladas,
	   total o parcialmente, publicando cualquier cambio en la misma forma en que aparecen estas condiciones de uso o a
	   través de cualquier tipo de comunicación dirigida a los usuarios.</p>
   <h3>ACCESO Y SEGURIDAD</h3>
   <p>Con carácter general, los Usuarios podrán acceder al sitio web de forma libre y gratuita. No obstante, Grupo
	   Editorial Luis Vives se reserva conforme a la legislación vigente el derecho de limitar el acceso a determinadas
	   áreas del sitio web para las cuales el Usuario deberá registrarse facilitando toda la información solicitada, de
	   forma actualizada y real (véase en cada caso la política de privacidad).</p>
   <p>
	   Queda prohibido el acceso al Sitio Web por parte de menores de edad.
   </p>
   <p>Bajo ningún concepto <strong>GRUPO EDELVIVES</strong> se responsabilizará de la veracidad de los datos de registro
	   facilitados por
	   los usuarios finales, por lo que cada uno de estos son los responsables de las posibles consecuencias, errores y
	   fallos que posteriormente puedan derivarse de la falta de veracidad de los datos.</p>
   <h3>DERECHOS DE PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
   <p><strong>GRUPO EDELVIVES</strong> es titular o, en su caso, cuenta con las licencias correspondientes sobre los
	   derechos de
	   explotación de propiedad intelectual e industrial del Sitio Web, así como de los derechos de propiedad intelectual e
	   industrial sobre la información, materiales y contenidos de la misma.</p>
   <p>En ningún caso se entenderá que el acceso, navegación y utilización del Sitio Web por parte del usuario implica una
	   renuncia, transmisión, licencia o cesión total o parcial de dichos derechos por parte de <strong>GRUPO EDELVIVES</strong>.
	   El usuario
	   dispone de un derecho de uso de los contenidos y/o servicios del Sitio Web dentro de un ámbito estrictamente
	   doméstico.</p>
   <p>Las referencias a marcas o nombres comerciales registrados, u otros signos distintivos, ya sean titularidad de
	   <strong>GRUPO EDELVIVES</strong> o de terceras empresas, llevan implícitas la prohibición sobre su uso sin el
	   consentimiento de
	   <strong>GRUPO EDELVIVES</strong> o de sus legítimos titulares. En ningún momento, salvo manifestación expresa en
	   contrario, el acceso,
	   navegación o utilización del Sitio Web y/o de sus contenidos, confiere al usuario derecho alguno sobre signos
	   distintivos en él incluidos.</p>
   <p>Quedan reservados todos los derechos de propiedad intelectual e industrial sobre los contenidos y/o servicios del
	   Sitio Web y, en particular, queda prohibido modificar, copiar, reproducir, comunicar públicamente, transformar o
	   distribuir, por cualquier medio y bajo cualquier forma, la totalidad o parte de los contenidos incluidos en la Web,
	   para propósitos públicos o comerciales, si no se cuenta con la autorización previa, expresa y por escrito de <strong>GRUPO
		   EDELVIVES</strong> o, en su caso, del titular de los derechos correspondientes.</p>
   <p>En el caso de que el usuario envíe información de cualquier tipo a <strong>GRUPO EDELVIVES</strong> a través
	   cualquiera de los
	   canales habilitados al efecto, el usuario declara, garantiza y acepta que tiene derecho a hacerlo libremente, que
	   dicha información no infringe ningún derecho de propiedad intelectual, industrial, secreto comercial o cualesquiera
	   otros derechos de terceros, y que dicha información no tiene carácter confidencial ni es perjudicial para terceros.</p>
   <p>El usuario reconoce asumir la responsabilidad, dejando indemne a <strong>GRUPO EDELVIVES</strong> por cualquier
	   comunicación que
	   suministre personalmente o a su nombre, alcanzando dicha responsabilidad sin restricción alguna la exactitud,
	   legalidad, originalidad y titularidad de la misma.</p>
   <h3>MARCAS</h3>
   <p>Las marcas, logotipos y cualesquiera otros de propiedad industrial que aparecen en este sitio son propiedad de
	   <strong>GRUPO EDELVIVES</strong> o de terceras partes. Está prohibida la utilización de estas marcas sin la previa
	   autorización
	   escrita de <strong>GRUPO EDELVIVES</strong>, o de las terceras partes propietarias de las marcas.</p>
   <h3>ENLACES</h3>
   <h4>ENLACES A OTRAS PÁGINAS WEB</h4>
   <p>En caso de que en el Sitio Web se mostraran enlaces a otras páginas web mediante diferentes botones, links, banners
	   o contenidos embebidos, <strong>GRUPO EDELVIVES</strong> informa que éstos se encuentran directamente gestionados por
	   terceros, no
	   teniendo <strong>GRUPO EDELVIVES</strong> ni medios humanos, ni técnicos para conocer de forma previa y/o controlar
	   y/o aprobar toda
	   la información, contenidos, productos o servicios facilitados por otros sitios web a los que se puedan establecer
	   enlaces desde el presente Sitio Web.</p>
   <p>En consecuencia, <strong>GRUPO EDELVIVES</strong> no podrá asumir ningún tipo de responsabilidad por cualquier
	   aspecto relativo a la
	   página web a la que se pudiera establecer un enlace desde el Sitio Web, en concreto, a título enunciativo y no
	   limitativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus productos y
	   servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.</p>
   <p>En este sentido, si los Usuarios tuvieran conocimiento efectivo de que las actividades desarrolladas a través de
	   estas páginas web de terceros son ilegales o contravienen la moral y/o el orden público, deberán comunicarlo
	   inmediatamente a <strong>GRUPO EDELVIVES</strong> a los efectos de que se proceda a deshabilitar el enlace de acceso
	   a las mismas,
	   acción que se llevará a cabo en el menor tiempo posible.</p>
   <p>En cualquier caso, el establecimiento de cualquier tipo de enlace desde el Sitio Web a otra página web ajena no
	   implicará que exista algún tipo de relación, colaboración o dependencia entre <strong>GRUPO EDELVIVES</strong> y el
	   responsable de la
	   página web ajena.</p>
   <h3>ENLACES DE TERCEROS</h3>
   <p><strong>GRUPO EDELVIVES</strong> pone a disposición de los usuarios, a través de diferentes herramientas y
	   aplicaciones, medios de
	   enlace que permiten a los usuarios acceder a canales y páginas en diferentes webs. La instalación de estas
	   aplicaciones en el Sitio Web tiene por único objeto facilitar a los usuarios el acceso a dichos canales en las
	   diferentes plataformas y redes sociales.</p>
   <p>El establecimiento de estas aplicaciones no implica la existencia de relación alguna entre <strong>GRUPO EDELVIVES</strong>
	   y el
	   propietario, fabricante o distribuidor de la plataforma enlazada, como tampoco la aceptación y aprobación por parte
	   de <strong>GRUPO EDELVIVES</strong> de sus contenidos y/o servicios, siendo su propietario, fabricante o distribuidor
	   el único
	   responsable de los mismos.</p>
   <p>En ningún caso, <strong>GRUPO EDELVIVES</strong> comparte con tales propietarios de tales páginas webs externas,
	   ningún tipo de
	   información privada sobre sus usuarios, siendo su única finalidad facilitar el acceso a ellas por parte de los
	   usuarios. En este sentido, toda la información que el propio usuario desee proporcionar a estas plataformas y/o
	   páginas webs externas, será bajo su propia responsabilidad, no interviniendo en dicho proceso <strong>GRUPO EDELVIVES</strong>.
   </p>
   <p>Debido a que <strong>GRUPO EDELVIVES</strong> no tiene control alguno sobre el contenido alojado en dichos canales,
	   el usuario
	   reconoce y acepta que <strong>GRUPO EDELVIVES</strong> no asume responsabilidad alguna por el contenido ni por los
	   servicios a los que
	   el usuario pueda acceder en dichas páginas ni por cualquier contenido, productos, servicios, publicidad, ni cualquier
	   otro material disponible en los mismos. Por tal motivo, el usuario debe extremar la prudencia en la valoración y
	   utilización de la información, contenidos y servicios existentes en los canales enlazados, y sobre la información
	   propia o de terceros que quiera compartir en dichos canales.</p>
   <h3>ENLACES EN OTRAS PÁGINAS WEB CON DESTINO AL SITIO WEB</h3>
   <p><strong>GRUPO EDELVIVES</strong> no autoriza el establecimiento de un enlace al presente Sitio Web desde otras
	   páginas web que
	   contengan materiales, información o contenidos ilícitos, ilegales, degradantes, obscenos y, en general, que
	   contravengan las leyes, la moral o al orden público, o las normas sociales generalmente aceptadas.</p>
   <p>En todo caso, los usuarios podrán establecer enlaces en sus respectivas páginas web siempre y cuando soliciten la
	   autorización previa y expresa a <strong>GRUPO EDELVIVES</strong>.</p>
   <p><strong>GRUPO EDELVIVES</strong> no tiene facultad ni medios humanos y técnicos para conocer, controlar ni aprobar
	   toda la información, contenidos, productos o servicios facilitados por otros sitios web que tengan establecidos
	   enlaces con destino al presente Sitio Web. <strong>GRUPO EDELVIVES</strong> no asume ningún tipo de responsabilidad
	   por cualquier
	   aspecto relativo a las páginas web que establecen ese enlace con destino al presente Sitio Web, en concreto, a título
	   enunciativo y no taxativo, sobre su funcionamiento, acceso, datos, información, archivos, calidad y fiabilidad de sus
	   productos y servicios, sus propios enlaces y/o cualquiera de sus contenidos, en general.</p>
   <h3>CONDICIONES DE USO DEL SITIO WEB</h3>
   <p>No está permitido y, por tanto, sus consecuencias serán de la exclusiva responsabilidad del usuario, el acceso o la
	   utilización del Sitio Web con fines ilegales o no autorizados, con o sin finalidad económica, y, más específicamente
	   y sin que el siguiente listado tenga carácter absoluto, queda prohibido:</p>
   <ol>
	   <li>
		   <p>Usar el Sitio Web en forma alguna que pueda provocar daños, interrupciones, ineficiencias o defectos en su
			   funcionalidad o en el ordenador de un tercero;</p>
	   </li>
	   <li>
		   <p>Usar el Sitio Web para la transmisión, la instalación o la publicación de cualquier virus, código malicioso u
			   otros programas o archivos perjudiciales;</p>
	   </li>
	   <li>
		   <p>Usar el Sitio Web para recoger datos de carácter personal de otros usuarios;</p>
	   </li>
	   <li>
		   <p>Registrarse a través del Sitio Web con una identidad falsa, suplantado a terceros o utilizando un perfil o
			   realizando cualquier otra acción que pueda confundir a otros usuarios sobre la identidad del origen de un mensaje;</p>
	   </li>
	   <li>
		   <p>Acceder sin autorización a cualquier sección del Sitio Web, a otros sistemas o redes conectadas a la Plataforma,
			   a ningún servidor de <strong>GRUPO EDELVIVES</strong> ni a los servicios ofrecidos a través del Sitio Web, por
			   medio de pirateo o
			   falsificación, extracción de contraseñas o cualquier otro medio ilegítimo;</p>
	   </li>
	   <li>
		   <p>Quebrantar, o intentar quebrantar, las medidas de seguridad o autenticación del Sitio Web o de cualquier red
			   conectada a la misma, o las medidas de seguridad o protección inherentes a los contenidos ofrecidos en el Sitio
			   Web;</p>
	   </li>
	   <li>
		   <p>Llevar a cabo alguna acción que provoque una saturación desproporcionada o innecesaria en la infraestructura del
			   Sitio Web o en los sistemas o redes de <strong>GRUPO EDELVIVES</strong>, así como en los sistemas y redes
			   conectados al Sitio Web;</p>
	   </li>
	   <li>
		   <p>Impedir el normal desarrollo de un evento, concurso, promoción o cualquier otra actividad disponible a través del
			   Sitio Web o cualesquiera de sus funcionalidades, ya sea alterando o tratando de alterar, ilegalmente o de cualquier
			   otra forma, el acceso, participación o funcionamiento de aquéllos, o falseando el resultado de los mismos y/o
			   utilizando métodos de participación fraudulentos, mediante cualquier procedimiento, y/o a través de cualquier
			   práctica que atente o vulnere en modo alguno las presentes Condiciones de uso.</p>
	   </li>
   </ol>
   <p>El incumplimiento de cualquiera de las anteriores obligaciones por el usuario, podrá llevar aparejada la adopción
	   por <strong>GRUPO EDELVIVES</strong> de las medidas oportunas amparadas en Derecho y en el ejercicio de sus derechos
	   u obligaciones,
	   pudiendo llegar a la eliminación o bloqueo de la cuenta del usuario infractor, sin que medie posibilidad de
	   indemnización alguna por los daños y perjuicios causados.</p>
   <h3>RESPONSABILIDADES Y GARANTÍAS</h3>
   <p><strong>GRUPO EDELVIVES</strong> no puede garantizar la fiabilidad, utilidad o veracidad de absolutamente toda la
	   información y/o de los servicios del Sitio Web, ni tampoco de la utilidad o veracidad de la documentación puesta a
	   disposición a través de la misma.</p>
   <p>En consecuencia, <strong>GRUPO EDELVIVES</strong> EDELVIVES no garantiza ni se hace responsable de: (i) la
	   continuidad de los contenidos del Sitio Web; (ii) la ausencia de errores en dichos contenidos; (iii) la ausencia de
	   virus y/o demás componentes dañinos en el Sitio Web o en el servidor que lo suministra; (iv) la invulnerabilidad del
	   Sitio Web y/o la imposibilidad de vulnerar las medidas de seguridad que se adopten en la misma; (v) la falta de
	   utilidad o rendimiento de los contenidos del Sitio Web; y (vi) los daños o perjuicios que cause, a sí mismo o a un
	   tercero, cualquier persona que infringiera las condiciones, normas e instrucciones que <strong>GRUPO EDELVIVES</strong>
	   establece en el Sitio Web o a través de la vulneración de los sistemas de seguridad del Sitio Web.</p>
   <p>No obstante, <strong>GRUPO EDELVIVES</strong> declara que ha adoptado todas las medidas necesarias, dentro de sus
	   posibilidades y del estado de la técnica, para garantizar el funcionamiento del Sitio Web y reducir al mínimo los
	   errores del sistema, tanto desde el punto de vista técnico, como de los contenidos publicados en el Sitio Web.</p>
   <p>Si el usuario tuviera conocimiento de la existencia de algún contenido ilícito, ilegal, contrario a las leyes o que
	   pudiera suponer una infracción de derechos de propiedad intelectual o industrial, de la normativa aplicable en
	   material de protección de datos personales y/o cualquier otro derecho, deberá notificarlo inmediatamente a <strong>GRUPO
		   EDELVIVES</strong> para que ésta pueda proceder a la adopción de las medidas oportunas.</p>
   <p><strong>GRUPO EDELVIVES</strong> no garantiza la licitud, fiabilidad y utilidad de los contenidos suministrados por
	   terceros a través del Sitio Web.</p>
   <p><strong>GRUPO EDELVIVES</strong> no será responsable sobre la veracidad, integridad o actualización de las
	   informaciones publicadas en el Sitio Web provenientes de fuentes ajenas al mismo (noticias externas, informes de
	   profesionales externos, etc.), así como tampoco de las contenidas en otros portales web mediante enlace desde el
	   Sitio Web.</p>
   <p><strong>GRUPO EDELVIVES</strong> no asumirá responsabilidad en cuanto a hipotéticos perjuicios que pudieran
	   originarse por el uso de las citadas informaciones.</p>
   <p>En todo caso, <strong>GRUPO EDELVIVES</strong> se reserva el derecho a suspender, modificar, restringir o
	   interrumpir, temporal o permanentemente, el acceso, navegación, uso, alojamiento y/o descarga del contenido y/o uso
	   de servicios del Sitio Web, con o sin previa notificación, a los usuarios que contravengan cualquiera de las
	   disposiciones detalladas en las presentes condiciones de uso, sin que medie posibilidad del usuario de exigir
	   indemnización alguna por esta causa.</p>
   <div class="pprivacy">
	   <p>De conformidad con lo dispuesto por la legislación vigente y aplicable en materia de protección de datos de
		   carácter personal, todos los datos de carácter personal facilitados durante la utilización del Sitio Web serán
		   tratados de conformidad con los dispuesto en la Política de Privacidad que todo usuario debe aceptar expresamente
		   para poder utilizar y registrarse en el Sitio Web.</p>
	   <p>Todo usuario que acepte las presentes condiciones de uso, acepta de forma informada, expresa e inequívoca nuestra
		   Política de Privacidad, pudiendo ejercer los derechos que en esta materia le corresponden, según se informa en la
		   mencionada Política de Privacidad.</p>
	   <h3>LEY APLICABLE Y JURISDICCIÓN COMPETENTE</h3>
	   <p>Estas Condiciones de Uso se rigen por la ley española. Las partes se someten expresamente, para la resolución de
		   los conflictos y con renuncia a cualquier otro fuero, a los juzgados y tribunales de Madrid capital.</p>
	   <p>Para presentar reclamaciones en el uso de nuestros servicios el cliente puede dirigirse por correo a la dirección
		   electrónica o física indicada en el apartado “Identificación”, comprometiéndonos a buscar en todo momento una
		   solución amistosa del conflicto.</p>
	   <p>Última actualización: 21 de enero de 2019</p>
	   <p>© 2019 GRUPO EDELVIVES Todos los derechos reservados</p>
	   <h3><a name="punto3a"></a>POLÍTICA DE PRIVACIDAD</h3>
	   <ol>
		   <li>
			   <h3>IDENTIFICACIÓN</h3>
			   <ul>
				   <li>Identidad: <strong>GRUPO EDITORIAL LUIS VIVES</strong> (en adelante, <strong>"GRUPO EDELVIVES"</strong>)</li>
				   <li>Domicilio: Carretera de Madrid, Km 315,700, 50001-Zaragoza</li>
				   <li>NIF: R5000274J</li>
				   <li>Delegado de Protección de Datos (DPO): puede contactar con nuestro DPO a través de los siguientes medios: :
					   - E-mail: dpo@edelvives.es
					   - Dirección: Calle Xaudaró, 25, 28034 Madrid.</li>
			   </ul>Indicando en la referencia “Delegado de Protección de Datos”.
		   </li>
		   <li>
			   <h3>INFORMACIÓN Y CONSENTIMIENTO.</h3>
			   <p>Mediante la aceptación de la presente Política de Privacidad, el usuario queda informado y presta su
				   consentimiento libre, informado, específico e inequívoco para que los datos personales que facilite a través de la
				   página web ubicada en la URL <a target="_blank" href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a>
				   (en adelante, el <strong>“Sitio Web”</strong>) sean tratados por <strong>GRUPO EDELVIVES</strong>, así como los
				   datos derivados de
				   su navegación y aquellos otros datos que pueda facilitar en un futuro a <strong>GRUPO EDELVIVES</strong>.</p>
			   <p>El usuario debe leer con atención esta Política de Privacidad, que ha sido redactada de forma clara y sencilla,
				   para facilitar su comprensión, determinando libre y voluntariamente si desea facilitar sus datos personales a
				   <strong>GRUPO EDELVIVES</strong>.</p>
			   <p>Si eres MENOR DE EDAD, sólo puedes aportar datos personales con el previo consentimiento de padres o tutores,
				   enviado debidamente firmado y con copia del DNI del padre o tutor firmante a la dirección <a mailto="rgpd@edelvives.es">rgpd@edelvives.es</a>
				   <strong>GRUPO EDELVIVES</strong> no responde de los datos de menores que sin poder conocer este hecho, se hayan
				   facilitado sin
				   consentimiento de padre o tutor.</p>
		   </li>
		   <li>
			   <h3>OBLIGATORIEDAD DE FACILITAR LOS DATOS.</h3>
			   <p>Los datos solicitados en los formularios del Sitio Web son con carácter general, obligatorios (salvo que en el
				   campo requerido se especifique lo contrario) para cumplir con las finalidades establecidas.</p>
			   <p>Por lo tanto, si no se facilitan los mismos o no se facilitan correctamente no podrán atenderse las mismas, sin
				   perjuicio de que podrá visualizar libremente el contenido del Sitio Web.</p>
		   </li>
		   <li>
			   <h3>¿CON QUÉ FINALIDAD TRATARÁ GRUPO EDELVIVES LOS DATOS PERSONALES DEL USUARIO?</h3>
			   <p>Los datos personales facilitados a través del Sitio Web serán tratados por <strong>GRUPO EDELVIVES</strong>
				   conforme a las
				   siguientes finalidades:</p>
			   <ol>
				   <li>Datos facilitados para el registro en el <strong>Portal de usuario</strong>:
					   <ul>
						   <li>
							   <p>Gestionar su registro como usuario en la plataforma online propiedad de <strong>GRUPO EDELVIVES</strong> y
								   alojada en el presente Sitio Web. </p>
						   </li>
						   <li>
							   <p>Gestionar el contenido del Portal.</p>
						   </li>
						   <li>
							   <p>Efectuar análisis sobre la utilización del Sitio Web y comprobar las preferencias y comportamiento de los
								   usuarios.</p>
						   </li>
					   </ul>
				   </li>
				   <li>
					   <p>Datos facilitados en los <strong>formularios de contacto y otros canales puestos a disposición de los usuarios</strong>:</p>
					   <ul>
						   <li>
							   <p>Gestionar las solicitudes de contacto e información del usuario a través de los canales dispuestos para
								   ello en los sitios web de <strong>GRUPO EDELVIVES</strong>.</p>
						   </li>
						   <li>
							   <p>Gestión de la petición planteada.</p>
						   </li>
						   <li>
							   <p>Efectuar análisis sobre la utilización del Sitio Web y comprobar las preferencias y comportamiento de los
								   usuarios.</p>
						   </li>
					   </ul>
				   </li>
			   </ol>
		   </li>
		   <li>
			   <h3>¿QUÉ DATOS DEL USUARIO TRATARÁ GRUPO EDELVIVES? </h3>
			   <p><strong>GRUPO EDELVIVES</strong> tratará las siguientes categorías de datos del usuario:</p>
			   <ol>
				   <li>
					   <p>Datos facilitados para el registro en el <strong><u>Portal de usuario</u></strong></p>
					   <ul>
						   <li>
							   <p>Datos identificativos: nombre, apellidos.</p>
						   </li>
						   <li>
							   <p>Datos de contacto: domicilio, dirección de correo electrónico, número de teléfono.</p>
						   </li>
						   <li>
							   <p>Otros datos: datos facilitados por los propios interesados en los campos abiertos.</p>
						   </li>
						   <li>
							   <p>Datos de navegación.</p>
						   </li>
					   </ul>
				   </li>
				   <li>
					   <p>Datos facilitados en los <strong><u>formularios de contacto y otros canales puestos a disposición de los
								   usuarios</u></strong>:</p>
					   <ul>
						   <li>
							   <p>Datos identificativos: nombre, apellidos.</p>
						   </li>
						   <li>
							   <p>Datos de contacto: dirección de correo electrónico, número de teléfono.</p>
						   </li>
						   <li>
							   <p>Datos de transacción de bienes y servicios.</p>
						   </li>
						   <li>
							   <p>Otros datos: datos facilitados por los propios interesados en los campos abiertos de los formularios
								   dispuestos en el Sitio Web o en los documentos adjuntos.</p>
						   </li>
						   <li>
							   <p>Datos de navegación.</p>
						   </li>
					   </ul>
					   <p>En caso de que el usuario facilite datos de terceros, manifiesta contar con el consentimiento de los mismos y
						   se compromete a trasladarle la información contenida en la Política de Privacidad, eximiendo a <strong>GRUPO
							   EDELVIVES</strong>
						   de cualquier responsabilidad en este sentido. No obstante, <strong>GRUPO EDELVIVES</strong> podrá llevar a cabo
						   las
						   verificaciones periódicas para constatar este hecho, adoptando las medidas de diligencia debida que
						   correspondan, conforme a la normativa de protección de datos.</p>
				   </li>
			   </ol>
		   </li>
		   <li>
			   <h3>¿CUÁL ES LA LEGITIMACIÓN DEL TRATAMIENTO DE LOS DATOS DEL USUARIO?</h3>
			   <p>La legitimación del tratamiento de sus datos personales, serán las siguientes:</p>
			   <ol>
				   <li>
					   <p>Datos facilitados para el registro en el <stron><u>Portal de usuario</u></stron>: el consentimiento que se le
						   solicita y que puede
						   retirar en cualquier momento. No obstante, en caso de retirar su consentimiento, ello no afectará a la licitud
						   de los tratamientos efectuados con anterioridad. Para la ejecución de análisis sobre la utilización de la web,
						   el interés legítimo <strong>GRUPO EDELVIVES</strong>.</p>
				   </li>
				   <li>
					   <p>2. Para los <strong><u>formularios de contacto y otros canales puestos a disposición de los usuarios</u></strong>:
						   el consentimiento
						   que se le solicita y que puede retirar en cualquier momento. No obstante, en caso de retirar su consentimiento,
						   ello no afectará a la licitud de los tratamientos efectuados con anterioridad. Para la ejecución de análisis
						   sobre la utilización de la web, el interés legítimo de <strong>GRUPO EDELVIVES</strong>.</p>
				   </li>
			   </ol>
			   <p>Los consentimientos obtenidos para las finalidades mencionadas son independientes por lo que el usuario podrá
				   revocar solo uno de ellos no afectando a los demás.</p>
			   <p>Para revocar dicho consentimiento, el Usuario podrá contactar con <strong>GRUPO EDELVIVES</strong>, en cualquier
				   momento, a través de los canales siguientes: <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
				   indicando en el asunto de referencia “Datos Personales”.</p>
		   </li>
		   <li>
			   <h3>¿CON QUÉ DESTINATARIOS SE COMPARTIRÁN LOS DATOS DEL USUARIO?</h3>
			   <p>Los datos del usuario podrán ser comunicados a:</p>
			   <ol>
				   <li>
					   <p>1. Datos facilitados para el registro en el <strong><u>Portal de usuario</u></strong>: Empresas del grupo al
						   que pertenece <strong>GRUPO
							   EDELVIVES</strong>, únicamente para fines administrativos internos y/o para las finalidades anteriormente
						   indicadas.</p>
				   </li>
				   <li>
					   <p>2. Datos facilitados para los <strong><u>formularios de contacto y otros canales puestos a disposición de los
								   usuarios</u></strong>:
						   Empresas del grupo al que pertenece <strong>GRUPO EDELVIVES</strong>, únicamente para fines administrativos
						   internos y/o para las
						   finalidades anteriormente indicadas.</p>
				   </li>
			   </ol>
			   <p>Adicionalmente, los datos podrán ser accesibles por proveedores de <strong>GRUPO EDELVIVES</strong>, siendo
				   dicho acceso el
				   necesario para el adecuado cumplimiento de las obligaciones legales y/o de las finalidades anteriormente
				   indicadas. Dichos proveedores no tratarán sus datos para finalidades propias que no hayan sido previamente
				   informadas por <strong>GRUPO EDELVIVES</strong>.</p>
		   </li>
		   <li>
			   <h3>CONSERVACIÓN DE LOS DATOS</h3>
			   <p>Sus datos serán conservados durante los siguientes plazos:</p>
			   <ol>
				   <li>
					   <p>Datos facilitados para el registro en el <strong><u>Portal de usuario</u></strong>: serán conservados hasta
						   que el usuario no solicite la baja de la misma y, una vez solicitada, durante el plazo de prescripción de
						   acciones legales derivadas del uso de la misma. </p>
				   </li>
				   <li>
					   <p>2. Datos facilitados para los <strong><u>formularios de contacto y otros canales puestos a disposición de los
								   usuarios</u></strong>:
						   serán conservados durante el plazo necesario para dar trámite y contestación a su solicitud y, una vez
						   finalizado el mismo, durante el plazo de prescripción de las acciones legales derivadas de la citada solicitud</p>
				   </li>
			   </ol>
		   </li>
		   <li>
			   <h3>RESPONSABILIDAD DEL USUARIO.</h3>
			   <p>El usuario:</p>
			   <ul>
				   <li>
					   <p>Garantiza que es mayor de dieciocho (18) años y que los datos que facilita a <strong>GRUPO EDELVIVES</strong>
						   son verdaderos, exactos, completos y actualizados. A estos efectos, el usuario responde de la veracidad de todos
						   los datos que comunique y mantendrá convenientemente actualizada la información facilitada, de tal forma que
						   responda a su situación real.</p>
				   </li>
				   <li>
					   <p>Garantiza que ha informado a los terceros de los que facilite sus datos, en caso de hacerlo, de los aspectos
						   contenidos en este documento. Asimismo, garantiza que ha obtenido su autorización para facilitar sus datos a
						   <strong>GRUPO EDELVIVES</strong> para los fines señalados.</p>
				   </li>
				   <li>
					   <p>Será responsable de las informaciones falsas o inexactas que proporcione a través del Sitio Web y de los daños
						   y perjuicios, directos o indirectos, que ello cause a <strong>GRUPO EDELVIVES</strong> o a terceros.</p>
				   </li>
			   </ul>
		   </li>
		   <li>
			   <h3>EJERCICIO DE DERECHOS.</h3>
			   <p>El usuario puede enviar un escrito a <strong>GRUPO EDELVIVES</strong>, a la dirección indicada en el encabezado
				   de la presente Política, o bien por medio de un correo electrónico a la dirección <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
				   adjuntando fotocopia de su documento de identidad, en cualquier momento y de manera gratuita, para:</p>
			   <ul>
				   <li>
					   <p>Revocar los consentimientos otorgados.</p>
				   </li>
				   <li>
					   <p>Obtener confirmación acerca de si en <strong>GRUPO EDELVIVES</strong> se están tratando datos personales que
						   conciernen al usuario o no.</p>
				   </li>
				   <li>
					   <p>Acceder a sus datos personales.</p>
				   </li>
				   <li>
					   <p>Rectificar los datos inexactos o incompletos.</p>
				   </li>
				   <li>
					   <p>Solicitar la supresión de sus datos cuando, entre otros motivos, los datos ya no sean necesarios para los
						   fines que fueron recogidos.</p>
				   </li>
				   <li>
					   <p>Obtener de <strong>GRUPO EDELVIVES</strong> la limitación del tratamiento de los datos cuando se cumpla alguna
						   de las condiciones previstas en la normativa de protección de datos.</p>
				   </li>
				   <li>
					   <p>Solicitar la portabilidad de los datos facilitados por el Usuario en aquellos casos previstos en la normativa</p>
				   </li>
				   <li>
					   <p>Ponerse en contacto con el DPO de <strong>GRUPO EDELVIVES</strong> a través de la siguiente dirección: <a
							target="_blank" href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
				   </li>
				   <li>
					   <p>Interponer una reclamación relativa a la protección de sus datos personales ante la Agencia Española de
						   Protección de Datos en la dirección Calle de Jorge Juan, 6, 28001 Madrid, cuando el interesado considere que
						   <strong>GRUPOEDELVIVES</strong>
						   ha vulnerado los derechos que le son reconocidos por la normativa aplicable en protección de datos.</p>
				   </li>
			   </ul>
		   </li>
		   <li>
			   <h3>MEDIDAS DE SEGURIDAD.</h3>
			   <p><strong>GRUPO EDELVIVES</strong> tratará los datos del usuario, en todo momento, de forma absolutamente
				   confidencial y guardando el preceptivo deber de secreto respecto de los mismos, de conformidad con lo previsto en
				   la normativa de aplicación, adoptando al efecto las medidas de índole técnica y organizativas necesarias que
				   garanticen la seguridad de sus datos y eviten su alteración, pérdida, tratamiento o acceso no autorizado, habida
				   cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos.</p>
			   <p>Última actualización: 21 de enero de 2019.</p>
		   </li>
	   </ol>
   </div>
   <div class="ccokies">
	   <h3><a name="punto3a"></a>POLÍTICA DE COOKIES</h3>
	   <p>Esta Política de Cookies es parte integrante del Aviso Legal y la Política de Privacidad de la página web <a
			target="_blank" href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> (en adelante, el <strong>“Sitio
			   web”</strong>).El acceso y la navegación en el Sitio Web, o el uso de los servicios del mismo, implican la
		   aceptación de los términos y condiciones recogido en el Aviso Legal y en la Política de Privacidad.</p>
	   <p>Con el fin de facilitar la navegación por el Sitio Web, <strong>GRUPO EDITORIAL LUIS VIVES</strong>, como titular
		   del mismo (en adelante, <strong>“GRUPO EDELVIVES”</strong>) con domicilio social en Ctra. de Madrid km. 315,700,
		   50.012, Zaragoza, con N.I.F. R 5000274 J, le comunica que utiliza cookies u otros dispositivos de almacenamiento de
		   almacenamiento y recuperación de datos de funcionalidad similar (en adelante, las <strong>“Cookies”</strong>).</p>
	   <p>En este sentido y con el objetivo de garantizar al usuario toda la información necesaria para su correcta
		   navegación, ponemos a disposición del usuario el siguiente texto informativo sobre qué son las cookies, qué
		   tipología de cookies existen en nuestra Página Web y cómo es posible configurarlas o deshabilitarlas.</p>
	   <ol>
		   <li>
			   <p>¿Qué es una Cookie?</p>
			   <p>Las Cookies son archivos que contienen pequeñas cantidades de información que se descargan en el dispositivo del
				   usuario cuando visita una página web. Su finalidad principal es reconocer al usuario cada vez que accede al Sitio
				   Web permitiendo, además, mejorar la calidad y ofrecer un mejor uso del Sitio Web.</p>
			   <p>Las Cookies son esenciales para el funcionamiento de Internet, ya que ayudan, entre otras funciones, a
				   identificar y resolver posibles errores de funcionamiento del Sitio Web.</p>
		   </li>
		   <li>
			   <p>Tipología de cookies</p>
			   <p>En el presente Sitio Web se utilizan cookies propias y de terceros. Las cookies utilizadas se almacenan en su
				   ordenador durante un tiempo determinado, éstas pueden considerarse cookies de sesión (si caducan cuando abandona
				   nuestro Sitio Web) o persistentes (si la caducidad de las cookies dura más tiempo). Las cookies utilizadas en la
				   presente página Web son:</p>
			   <p><strong>Cookies “estrictamente necesarias”</strong> Son aquellas cookies consideradas imprescindibles para la
				   navegación por la Página Web, pues facilitan al usuario la utilización de sus prestaciones o herramientas como,
				   por ejemplo, identificar la sesión, acceder a partes de acceso restringido, recordar los elementos que integran un
				   pedido, realizar el proceso de compra de un pedido, entre otros. Sin estas cookies, las funciones mencionadas
				   quedarían inhabilitadas.</p>
			   <p><strong>Cookies sobre comportamiento</strong>
				   <p>Son aquellas cookies cuyo objetivo es la recopilación de información relativa a los usos de navegación del
					   usuario, como por ejemplo las páginas que son más visitadas, los mensajes de error, entre otros, con el objetivo
					   por parte del responsable, de introducir mejoras en la página web en base a la información recopilada por estas
					   cookies.</p>
				   <p>La información recopilada por estas cookies es anónima no pudiendo identificar al usuario personalmente y, por
					   lo tanto, se utiliza exclusivamente para el correcto funcionamiento de la página web.</p>
			   </p>
			   <p><strong>Cookies de funcionalidad</strong>
				   <p>Son aquellas cookies que permiten recordar a la página web las decisiones que el usuario toma, por ejemplo: se
					   almacenará la ubicación geográfica del usuario para asegurar que se muestra el sitio web indicado para su región,
					   el nombre de usuario, el idioma, el tipo de navegador mediante el cual se accede a la página web, entre otros. El
					   objetivo es la prestación de un servicio más personalizado.</p>
				   <p>La información recopilada por estas cookies es anónima no pudiendo identificar al usuario personalmente y, por
					   lo tanto, se utiliza exclusivamente para el funcionamiento de la página web.</p>
			   </p>
			   <p><strong>Cookies de personalización y de publicidad</strong>
				   <p>Son aquellas cookies que se utilizan por los operadores publicitarios con el permiso del titular de la página
					   web, las cuales recogen información sobre las preferencias y elecciones del usuario en su navegación por sitios
					   web. Se dirigen a las redes de publicidad quienes las utilizan para luego mostrar al usuario anuncios
					   personalizados en otros sitios web.</p>
			   </p>
		   </li>
		   <li>
			   <p>Cuadro resumen de cookies utilizadas en el Sitio Web</p>
			   <p><strong>GRUPO EDELVIVES</strong> utiliza cookies para personalizar la navegación del usuario por los Sitios Web
				   de su titularidad, cookies de entrada de usuario y cookies de sesión para equilibrar la carga, las cuales están
				   excluidas del ámbito de aplicación del artículo 22.2 de la LSSI.</p>
			   <p>Por otra parte, <strong>GRUPO EDELVIVES</strong> utiliza cookies de analítica web para medir y analizar la
				   navegación de los usuarios en los Sitios Web. <strong>GRUPO EDELVIVES</strong> utiliza los datos obtenidos con el
				   fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios. La analítica web no
				   permite obtener información sobre el nombre, apellidos o dirección de correo electrónico o postal del usuario. La
				   información obtenida es la relativa al número de usuarios que acceden a la web, el número de páginas vistas, la
				   frecuencia y repetición de las visitas, su duración, el navegador utilizado, el operador que presta el servicio,
				   el idioma, el terminal utilizado o la ciudad a la que está asignada la dirección IP.</p>
			   <p>Del mismo modo, <strong>GRUPO EDELVIVES</strong> utiliza cookies de publicidad comportamental para la gestión de
				   los espacios publicitarios en base a criterios determinados y cookies de complemento (plug-in) para intercambiar
				   contenidos sociales. Estas cookies almacenan información del comportamiento de los usuarios obtenida a través de
				   la observación continuada de sus hábitos de navegación, lo que permite mostrar publicidad en función del mismo.</p>
			   <table>
				   <thead>
					   <tr>
						   <td>NOMBRE</td>
						   <td>TITULAR</td>
						   <td>FINALIDAD</td>
						   <td>DURACIÓN</td>
					   </tr>
				   </thead>
				   <tbody>
					   <tr>
						   <td><strong>_ga</strong></td>
						   <td>Google</td>
						   <td>Esta cookie de origen contiene un identificador anónimo usado para distinguir usuarios.</td>
						   <td>2 años o actualización</td>
					   </tr>
					   <tr>
						   <td><strong>_gat</strong></td>
						   <td>Google</td>
						   <td>Esta cookie de origen contiene un identificador para diferenciar entre los diferentes objetos de seguimiento
							   creados en la sesión.</td>
						   <td>10 minutos desde creación o modificación</td>
					   </tr>
					   <tr>
						   <td><strong>_gid</strong></td>
						   <td>Google</td>
						   <td>Esta cookie se usa para distinguir usuarios.</td>
						   <td>24 horas</td>
					   </tr>
					   <tr>
						   <td><strong>NID</strong></td>
						   <td>Google</td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>G_ENABLED_IDPS</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>ci_session_i2c</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>SIDCC</strong></td>
						   <td>Google</td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>OCAK</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>GAPS</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>CheckConnectionTempCookie529</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>clusterBAK</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>clusterD</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>edelvives</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>PHPSESSID</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
					   <tr>
						   <td><strong>endprom</strong></td>
						   <td></td>
						   <td></td>
						   <td></td>
					   </tr>
				   </tbody>
			   </table>
		   </li>
		   <li>
			   <p>Configuración del usuario para evitar Cookies</p>
			   <p>En cumplimiento de la normativa legal vigente, <strong>GRUPO EDELVIVES</strong> ppone a disposición de los
				   usuarios del Sitio Web la información que les permita configurar su navegador/navegadores de Internet para
				   mantener su privacidad y seguridad en relación con las Cookies. Por ello, se facilita la información y los enlaces
				   a los sitios de soporte oficiales de los principales navegadores para que cada usuario pueda decidir si desea o no
				   aceptar el uso de Cookies.</p>
			   <p>Así, es posible bloquear las Cookies a través de las herramientas de configuración del navegador, o bien, cada
				   usuario puede configurar su navegador para que le avise cuando un servidor quiera guardar una Cookie:</p>
			   <ol style="list-style-type: lower-alpha;">
				   <li>
					   <p>Si utiliza Microsoft Internet Explorer, encontrará la opción en el menú Herramientas > Opciones de Internet >
						   Privacidad > Configuración. Para saber más visite <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
						   y <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
				   </li>
				   <li>
					   <p>Si utiliza Firefox, encontrará la opción en el menú Herramientas > Opciones > Privacidad > Cookies. Para
						   saber más visite <a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
				   </li>
				   <li>
					   <p>Si utiliza Chrome, en la sección de Opciones > Opciones avanzadas > Privacidad. Para saber más <a target="_blank"
							href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
				   </li>
				   <li>
					   <p>Si utiliza Opera, en la opción de Seguridad y Privacidad, podrá configurar el navegador. Para saber más visite
						   <a target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
				   </li>
				   <li>
					   <p>Si utiliza Safari encontrará la opción en el menú Preferencias/Privacidad. Más información en: <a target="_blank"
							href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
				   </li>
			   </ol>
		   </li>
	   </ol>
	   <p>Última actualización: 21 de enero de 2019.</p>
   </div>
</div>`;

export default iframeCode;
