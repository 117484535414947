export const ca = {
  accept: "Acceptar",
  annotations: "Notes",
  areas: "Àrees",
  authentication_error:
    "Les dades semblen incorrectes. Revisa-les i torna-ho a intentar.",
  book_abstract: "Resum del llibre",
  catalog_header: "Catàleg",
  clear_filters: "Netejar filtres",
  collections: "Col·leccions",
  bookmarks: "Marcadors",
  books: "Llibres",
  filter_catalog_option: "Catàleg",
  filter_my_books_option: "Els meus llibres",
  filter_teacher_books_option: "Llibres de la classe",
  filters_title: "Filtrar per",
  genres: "Gènere",
  languages: "Idiomes",
  login_with_google: "Inicia sessió amb Google",
  login_legend: "INICIA SESSIÓ",
  login_submit: "Inicia sessió",
  logo_login: "imgs/login/logo.png",
  logout: "Tanca sessió",
  no_results: "No s’han trobat resultats",
  open: "Llegir",
  password_lost: "He oblidat la meva contrasenya",
  password_placeholder: "Contrasenya",
  password_recovery_header: "¿Olvidaste tu contraseña?",
  password_recovery_legend:
    "Escribe tu dirección de correo electrónico y te enviaremos los datos de acceso.",
  password_recovery_submit: "Recuperar",
  password_recovery_ok:
    "En breve recibirás un correo electrónico con los datos de acceso",
  password_recovery_error:
    "El correo electrónico proporcionado no corresponde a ningún usuario registrado",
  publishers: "Editorials",
  read: "Llegit",
  ages: "Edat recomanada",
  remove_from_my_books: 'Treu de "Els meus llibres"',
  search: "Cerca",
  search_input: "Cerca ...",
  signin_legend: "Si encara no tens un compte",
  signin_link: "suscríbete aquí",
  subjects: "Temàtiques",
  username_placeholder: "Correu electrònic",
  values: "Valors",
  education_levels: "Niveles educativos",
  typologies: "Recursos",
  view_abstract: "Resum",
  requestCase: "Votar",
  addClass: "Afegir a la meva classe",
  addBook: "Afegir llibre",
  add: "Afegir",
  selectClasses: "Selecciona una o més classes per afegir el llibre",
  wait: "Espera...",
  addWithSuccess: "Llibre afegit correctament a la classe.",
  cancel: "Cancel·la",
  detective: "investigador",
  detectives: "investigadors",
  noDetectives: "Sense detectius",
  requestCaseSuccess: "Votat",
  requestCaseDescription: (
    <>
      <p>
        <strong>T'agradaria disposar d'un cas sobre aquest llibre?</strong>
      </p>
      <p>
        Si vols una proposta didàctica per treballar aquesta lectura amb el teu
        alumnat, fes clic a “Votar”.
      </p>
      <p>
        El recompte dels vots ens ajuda a decidir quins seran els pròxims casos
        disponibles a Ta-tum.
      </p>
      <p>
        Si finalment creem aquest cas, t'enviarem un correu electrònic per
        informar-te.
      </p>
    </>
  ),
  sendWithSuccess: "Hem rebut el teu vot.<p>Moltes gràcies!</p>",
  requestTitle: "Vota!",
  moreReadings: "Més llegits",
  moreRecents: "Més recents",
  orderAsc: "Ordena A-Z",
  orderDesc: "Ordena Z-A",
  orderBy: "Ordena per",
  urlSuscribe: "https://family.ta-tum.com/#subscribe",
  applyFilters: "Aplicar filtres",
  legal: "Avís Legal",
  privacy: "Política de Privacitat",
  cookies: "Política de cookies",
  edelvives: "Grupo Edelvives",
  edelvivesLink: "https://www.edelvives.com/es/index",
};
