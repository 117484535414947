import Button from "../buttons/Button";
import Flex from "../flex/Flex";
import closeImg from "../../assets/imgs/close.png";

interface IPopup {
  accept: string;
  cancel: string;
  title: string;
  subtitle: string;
  onClosePopup: () => void;
  onClickAccept?: () => void;
  children?: any;
  type: string;
}

export default function Popup(props: IPopup) {
  return (
    <Flex customClass={"popup"}>
      <Flex customClass={"middle"}>
        <Flex customClass={`column pop ${props.type}`}>
          <Flex>
            <Flex customClass={"spaceBtw w100"}>
              <p className="title">{props.title}</p>
              <Button onClick={props.onClosePopup} icon={closeImg} />
            </Flex>
          </Flex>
          <p className="subtitle">{props.subtitle}</p>
          <Flex>{props.children}</Flex>
          <Flex customClass={"end footer gap20"}>
            <Button
              onClick={props.onClosePopup}
              custom="gray"
              text={props.cancel}
            />
            <Button
              onClick={props.onClickAccept}
              custom="orange"
              text={props.accept}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
