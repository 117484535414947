import Button from "../../../../../commons/buttons/Button";
import { ReactComponent as MenuIcon } from "../../../../../assets/icons/filter.svg";
import useMenu from "./useMenu";

interface IMenu {
  text?: string;
}

export default function Menu(props: IMenu) {
  const { message, openSideBar } = useMenu();

  const button = (
    <Button
      onClick={openSideBar}
      custom="cream"
      text={props.text}
      iconLeft={<MenuIcon />}
    />
  );

  return (
    (message.filterQty && (
      <div data-qty={message.filterQty} className="bubble">
        {button}
      </div>
    )) ||
    button
  );
}
