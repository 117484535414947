interface IFlex {
  onClick?: () => void;
  customClass?: string | "";
  children?: any;
}
export default function Flex(props: IFlex) {
  return (
    <div onClick={props.onClick} className={`flex ${props.customClass || ""}`}>
      {props.children}
    </div>
  );
}
