import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { es } from "./es";
import { en } from "./en";
import { ca } from "./ca";
import { gl } from "./gl";
import { vsv } from "./vsv";
import { eu } from "./eu";

i18next.use(initReactI18next).init({
  lng: "es",
  fallbackLng: "es",
  resources: {
    ca: {
      translation: ca,
    },
    es: {
      translation: es,
    },
    en: {
      translation: en,
    },
    eu: {
      translation: eu,
    },
    gl: {
      translation: gl,
    },
    vsv: {
      translation: vsv,
    },
  },
  returnObjects: false,
  returnedObjectHandler: (key, value, options) => value,
});

export default i18next;
