import { useContext, useEffect, useState } from "react";
import IFilter from "../../types/IFIlter";
import useManager from "../../hooks/useManager";
import INotify from "../../types/INotify";
import Observer from "../../manager/Observer";
import globalContext from "../../context/Context";

const FILTERS = [
  "languages",
  "ages",
  "education_levels",
  "typologies",
  "subjects",
  "genres",
  "values",
] as const;

interface IFormat {
  collapse: boolean;
  name: string;
  data: any;
}

export default function useSidebar(props: any) {
  const [formatData, setFormatData] = useState<IFormat[]>([]);
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [message, setMessage] = useState({} as INotify);
  const result: any = [];
  const manager = useManager();
  const { user } = useContext(globalContext);

  useEffect(() => {
    const observer = new Observer();
    manager.get().addObserver(observer);
    observer.callback = (props: INotify) => {
      setMessage(props);
    };
    return () => {
      manager.get().removeObserver(observer);
    };
  }, []);

  useEffect(() => {
    FILTERS.map((item) => {
      result.push({
        collapse: false,
        name: item.toString(),
        data: props.data[item],
      });
    });
    setFormatData(result);
    return () => {};
  }, [props.data]);

  async function collapseThis(name: string) {
    const a = [...formatData];
    a.forEach((item) => {
      if (item.name === name) {
        item.collapse = !item.collapse;
      }
    });

    setFormatData(a);
  }

  async function addToFilter(data: IFilter) {
    const a = [...filters].filter((item) => item.guid !== data.guid);
    a.push(data);
    setFilters(a);
  }

  async function removeFilters() {
    const fd = [...formatData];
    fd.forEach((item) => {
      item.data.filter((it: any) => (it.click = false));
    });
    setFormatData(fd);
    setFilters([]);
    props.onClickFilterApply([]);
    manager.notify({ filterQty: 0 });
  }

  async function applyFilter() {
    manager.notify({
      openSideBar: false,
      filterQty: filters.filter((item) => item.add === true).length,
    });
    props.onClickFilterApply(filters);
  }

  async function close() {
    manager.notify({ openSideBar: false });
  }

  return {
    collapseThis,
    formatData,
    filters,
    addToFilter,
    removeFilters,
    applyFilter,
    message,
    close,
    langGuid: user.language_guid,
  };
}
