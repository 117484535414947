import INotify from "../types/INotify";
import Observer from "./Observer";

export default class Manager {
  private static _instance: Manager;
  private uManager = new Observer();

  static getInstance() {
    if (this._instance) {
      return this._instance;
    }

    this._instance = new Manager();
    this._instance.uManager.addObserver(new Observer());
    return this._instance;
  }

  public notify(props: INotify): void {
    this.uManager.notify(props);
  }

  public get() {
    return this.uManager;
  }
}
