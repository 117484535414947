import Filter from "../filter/Filter";
import IFilter from "../../types/IFIlter";
import IPackages from "../../types/IPackages";
import useSidebar from "./useSidebar";
import { nanoid } from "nanoid";
import Flex from "../flex/Flex";
import Button from "../buttons/Button";
import CloseImg from "../../assets/imgs/close.png";
import { ReactComponent as FilerIcon } from "../../assets/icons/filter.svg";

interface ISidebar {
  t: any;
  data: IPackages;
  onClickFilterApply: (filter: IFilter[]) => {};
}

export default function SideBar(props: ISidebar) {
  const {
    collapseThis,
    formatData,
    filters,
    addToFilter,
    removeFilters,
    applyFilter,
    message,
    close,
    langGuid,
  } = useSidebar(props);

  return (
    <Flex customClass={`sideBar${message.openSideBar ? " open" : ""}`}>
      <div className={`wrapper${message.openSideBar ? " open" : ""}`}>
        <Flex customClass={"sheader"}>
          <Flex customClass={"spaceBtw w100"}>
            <Flex customClass={"gap10 aCenter"}>
              <FilerIcon />
              <p>{props.t("filters_title")}</p>
            </Flex>
            <Button onClick={close} icon={CloseImg} />
          </Flex>
        </Flex>
        <div className="filter">
          {formatData.map((item) => {
            return (
              item.data.length > 0 && (
                <Filter
                  onClickHead={() => {
                    collapseThis(item.name);
                  }}
                  key={nanoid()}
                  onChange={addToFilter}
                  title={props.t(item.name)}
                  tag={item.name}
                  data={item}
                  langGuid={langGuid}
                />
              )
            );
          })}
        </div>
        <Flex customClass={"spaceAround footer"}>
          <Button
            onClick={removeFilters}
            disabled={!filters.filter((item) => item.add !== false).length}
            custom="transparent"
            text={props.t("clear_filters")}
          />
          <Button
            onClick={applyFilter}
            custom="orange"
            text={props.t("applyFilters")}
          />
        </Flex>
      </div>
    </Flex>
  );
}
