import Flex from "../../../commons/flex/Flex";

interface IResult {
  text: string;
}

export default function NoResult(props: IResult) {
  return (
    <Flex customClass={"noResult"}>
      <Flex customClass={"middle text"}>{props.text}</Flex>
    </Flex>
  );
}
