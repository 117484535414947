interface IForm {
  onSubmit: (data: any) => {};
  gap?: any;
  children: any;
}
export default function Form(props: IForm) {
  return (
    <form
      autoComplete="off"
      style={{ gap: props.gap || "inherit" }}
      className="flex column"
      onSubmit={props.onSubmit}
    >
      {props.children}
    </form>
  );
}
