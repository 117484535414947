import IOption from "../../types/IOption";
import Flex from "../flex/Flex";
import useDropdown from "./useDropdown";

interface IDropDown {
  data: IOption[];
  onSelectChange: (index: string) => void;
}

export default function Dropdown(props: IDropDown) {
  const { selectHandler, clickOnDrop, open, data, myRef } = useDropdown(props);

  return (
    <div ref={myRef}>
      <Flex customClass="dropdown column">
        <div onClick={clickOnDrop} className={"select"}>
          {data.find((item) => item.selected === true)?.text}
        </div>
        <Flex customClass={`column options${open ? " show" : ""}`}>
          {data.map((item, index) => {
            return (
              <div
                key={index}
                onClick={() => selectHandler(index)}
                className={`option${item.selected ? " selected" : ""}`}
              >
                {item.text}
              </div>
            );
          })}
        </Flex>
      </Flex>
    </div>
  );
}
