import React, { ChangeEvent } from "react";

interface IInput {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  placeHolder?: string;
  value: string;
}
export default function Input(props: IInput) {
  return (
    <input
      onChange={props.onChange}
      type={props.type}
      placeholder={props.placeHolder || ""}
      value={props.value}
    />
  );
}
