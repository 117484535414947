import { useEffect, useRef, useState } from "react";
import IOption from "../../types/IOption";
import useOutside from "../../hooks/useOutside";

export default function useDropdown(props: any) {
  const myRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const [data, setData] = useState<IOption[]>(props.data);
  const outSide = useOutside({ el: myRef });

  useEffect(() => {
    if (outSide) {
      setOpen(false);
    }
  }, [outSide]);

  const selectHandler = (index: number) => {
    const temp = [...data];
    temp.forEach((object) => {
      object.selected = false;
    });

    temp[index].selected = true;

    setData(temp);
    setOpen(!open);
    props.onSelectChange(index.toString());
  };

  async function clickOnDrop() {
    setOpen(!open);
  }

  return {
    selectHandler,
    clickOnDrop,
    open,
    data,
    myRef,
  };
}
