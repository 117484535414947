import { useState } from "react";
import IClassRoom from "../../types/IClassRoom";

export default function useClassList(props: any) {
  const [data, setData] = useState<IClassRoom>(props.data);
  async function onChange(temp: IClassRoom) {
    temp.is_classroom_book = !temp.is_classroom_book;
    setData({ ...temp });
  }

  return { onChange, data };
}
