import Dropdown from "../../../../commons/dropdown/Dropdown";
import Flex from "../../../../commons/flex/Flex";
import Avatar from "../avatar/avatar";
import Find from "../filters/find/find";
import Menu from "../filters/menu/menu";

import TabsMobile from "../filters/tabs/tabsMobile";
import useHeader from "./useHeader";

interface IHeader {
  t: any;
  bookClassClick: () => void;
  catalogClick: () => void;
  myBooksClick: () => void;
  onFindChange: (value: string) => {};
  findValue: string;
  avatar: string;
  onSelectChange: (index: string) => void;
}

export default function HeaderMobile(props: IHeader) {
  const { data, move } = useHeader({
    t: props.t,
  });

  return (
    <>
      <Flex customClass={"header fixed"}>
        <Flex customClass={"column"}>
          <Flex customClass={"spaceBtw top"}>
            <a
              className={`logo${move ? " move" : ""}`}
              target="_blank"
              href={`${
                process.env[
                  `REACT_APP_REQUEST_CASE_${process.env.REACT_APP_ENVIROMENT}`
                ]
              }`}
            ></a>

            <Flex customClass={"gap15"}>
              <Find
                value={props.findValue}
                onFindChange={props.onFindChange}
                placeHolder={props.t("search_input")}
              />
              <Avatar t={props.t} move={move} path={props.avatar} />
            </Flex>
          </Flex>

          <Flex customClass={"border central spaceAround mt5"}>
            <TabsMobile
              t={props.t}
              bookClassClick={props.bookClassClick}
              catalogClick={props.catalogClick}
              myBooksClick={props.myBooksClick}
            />
          </Flex>

          <Flex customClass={"filter spaceAround mobile"}>
            <Flex customClass={"gap10 menu"}>
              <Menu />
            </Flex>
            <Dropdown data={data} onSelectChange={props.onSelectChange} />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
