const iframeCode = `<div class="tyc_legal">
	<h1>LEGE OHARRA</h1>
	<p> Informazioaren Gizarteko eta Merkataritza Elektronikoko Zerbitzuen 34/2002 Legeak, uztailaren 11koak, 10.
		artikuluan ezarritakoa betez, hemen azalduko ditugu <a target="_blank"
			href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> webguneko (aurrerantzean, el
		<strong>“Webgunea”</strong>) titularraren identifikazio datuak:</p>
	<p><strong>TITULARRA</strong>: Grupo Editorial Luis Vives (aurrerantzean, “GRUPO EDELVIVES”)</p>
	<p><strong>IFK</strong>: R 5000274 J</p>
	<p><strong>Helbidea</strong>: Ctra. de Madrid km. 315,700; C.P. 50.012 Zaragoza (España)</p>
	<p>Erakunde hori Instituto de Hermanos Maristas de la Enseñanzakoa da; eta azken hori Justizia Ministerioaren
		Erakunde Erlijiosoen Erregistroan dago inskribatuta 1334-i/28-SE/B zenbakiarekin.</p>
	<p>Webgunera sartu, bertan nabigatu eta Webgunea erabiltzeak esan nahi du berariaz eta argi onartzen dituzula
		erabilera baldintza hauen zehaztapen guztiak eta idatziz eta sinatuta jarritako beste edozein kontraturen balio
		eta eraginkortasun berdina du.</p>
	<p>Baldintza hauek betetzea eskatu ahal izango zaio Webgunean sartu, bertan nabigatu eta Webgunea erabiltzen duen
		orori. Azaldu ditugun baldintza hauekin ados ez bazaude, ez sartu Webgunean, ez nabigatu bertan eta ez erabili
		webguneko orririk.</p>
	<h3>ERABILTZEKO BALDINTZA OROKORRAK</h3>
	<p>Erabilera arau hauek arautzen dute Webgunerako sarrera, bertako nabigazioa eta Webgunearen erabilera. Hala ere,
		<strong>GRUPO EDELVIVES</strong>ek eskubidea du Webgunearen aurkezpena, konfigurazioa eta edukia aldatzeko,
		baita hara sartu eta/edo hura erabiltzeko eskatutako baldintzak aldatzeko ere. Aldaketak indarrean jarri ondoren
		Webguneko edukietara sartzeak eta haiek erabiltzeak esan nahi du onartu egiten direla aldaketak.</p>
	<p>Hala ere, zenbait edukitara sartzeko eta zenbait zerbitzu erabiltzeko, baliteke baldintza partikular jakin batzuk
		bete behar izatea. Argi azalduta egon beharko dute beti baldintza horiek eta erabiltzaileek berariaz onartu
		beharko dituzte. Baldintza partikular horiek ordeztu, osatu edo, hala badagokio, aldatu egingo dituzte erabilera
		baldintza partikular hauek.</p>
	<p><strong>GRUPO EDELVIVES</strong>ek eskubidea du hemen ezarritako baldintzak osorik edo neurri batean aldatzeko
		eta aldaketak erabilera baldintza hauek azaltzen diren modu berean edo erabiltzaileei zuzendutako edozein
		komunikaziorako moduren bidez argitaratuko ditu.</p>
	<h3>SARTZEA ETA SEGURTASUNA</h3>
	<p>Oro har, Erabiltzaileek modu askean eta doan sartu ahal izango dute Webgunera. Hala ere, Grupo Editorial Luis
		Vivesek, indarrean den legeriaren arabera, eskubidea du Webgunearen atal jakinetara sarbidea mugatzeko eta
		Erabiltzaileak, horietara sartzeko, erregistratu egin beharko du eta eskatzen zaion informazio guztia eman,
		eguneratua eta benetakoa (ikus kasu bakoitzean pribatutasun politika).</p>
	<p>
		Adingabeek debekatuta dute Webgunera sartzea.
	</p>
	<p> <strong>GRUPO EDELVIVES</strong>ek ez du inola ere bere gain hartuko amaierako erabiltzaileek erregistrorako
		emandako datuen benetakotasunari lotutako erantzukizunik eta, beraz, erabiltzaile horietako bakoitza izango da
		datuak benetakoak ez izateak ekar ditzakeen ondorioen eta akatsen erantzule.</p>
	<h3>JABETZA INTELEKTUALARI ETA INDUSTRIALARI LOTUTAKO ESKUBIDEAK</h3>
	<p><strong>GRUPO EDELVIVES</strong> da Webgunearen jabetza intelektuala eta industriala ustiatzeko eskubideen
		titularra edo, hala dagokionean, horri lotutako lizentziak ditu, baita Webguneko informazioaren, materialen eta
		edukien jabetza intelektualeko eta industrialeko eskubideen titular ere.</p>
	<p>Ez da inola ere ulertuko erabiltzailea Webgunera sartzeak, han nabigatzeak eta hura erabiltzeak esan nahi duenik
		<strong>GRUPO EDELVIVES</strong>ek eskubide horiei, osorik edo hein batean, uko egiten dienik, eskubide horiek
		besterendu, horien gaineko baimena eman edo lagatzen dituenik.
		Erabiltzaileak Webguneko edukiak eta/edo zerbitzuak erabiltzeko eskubidea du, baina taldeko erabilerarako
		bakarrik.</p>
	<p>Erregistratutako marka edo izenei edo beste ikur bereizgarri batzuei erreferentzia egiteak, izan horien titularra
		<strong>GRUPO EDELVIVES</strong> edo hirugarren enpresa bat, <strong>GRUPO EDELVIVES</strong>en edo legezko
		titularren baimenik gabe erabiltzeko debekua dakar. Webgunera eta/edo bertako edukietara sartzeak, han
		nabigatzeak edo haiek erabiltzeak ez dio erabiltzaileari, inola ere, horietan dauden ikur bereizgarriak
		erabiltzeko eskubiderik emango.</p>
	<p>Webguneko edukien eta/edo zerbitzuen gaineko jabetza intelektualaren eta industrialaren eskubide guztiak
		erreserbatuta daude eta, bereziki, debekatuta dago Webguneko edukiak osorik edo neurri batean helburu
		publikoetarako edo komertzialetarako aldatzea, kopiatzea, erreproduzitzea, publikoki komunikatzea, eraldatzea
		edo banatzea, edozein dela erabilitako bidea eta forma, baldin eta ez bada horretarako aurrez <strong>GRUPO
			EDELVIVES</strong>en edo, hala badagokio, eskubideen titularraren berariazko baimen idatzia eskuratzen
		behintzat.</p>
	<p>Erabiltzaileak <strong>GRUPO EDELVIVES</strong>i informazioa, edozein motatakoa, bidaltzen badio horretarako
		edozein bide erabilita, erabiltzaileak adierazten, bermatzen eta onartzen du eskubidea duela informazio hori
		modu librean bidaltzeko, informazio horrek ez duela inoren eskubiderik urratzen jabetza intelektualari,
		industrialari, sekretu komertzialari edo hirugarrenen beste edozein eskubide aintzat hartuta, eta informazio
		hori ez dela konfidentziala, ez eta hirugarrenentzako kaltegarria ere.</p>
	<p>Erabiltzaileak aitortzen du bere gain hartzen duela pertsonalki edo bere izenean egiten duen edozein
		komunikazioren erantzukizuna eta horretatik salbuetsita uzten duela <strong>GRUPO EDELVIVES</strong>.
		Erantzukizun horretan sartzen da, inolako murrizpenik gabe, komunikazioaren zehaztasuna, legezkotasuna,
		originaltasuna eta titulartasuna.</p>
	<h3>MARKAK</h3>
	<p>Webgune honetan azaltzen diren marka, logotipo eta jabetza industriala duten beste edozein ere <strong>GRUPO
			EDELVIVES</strong>en edo hirugarrenen jabetzakoak dira. Debekatuta dago marka horiek erabiltzea, baldin eta
		ez bada aurrez <strong>GRUPO EDELVIVES</strong>en edo marken jabe diren hirugarrenen idatzizko baimenik
		eskuratzen behintzat.</p>
	<h3>ESTEKAK</h3>
	<h4>BESTE WEBGUNE BATZUETARAKO ESTEKAK</h4>
	<p>Webgunean beste webgune batzuetarako estekak badaude botoi, esteka, banner edo barnean hartutako edukien bidez,
		<strong>GRUPO EDELVIVES</strong>ek jakinarazten du hirugarrenek kudeatzen dituztela horiek zuzenean eta ez duela
		<strong>GRUPO EDELVIVES</strong>ek Webgune honetatik irits daitezkeen beste webguneek eskainitako informazio,
		eduki, produktu edo zerbitzu oro aurrez ezagutzeko eta/edo horiek kontrolatzeko eta/edo onartzeko giza
		baliabiderik, ez eta baliabide teknikorik ere.</p>
	<p>Horren ondorioz, <strong>GRUPO EDELVIVES</strong>ek ezingo du bere gain inolako erantzukizunik hartu Webgunetik
		abiatuta iristeko aukera dagoen web orrialdeei lotutako alderdietan, zehazki, azalpen gisa eta mugatu gabe,
		haren produktuen eta zerbitzuen, esteken eta oro har, beste edozein edukiren, funtzionamenduari, sarbideari,
		datuei, informazioari, fitxategiei, kalitateari eta fidagarritasunari lotuta.</p>
	<p>Hori horrela, Erabiltzaileek modu fidagarrian jakiten badute hirugarren webgune horien bidez garatutako jarduerak
		legez kanpokoak direla edo moralaren eta/edo ordena publikoaren aurkakoak direla, berehala jakinarazi beharko
		diote <strong>GRUPO EDELVIVES</strong>i, hark webgune horietarako esteka desgai dezan. Ahalik eta azkarren
		egingo du <strong>GRUPO EDELVIVES</strong>ek lan hori.</p>
	<p>Nolanahi ere, Webgunetik kanpoko beste webgune batera edozein motatako estekak ezartzeak ez du esan nahiko
		<strong>GRUPO EDELVIVES</strong>en eta kanpoko webgunearen arduradunaren artean inolako harremanik,
		lankidetzarik edo mendetasunik dagoenik.</p>
	<h3>HIRUGARRENEN ESTEKAK</h3>
	<p><strong>GRUPO EDELVIVES</strong>ek erabiltzaileen esku jartzen ditu, zenbait tresna eta aplikazioren bidez,
		estekatzeko baliabideak, erabiltzaileei zenbait webetako kanaletara eta orrialdeetara sartzeko aukera emateko.
		Webgunean aplikazio horiek instalatzearen helburu bakarra erabiltzaileei zenbait plataforma eta sare sozialetan
		kanal horietan sartzeko bidea erraztea da.</p>
	<p>Aplikazio horiek ezartzeak ez du esan nahi inolako harremanik dagoenik <strong>GRUPO EDELVIVES</strong>en eta
		estekatutako plataformaren jabearen, fabrikatzailearen edo banatzailearen artean; ez eta <strong>GRUPO
			EDELVIVES</strong>ek plataforma horietako edukiak eta/edo zerbitzuak onartzen eta onesten dituenik ere,
		plataformen jabea, fabrikatzailea edo banatzailea baita horien arduradun bakarra.</p>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du, inola ere, kanpoko webgune horien jabeekin erabiltzaileei buruzko
		informazio pribaturik partekatuko; izan ere, helburu bakarra erabiltzaileei kanpoko webgune horietara sartzeko
		bidea erraztea da. Hori horrela, erabiltzaileak kanpoko plataforma eta/edo web orri horiei informazioa eman nahi
		badie, bere gain hartu beharko du horren erantzukizuna eta prozesu horretan ez du <strong>GRUPO
			EDELVIVES</strong>ek esku hartuko.
	</p>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du kanal horietako edukirik kontrolatzen eta beraz, erabiltzaileak
		aitortzen du eta onartzen du <strong>GRUPO EDELVIVES</strong>ek ez duela bere gain hartuko erabiltzaileak
		orrialde horietan lor ditzakeen edukien eta zerbitzuen erantzukizunik, ez eta horietan erabilgarri dagoen
		edozein eduki, produktu, zerbitzu, publizitate edo bestelako materialei lotutako erantzukizunik ere. Hori dela
		eta, erabiltzaileak zuhurtzia handiz jokatu behar du estekatutako kanaletako informazioa, edukiak eta zerbitzuak
		erabiltzean eta kanal horietan partekatu nahi duen bere edo hirugarrenen informazioarekin.</p>
	<h3>BESTE WEB ORRI BATZUETATIK GURE WEBGUNERAKO ESTEKAK</h3>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du beste web orri batzuetatik Webgune honetarako estekarik ezartzen uzten,
		baldin eta beste web orri horiek material, informazio edo eduki bidegabeak, legez kanpokoak, iraingarriak,
		lizunak badituzte eta, oro har, legeen, moralaren edo ordena publikoaren, edo oro har onartutako gizarte arauen
		aurkakoak badira.</p>
	<p>Nolanahi ere, erabiltzaileek estekak ezarri ahal izango dituzte haien web orrietan, betiere, aurretik,
		<strong>GRUPO EDELVIVES</strong>i horretarako berariazko baimena eskatzen badiote.</p>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du ahalmenik, ez eta giza baliabide nahiz baliabide teknikorik ere, Webgune
		honetarako estekak ezarrita dituzten webguneek eskainitako informazio, eduki, produktu edo zerbitzu oro
		ezagutzeko, kontrolatzeko eta onartzeko. <strong>GRUPO EDELVIVES</strong>ek ez du bere gain inolako
		erantzukizunik hartuko beste webgune batzuetatik Webgune honetarako esteka ezartzeari lotutako alderdietan,
		zehazki, azalpen gisa eta mugatu gabe, haren produktuen eta zerbitzuen, esteken eta/edo oro har, beste edozein
		edukiren, funtzionamenduari, sarbideari, datuei, informazioari, fitxategiei, kalitateari eta fidagarritasunari
		lotuta.</p>
	<h3>WEBGUNEA ERABILTZEKO BALDINTZAK</h3>
	<p>Ez dago baimenduta eta, beraz, dakarzkien ondorioak erabiltzaileak bakarrik hartu beharko ditu bere gain,
		Webgunera legez kanpoko edo baimendu gabeko helburuekin sartzea eta Webgunea helburu horiekin erabiltzea, izan
		xede ekonomikoa edo ez, eta zehatzago esanda, debekatuta daude zerrenda honetan aipatuko ditugunak, aintzat
		hartuta zerrenda ez dela absolutua:</p>
	<ol>
		<li>
			<p>Webgunea erabiltzea haren funtzionaltasunean edo hirugarren baten ordenagailuan kalteak, etenak,
				eraginkortasun faltak edo akatsak eragiteko moduan;</p>
		</li>
		<li>
			<p>Webgunea erabiltzea birus, kode gaizto edo beste programa edo fitxategi kaltegarriren bat transmititzeko,
				instalatzeko edo argitaratzeko;</p>
		</li>
		<li>
			<p>Webgunea erabiltzea beste erabiltzaile batzuen datu pertsonalak eskuratzeko;</p>
		</li>
		<li>
			<p>Webgunearen bidez, nortasun faltsua erabilita, hirugarren baten lekuan jarrita edo profil bat erabilita
				erregistratzea edo mezu baten jatorriaren identitatearen inguruan beste erabiltzaileei nahasmena sor
				diezaiekeen beste ekintzaren bat egitea;</p>
		</li>
		<li>
			<p>Baimenik gabe sartzea Webgunearen edozein ataletara, Plataformari konektatutako beste sistema edo
				sareetara, <strong>GRUPO EDELVIVES</strong>en zerbitzarietara edo Webgunearen bidez eskainitako
				zerbitzuetara, pirata jarduera edo faltsutze bidez, pasahitzak eskuratuta edo beste edozein modu
				bidegabe erabilita;</p>
		</li>
		<li>
			<p>Bertan behera uztea (edo bertan behera uzten saiatzea) Webgunearen edo hari konektatutako edozein sareren
				segurtasun neurriak edo autentifikazioko neurriak edo Webgune honetan eskaintzen diren edukiei
				atxikitako segurtasun edo babes neurriak;</p>
		</li>
		<li>
			<p>Webgunearen azpiegituran edo <strong>GRUPO EDELVIVES</strong>en sistemetan edo sareetan, edo Webgunera
				konektatutako sistemetan eta sareetan neurrigabeko saturazioa eragiten duen ekintzaren bat egitea;</p>
		</li>
		<li>
			<p>Webgunearen bidez eskaintzen den ekitaldi, lehiaketa, promozio edo beste edozein jarduera edo Webgunearen
				edozein funtzionalitate behar bezala garatzeko oztopoak jartzea, bai legez kanpo edo beste edozein
				modutan, horietarako sarbidea, parte-hartzea edo horien funtzionamendua nahasiz edo nahasten saiatuz,
				edo horien emaitzak faltsutuz eta/edo parte hartzeko iruzurreko metodoak erabiliz, edozein prozeduraren
				bidez eta/edo Erabilera baldintza hauek urratzen dituen edozein praktikaren bidez.</p>
		</li>
	</ol>
	<p>Erabiltzaileak aipatu ditugun betebehar horietakoren bat ez betetzeak ekar dezake <strong>GRUPO
			EDELVIVES</strong>ek Zuzenbideak eta bere eskubide edo betebeharrek eskaintzen dituzten neurriak hartzea eta
		irits liteke erabiltzaile arau hauslearen kontua ezabatzera edo blokeatzera ere eta horrek ez dio
		erabiltzaileari eragin zaizkion kalte-galeren ordaina jasotzeko aukerarik emango.</p>
	<h3>ERANTZUKIZUNAK ETA BERMEAK</h3>
	<p><strong>GRUPO EDELVIVES</strong>ek ezin du bermatu Webguneko informazio eta/edo zerbitzuen erabateko
		fidagarritasuna, erabilgarritasuna edo egiazkotasuna, ez eta Webgunearen bidez eskuragarri dagoen
		dokumentazioaren erabilgarritasuna edo egiazkotasuna ere.</p>
	<p>Horren ondorioz, <strong>GRUPO EDELVIVES</strong> ek ez ditu honako hauek bermatzen, eta ez du haien gaineko
		erantzukizunik ere hartzen: (i) Webguneko edukien jarraitutasuna; (ii) edukietan akatsik ez egotea; (iii)
		Webgunean edo haren zerbitzarian birusik eta/edo bestelako osagai kaltegarririk ez izatea; (iv) Webgunearen
		zaurgarritasuna eta/edo hartutako segurtasun neurriak urratzea ezinezko izatea; (v) Webguneko edukien
		erabilgarritasun falta edo errendimendu falta eta (vi) <strong>GRUPO EDELVIVES</strong>ek Webgunean ezarritako
		baldintzak, arauak eta jarraibideak betetzen ez dituen edonork Webguneari edo hirugarren bati eragindako
		kalte-galerak edo Webgunearen segurtasun sistemak urratuta eragindakoak.</p>
	<p>Hala ere, <strong>GRUPO EDELVIVES</strong>ek adierazten du hartu dituela, ahal izan duen neurrian eta teknikaren
		egoerak aukera eman dion neurrian, Webgunearen funtzionamendua bermatzeko eta sistemaren erroreak ahalik eta
		gehien murrizteko neurri guztiak, bai teknikoak, bai Webgunean argitaratutako edukiei lotutakoak.</p>
	<p>Erabiltzaileak jakiten badu badela edukiren bat bidegabea, legez kanpokoa, legeen aurkakoa edo jabetza
		intelektuala edo industriala, datu pertsonalak babesteari lotuta aplikagarri den araudia eta/edo beste edozein
		eskubide urra dezakeela, berehala jakinarazi beharko dio <strong>GRUPO EDELVIVES</strong>i, hark dagozkion
		neurriak har ditzan.</p>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du bermatzen Webgunearen bidez hirugarrenek eskainitako edukien
		zilegitasuna, fidagarritasuna eta erabilgarritasuna.</p>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du bere gain erantzukizunik hartuko Webgunean kanpoko iturrietatik etorrita
		(kanpoko albistea, kanpoko profesionalen txostenak, etab.) argitaratzen diren informazioen egiazkotasunari,
		osotasunari edo eguneratuta izateari lotuta, ez eta Webguneko esteketatik iristeko modukoak diren beste atari
		batzuetako edukiei lotuta ere.</p>
	<p><strong>GRUPO EDELVIVES</strong>ek ez du bere gain erantzukizunik hartuko aipatutako informazio horien
		erabileragatik sor litezkeen kalteei lotuta.</p>
	<p>Nolanahi ere, <strong>GRUPO EDELVIVES</strong>ek eskubidea du erabilerarako baldintza hauetan zehaztutako
		xedapenak betetzen ez dituzten erabiltzaileei Webgunerako sarbidea, bertan nabigatzea, hura erabiltzea, edukiak
		ostatatzea eta/edo deskargatzea eta/edo Webguneko zerbitzuen erabilera, aldi baterako edo betiko, bertan behera
		uzteko, aldatzeko, mugatzeko, eteteko, aurrez jakinarazita edo jakinarazi gabe, eta erabiltzaileak ez du, horren
		harira, inolako kalte-ordainik eskatzeko aukerarik izango.</p>
	<div class="pprivacy">
		<p>Datu pertsonalak babesteko indarrean eta aplikagarri den legeriak ezarritakoa betez, Webgunea erabiltzean
			emandako datu pertsonal guztiak Pribatutasun Politika atalean ezarritakoaren arabera tratatuko dira.
			Erabiltzaile guztiek berariaz onartu behar dute Pribatutasun Politika Webgunea erabiltzeko eta bertan
			erregistratu ahal izateko.</p>
		<p>Erabilerako baldintza hauek onartzen dituen erabiltzaile orok onartzen du gure Pribatutasun Politika,
			jakinaren gainean egonda, berariaz eta zalantzarik gabe, eta eskubidea izango dute arlo honetan dagozkien
			eskubideak gauzatzeko. Horren berri ematen du aipatutako Pribatutasun Politikak.</p>
		<h3>APLIKAGARRIA DEN LEGEA ETA ESKUMENA DUEN JURISDIKZIOA</h3>
		<p>Erabilerarako Baldintza hauek Espainiako legeak arautzen ditu. Aldeak Madril hiriburuko epaitegi eta
			auzitegien mende jartzen dira berariaz, sor litezkeen gatazkak ebazteko eta beste edozein forori uko eginda.
		</p>
		<p>Gure zerbitzuen erabilerari lotuta erreklamazioak jartzeko, bezeroak “Identifikazioa” atalean adierazitako
			posta fisikoko edo elektronikoko helbidera idatz dezake eta konpromisoa hartzen dugu, une oro, gatazka modu
			adiskidetsuan lortzen saiatzeko.</p>
		<p>Azken eguneratzea: 2019ko urtarrilak 21</p>
		<p>© 2019 GRUPO EDELVIVES. Eskubide guztiak erreserbatuak </p>
		<h3><a name="punto3a"></a>PRIBATUTASUNEKO POLITIKA</h3>
		<ol>
			<li>
				<h3>IDENTIFIKAZIOA</h3>
				<ul>
					<li>Identitatea: <strong>GRUPO EDITORIAL LUIS VIVES</strong> (aurrerantzean, <strong>"GRUPO
							EDELVIVES"</strong>)</li>
					<li>Helbidea: Carretera de Madrid, Km 315,700, 50001-Zaragoza</li>
					<li>IFZ: R5000274J</li>
					<li>Datuen Babeserako Ordezkaria (DBO): gure DBOrekin harremanetan jar zaitezke honako bide
						hauetatik: :
						- Helbide elektronikoa: dpo@edelvives.es
						- Helbidea: Calle Xaudaró, 25, 28034 Madrid.</li>
				</ul>Aipatu, erreferentzian, “Datuen Babeserako Ordezkaria” (“Delegado de Protección de Datos”).
			</li>
			<li>
				<h3>INFORMAZIOA ETA ONESPENA</h3>
				<p>Pribatutasuneko Politika hau onartuta, erabiltzailea informatuta gelditzen da eta onespen libre,
					espezifiko eta zalantzarik gabea ematen du <a target="_blank"
						href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> URLko webgunearen bidez
					(aurrerantzean, <strong>“Webgunea”</strong>) ematen dituen datu pertsonalak <strong>GRUPO
						EDELVIVES</strong>ek trata ditzan; baita egiten duen nabigaziotik ateratako datuak eta
					etorkizunean <strong>GRUPO EDELVIVES</strong>i emango dizkionak ere.</p>
				<p>Erabiltzaileak arretaz irakurri behar du Pribatutasuneko Politika hau. Argi eta sinple idatzita dago,
					erraz uler dadin, eta erabiltzaileak libre eta bere borondatez erabaki behar du
					<strong>GRUPO EDELVIVES</strong>i bere datu pertsonalak eman nahi dizkion edo ez.</p>
				<p>ADINGABEA bazara, zure gurasoen edo tutoreen aurretiko baimena beharko duzu zure datu pertsonalak
					emateko; gurasoak edo tutoreak behar bezala izenpetuta eta haren NANaren kopiarekin batera igorri
					behar duzu <a mailto="rgpd@edelvives.es">rgpd@edelvives.es</a> helbidera.
					<strong>GRUPO EDELVIVES</strong>ek ez du erantzukizunik hartuko, adinaren datu hori jakiteko modurik
					gabe, gurasoaren edo tutorearen baimenik gabe eman diren adingabeen datuen gainean.</p>
			</li>
			<li>
				<h3>DATUAK EMATEKO DERRIGORTASUNA</h3>
				<p>Webgunearen inprimakietan eskatzen diren datuak, oro har, ezarritako helburuak betetzeko nahitaezkoak
					dira (dagokien eremuan aurkakoa adierazten ez bada behintzat).</p>
				<p>Beraz, datu horiek ematen ez badira edo zuzen ematen ez badira, ezingo dira helburu horiek bete. Hala
					ere, Webgunearen edukia modu librean ikusi ahal izango da.</p>
			</li>
			<li>
				<h3>ZER XEDEREKIN TRATATUTAKO DITU GRUPO EDELVIVESEK ERABILTZAILEAREN DATUAK?</h3>
				<p>Webgunearen bidez emandako datuak honako xede hauetarako erabiliko ditu <strong>GRUPO
						EDELVIVES</strong>ek:</p>
				<ol>
					<li><strong>Erabiltzailearen atarian</strong> erregistratzeko emandako datuak:
						<ul>
							<li>
								<p><strong>GRUPO EDELVIVES</strong>en jabetzakoa den eta Webgune hau euskarri duen
									plataforman erabiltzaile gisa erregistratzeko bidea kudeatzea. </p>
							</li>
							<li>
								<p>Atariko edukia kudeatzea.</p>
							</li>
							<li>
								<p>Webgunearen erabilerari buruzko analisia egitea eta erabiltzaileen lehenespenak eta
									jokabidea ezagutzea.</p>
							</li>
						</ul>
					</li>
					<li>
						<p><strong>Harremanetarako inprimakietan eta erabiltzaileei erabilgarri jarritako beste kanal
								batzuetan</strong> emandako datuak:</p>
						<ul>
							<li>
								<p><strong>GRUPO EDELVIVES</strong>en webguneetan harremanetarako eta informazioa
									emateko jarritako bideetan erabiltzaileak eginiko eskaerak kudeatzea.</p>
							</li>
							<li>
								<p>Planteatutako eskaeraren kudeaketa.</p>
							</li>
							<li>
								<p>Webgunearen erabilerari buruzko analisia egitea eta erabiltzaileen lehenespenak eta
									jokabidea ezagutzea.</p>
							</li>
						</ul>
					</li>
				</ol>
			</li>
			<li>
				<h3>ERABILTZAILEAREN ZER DATU TRATATUKO DITU GRUPO EDELVIVESEK? </h3>
				<p><strong>GRUPO EDELVIVES</strong>ek erabiltzailearen datu kategoria hauek tratatuko ditu:</p>
				<ol>
					<li>
						<p><strong><u>Erabiltzailearen atarian</u></strong> erregistratzeko emandako datuak:</p>
						<ul>
							<li>
								<p>Identifikaziorako datuak: izena, abizenak.</p>
							</li>
							<li>
								<p>Harremanetarako datuak: helbidea, helbide elektronikoa, telefono zenbakia.</p>
							</li>
							<li>
								<p>Beste datu batzuk: interesdunek eremu irekietan emandako datuak.</p>
							</li>
							<li>
								<p>Nabigazio datuak.</p>
							</li>
						</ul>
					</li>
					<li>
						<p><strong><u>Harremanetarako inprimakietan eta erabiltzaileei erabilgarri jarritako beste kanal
									batzuetan</u></strong> emandako datuak:</p>
						<ul>
							<li>
								<p>Identifikaziorako datuak: izena, abizenak.</p>
							</li>
							<li>
								<p>Harremanetarako datuak: helbide elektronikoa, telefono zenbakia.</p>
							</li>
							<li>
								<p>Ondasunen eta zerbitzuen transakziorako datuak.</p>
							</li>
							<li>
								<p>Beste datu batzuk: interesdunen Webguneko inprimakietan edo atxikitako dokumentuetan
									jarrita dauden eremu irekietan emandako datuak.</p>
							</li>
							<li>
								<p>Nabigazio datuak.</p>
							</li>
						</ul>
						<p>Erabiltzaileak hirugarrenen datuak ematen baditu, adierazten du hirugarren horien onespena
							duela eta konpromisoa hartzen du haiei Pribatutasuneko Politikako informazioa emateko eta
							salbuetsi egiten du <strong>GRUPO EDELVIVES</strong> bide horretatik etor daitekeen
							erantzukizun orotatik. Hala ere, <strong>GRUPO EDELVIVES</strong>ek aldizkako egiaztapenak
							egin ahal izango ditu aipatutako hori egiaztatu ahal izateko eta dagozkion neurriak hartuko
							ditu datuak babesteko araudia betez.</p>
					</li>
				</ol>
			</li>
			<li>
				<h3>ZER LEGITIMAZIO DU ERABILTZAILEAREN DATUEN TRATAMENDUAK?</h3>
				<p>Honako hau izango da datu pertsonalen tratamenduaren legitimazioa:</p>
				<ol>
					<li>
						<p>1. <stron><u>Erabiltzailearen atarian</u></stron> erregistratzeko emandako datuak: eskatzen
							zaion onespena, edozein unetan atzera bota dezakeena. Hala ere, onespena atzera botaz gero,
							horrek ez dio eragingo aurretik datuei emandako tratamenduaren legitimitateari. Webgunearen
							erabilera buruzko analisia egiteko, <strong>GRUPO EDELVIVES</strong>en interes legitimoa.
						</p>
					</li>
					<li>
						<p>2. <strong><u>Harremanetarako inprimakietarako eta erabiltzaileei erabilgarri jarritako beste
									kanal batzuetarako</u></strong>: erabiltzaileari eskatzen zaion onespena, edozein
							unetan utz dezakeena bertan behera. Hala ere, onespena atzera botaz gero, horrek ez dio
							eragingo aurretik datuei emandako tratamenduaren legitimitateari. Webgunearen erabilerari
							buruzko analisia egiteko, <strong>GRUPO EDELVIVES</strong>en interes legitimoa.</p>
					</li>
				</ol>
				<p>Aipatutako xedeetarako eskuratutako onespenak independenteak dira eta beraz, erabiltzaileak horietako
					bakarra bota ahal izango du atzera eta ez die horrek besteei eragingo.</p>
				<p>Onespen hori atzera botatzeko, Erabiltzaileak <strong>GRUPO EDELVIVES</strong>ekin edozein unetan
					jarri ahal izango du harremanetan, honako bide hau erabilita: <a target="_blank"
						href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>, Erreferentziazko gaian "Datu Pertsonalak"
					(“Datos Personales”) adierazi beharko da.</p>
			</li>
			<li>
				<h3>ZER HARTZAILEREKIN PARTEKATUKO DIRA ERABILTZAILEAREN DATUAK?</h3>
				<p>Erabiltzailearen datuak honako hauei jakinarazi ahal izango zaizkie:</p>
				<ol>
					<li>
						<p>1. <strong><u>Erabiltzailearen atarian</u></strong> erregistratzeko emandako datuak:
							<strong>GRUPO EDELVIVES</strong> kide duen taldeko enpresak, barneko administrazio
							xedeetarako eta/edo aurretik aipatutako xedeetarako bakar-bakarrik.</p>
					</li>
					<li>
						<p>2. <strong><u>Harremanetarako inprimakietarako eta erabiltzaileei erabilgarri jarritako beste
									kanal batzuetan</u></strong> emandako datuak:
							<strong>GRUPO EDELVIVES</strong> kide duen taldeko enpresak, barneko administrazio
							xedeetarako eta/edo aurretik aipatutako xedeetarako bakar-bakarrik.</p>
					</li>
				</ol>
				<p>Gainera <strong>GRUPO EDELVIVES</strong>en hornitzaileek ere eskuragarri izango dituzte datuak eta
					eskuragarritasun hori da legezko betebeharrak eta/edo lehen aipatutako xedeak behar bezala betetzeko
					beharrezkoa dena. Hornitzaile horiek ez dituzte erabiltzailearen datuak haien xedeetarako erabiliko,
					baldin eta ez badu <strong>GRUPO EDELVIVES</strong>ek, aurrez, horien berri eman.</p>
			</li>
			<li>
				<h3> DATUAK GORDETZEA</h3>
				<p>Honako epe hauek beteko dira erabiltzaileen datuak gordetzeari dagokionez:</p>
				<ol>
					<li>
						<p>1. <strong><u>Erabiltzailearen atarian</u></strong> erregistratzeko emandako datuak:
							erabiltzaileak plataformatik baja eman dakiola eskatzen duen arte gordeko dira eta baja
							eskaera hori egin ondoren, haren erabileratik etorritako legezko ekintzak preskribatzeko
							epea amaitu arte. </p>
					</li>
					<li>
						<p>2. <strong><u>Harremanetarako inprimakietarako eta erabiltzaileei erabilgarri jarritako beste
									kanal batzuetarako</u></strong> emandako datuak: eskaerak eta haien erantzunak
							bideratzeko behar den denboran gordeko dira eta epe hori amaitzean, aipatutako eskaerari
							dagozkion legezko ekintza preskribatu arte.</p>
					</li>
				</ol>
			</li>
			<li>
				<h3>ERABILTZAILEAREN ERANTZUKIZUNA</h3>
				<p>Erabiltzaileak:</p>
				<ul>
					<li>
						<p>Bermatzen du hemezortzi (18) urtetik gorakoa dela eta <strong>GRUPO EDELVIVES</strong>i
							emandako datuak egiazkoak, zehatzak, osoak eta eguneratuak direla. Horretarako,
							erabiltzailearen erantzukizuna izango da komunikatzen dituen datu guztien egiazkotasuna eta
							emandako informazioa eguneratuta eduki beharko du, bere benetako egoera isla dezan.</p>
					</li>
					<li>
						<p>Bermatzen du jakinarazi dizkiela hirugarrenei dokumentu honetako alderdiak, baldin eta haien
							datuak eman baditu. Era berean, bermatzen du eskuratu duela hirugarren horiek <strong>GRUPO
								EDELVIVES</strong>i, aipatutako xedeetarako, haien datuak emateko emandako baimena.</p>
					</li>
					<li>
						<p>Webgunearen bidez ematen dituen informazio faltsuen eta okerren erantzule izango da, baita
							<strong>GRUPO EDELVIVES</strong>i edo hirugarrenei horrek eragindako zuzeneko edo zeharkako
							kalte-galeren erantzule ere.</p>
					</li>
				</ul>
			</li>
			<li>
				<h3>ESKUBIDEAK ERABILTZEA.</h3>
				<p>Erabiltzaileak idatzi bat igor diezaioke <strong>GRUPO EDELVIVES</strong>i, edozein unetan eta doan,
					Politika honen goiburuan adierazitako helbidera edo, bestela <a target="_blank"
						href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a> helbidera mezu elektroniko bat bidalita.
					Eta nortasun agiriaren fotokopia bidali behar da idatziarekin batera, honako hauetarako:</p>
				<ul>
					<li>
						<p>Emandako onespenak atzera botatzea.</p>
					</li>
					<li>
						<p>Informazioa eskuratzea, jakiteko ea <strong>GRUPO EDELVIVES</strong>en erabiltzaileari
							buruzko datu pertsonalak tratatzen ari diren edo ez.</p>
					</li>
					<li>
						<p>Datu pertsonaletara sartzea.</p>
					</li>
					<li>
						<p>Oker edo osatugabe zeuden datuak zuzentzea.</p>
					</li>
					<li>
						<p>Datuak ezabatzeko eskatzea, baldin eta, besteak beste, datuak ez badira beharrezkoak jaso
							zirenean zeuden helburuetarako.</p>
					</li>
					<li>
						<p><strong>GRUPO EDELVIVES</strong>en eskutik datuen tratamendua mugatzea lortzea, datuak
							babesteko araudian ezarritako baldintzaren bat betetzen denean.</p>
					</li>
					<li>
						<p>Erabiltzaileak emandako datuen garraiagarritasuna eskatzea araudian ezarritako kasuetan.</p>
					</li>
					<li>
						<p><strong>GRUPO EDELVIVES</strong>eko DBOrekin harremanetan jartzea, helbide hau erabilita: <a
								target="_blank" href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
					</li>
					<li>
						<p>Erreklamazio bat jartzea datu pertsonalak babestearen inguruan, Datuen Babeserako Espainiako
							Agentzian (calle de Jorge Juan 6, 28001 Madrid), interesdunari iruditzen zaionean
							<strong>GRUPOEDELVIVES</strong>ek urratu egin dituela datuen babesari lotuta aplikagarri den
							araudiak aitortzen dizkion eskubideak.</p>
					</li>
				</ul>
			</li>
			<li>
				<h3>SEGURTASUN NEURRIAK.</h3>
				<p><strong>GRUPO EDELVIVES</strong>ek konfidentzialtasun osoz eta sekretua gordetzeko eginbeharra betez
					tratatuko ditu, une oro, erabiltzailearen datuak, aplikagarri den araudian ezarritakoaren arabera
					eta horretarako, datuen segurtasuna bermatzeko eta datuak aldatzea, galtzea, baimenik gabe tratatzea
					edo erabiltzea saihesteko beharrezkoak diren neurri teknikoak eta antolaketa neurriak hartuko ditu
					teknologiaren egoera, gordetako datuen izaera eta zer arriskutan dauden kontuan hartuta.</p>
				<p>Azken eguneratzea: 2019ko urtarrilak 21.</p>
			</li>
		</ol>
	</div>
	<div class="ccokies">
		<h3><a name="punto3a"></a>COOKIEEN POLITIKA</h3>
		<p>Cookien Politika hau <a target="_blank"
				href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> (webguneko (aurrerantzean
			<strong>“Webgunea”</strong>) Lege Oharraren eta Pribatutasun Politikaren parte da. Webgunera sartzeak eta
			bertan nabigatzeak, edo Webguneko zerbitzuak erabiltzeak Lege Oharrean eta Pribatutasun Politikan
			adierazitako baldintzak onartzea dakar.</p>
		<p>Webgunean errazago nabigatzeko, <strong>GRUPO EDITORIAL LUIS VIVES</strong>ek (aurrerantzean <strong>“GRUPO
				EDELVIVES”</strong>), Webgunearen titular denez, jakinarazten dizu cookieak edo datuak biltegiratzeko
			eta berreskuratzeko helburua eta cookieen antzeko funtzionalitatea duten beste gailu batzuk (aurrerantzean
			<strong>“Cookieak”</strong>) erabiltzen dituela. <strong>“GRUPO EDELVIVES”</strong>en helbide soziala Ctra.
			de Madrid km 315,700, 50012 Zaragoza da eta IFZ.: R 5000274 J.</p>
		<p>Hori horrela, eta erabiltzaileei behar bezala nabigatzeko behar duten informazioa bermatzeko, erabiltzaileei
			irakurgai jartzen diegu ondorengo informazio testu hau, cookieak zer diren, gure Webgunean zer motatako
			cookieak ditugun eta nola konfiguratu edo desgaitu daitezkeen azaltzeko.</p>
		<ol>
			<li>
				<p>Zer dira Cookieak?</p>
				<p>Cookieak informazio kantitate txikiak dituzten fitxategiak dira. Erabiltzailearen gailuan
					deskargatzen dira erabiltzailea webgune batean sartzen denean. Cookieen helburu nagusia
					erabiltzailea Webgunera sartzen den bakoitzean, hura ezagutzea da eta, hala, kalitatea hobetu eta
					Webgunearen erabilera hobea eskaintzen da.</p>
				<p>Cookieak funtsezkoak dira Interneten funtzionamendurako, lagundu egiten baitute, beste funtzio
					batzuen artean, Webguneak funtzionamenduan izan ditzakeen akatsak identifikatzen eta konpontzen.</p>
			</li>
			<li>
				<p>Cookie motak</p>
				<p>Webgune honetan, gure cookieak eta hirugarrenenak erabiltzen ditugu. Erabilitako cookieak
					ordenagailuan gordetzen dira denbora jakin batez eta saioko cookie (iraungi egiten dira Webgunetik
					irtetean) edo cookie iraunkorrak esaten zaie (cookien iraupena handiagoa da kasu honetan). Hona
					hemen Webgune honetan erabiltzen diren cookieak:</p>
				<p><strong>“Behar-beharrezkoak” diren cookieak</strong>
				<p>Webgunean nabigatzeko ezinbestekoak diren cookieak; izan ere, erabiltzaileari erraztu egiten die
					prestazioak eta tresnak erabiltzea; adibidez, saioa identifikatzea, sarbide mugatuko ataletara
					sartzea, eskaera bat osatzen duten elementuak gogoraraztea eta eskaera baten erosketa-prozesua
					gauzatzea, besteak beste. Cookie horiek gabe, aipatutako funtzioak desgaituta geldituko lirateke.
				</p>
				<p><strong>Jokabideari lotutako cookieak</strong>
				<p>Cookie hauen helburua erabiltzailearen nabigazio erabileraren inguruko informazioa biltzea da;
					adibidez, gehien bisitatutako orrialdeak eta errore mezuak, hala, arduradunak webgunean hobekuntzak
					egiteko aukera izaten du cookie horiek bildutako informazioan oinarrituta.</p>
				<p>Cookie horiek bildutako informazioa anonimoa da eta ezin dute erabiltzailea pertsonalki identifikatu.
					Beraz, informazioa webguneak behar bezala funtzionatzeko bakarrik erabiltzen da.</p>
				</p>
				<p><strong>Funtzionalitateari lotutako cookieak</strong>
				<p>Cookie hauek webguneari gogorarazten diote erabiltzaileak zer erabaki hartu dituen; adibidez hauek:
					erabiltzailearen kokapen geografikoa gordetzen da hari dagokion eskualderako egokia den webgunea
					erakusteko, erabiltzailearen izena, hizkuntza eta webgunera sartzeko erabiltzen den nabigatzaile
					mota. Helburua zerbitzu pertsonalizatua eskaintzea da.</p>
				<p>Cookie horiek bildutako informazioa anonimoa da eta ezin dute erabiltzailea pertsonalki identifikatu.
					Beraz, informazioa webgunearen funtzionamendurako bakarrik erabiltzen da.</p>
				</p>
				<p><strong>Pertsonalizaziorako eta publizitateari lotutako cookieak</strong>
				<p>Cookie hauek publizitate arloko operadoreek erabiltzen dituzte webgunearen titularraren baimenarekin.
					Erabiltzailea webguneetan dabilenean adierazten dituen lehenespenen eta egiten dituen hautuen
					inguruko informazioa jasotzen dute. Publizitate sareetara zuzenduta egoten dira eta gero, sare
					horiek, iragarki pertsonalizatuak eskaintzen dizkiote erabiltzaileari beste webgune batzuetan.</p>
				</p>
			</li>
			<li>
				<p>Webgunean erabiltzen diren cookieen laburpen taula</p>
				<p><strong>GRUPO EDELVIVES</strong>ek cookieak erabiltzen ditu erabiltzaileak taldearen titularpeko
					Webguneetan egiten duen nabigazioa pertsonalizatzeko, eta erabiltzailearen sarrerako cookieak eta
					saioari lotutako cookieak ere erabiltzen ditu karga orekatzeko eta horiek IGZLren 22.2 artikuluaren
					aplikazio eremutik kanpo daude.</p>
				<p>Bestalde, <strong>GRUPO EDELVIVES</strong>ek web analitikako cookieak erabiltzen ditu erabiltzaileek
					Webguneetan egiten duten nabigazioa neurtzeko eta aztertzeko. <strong>GRUPO EDELVIVES</strong>ek
					hobekuntzak egiteko erabiltzen ditu eskuratutako datuak, erabiltzaileen erabilera datuen inguruko
					analisiaren arabera. Web analitikak ez du aukerarik ematen erabiltzailearen izenari, abizenei eta
					helbide elektronikoari edo posta helbideari buruzko informaziorik eskuratzeko. Eskuratutako
					informazioa honako hauei buruzkoa da: webera sartzen den erabiltzaile kopurua, ikusitako orri
					kopurua, bisiten maiztasuna eta errepikakortasuna, bisiten iraupena, erabilitako nabigatzailea,
					zerbitzua eskaintzen duen operadorea, hizkuntza, erabilitako terminala eta IP helbideari esleitutako
					hiria.</p>
				<p>Era berean, <strong>GRUPO EDELVIVES</strong>ek jokabideari lotutako publizitatearen cookieak
					erabiltzen ditu publizitatea espazioak irizpide jakinen arabera kudeatzeko, eta osagarrirako
					cookieak ere bai (plug-in), eduki sozialak trukatzeko. Cookie horiek erabiltzaileen portaerari
					buruzko informazioa jasotzen dute. Informazio hori nabigazio-ohiturak etengabe behatuta lortzen
					dute, eta horrek publizitatea ohitura horien arabera erakusteko aukera ematen du.</p>
				<table>
					<thead>
						<tr>
							<td>IZENA</td>
							<td>TITULARRA</td>
							<td>XEDEA</td>
							<td>IRAUPENA</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><strong>_ga</strong></td>
							<td>Google</td>
							<td>Jatorrizko cookie honek identifikatzaile anonimo bat du, erabiltzaileak bereizteko
								erabiltzen dena.</td>
							<td>2 urte edo eguneratu arte</td>
						</tr>
						<tr>
							<td><strong>_gat</strong></td>
							<td>Google</td>
							<td>Jatorri cookie honek identifikatzaile bat du saioan sortutako jarraipeneko objektuak
								bereizteko.</td>
							<td>10 minutu sortu edo aldatzen denetik</td>
						</tr>
						<tr>
							<td><strong>_gid</strong></td>
							<td>Google</td>
							<td>Cookie hau erabiltzaileak bereizteko erabiltzen da.</td>
							<td>24 ordu</td>
						</tr>
						<tr>
							<td><strong>NID</strong></td>
							<td>Google</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>G_ENABLED_IDPS</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>ci_session_i2c</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>SIDCC</strong></td>
							<td>Google</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>OCAK</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>GAPS</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>CheckConnectionTempCookie529</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>clusterBAK</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>clusterD</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>edelvives</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>PHPSESSID</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>endprom</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</li>
			<li>
				<p>Erabiltzailearen konfigurazioa, Cookieak saihesteko</p>
				<p>Indarrean den lege araudia betez, <strong>GRUPO EDELVIVES</strong>ek Webgunearen erabiltzaileei
					eskuragarri jartzen die informazioa, Interneteko nabigatzailea/nabigatzaileak, Cookieei lotuta,
					pribatutasuna eta segurtasuna zaintzeko moduan konfigura dezaten/ditzaten. Horregatik, informazioa
					eta nabigatzaile nagusien laguntza ofizialerako guneetarako estekak eskaintzen dira, hala,
					erabiltzaile bakoitzak erabaki dezan Cookieen erabilera onartu edo ez.</p>
				<p>Hala, Cookieak blokeatu egin daitezke nabigatzailea konfiguratzeko tresnen bidez edo bestela,
					erabiltzaile bakoitzak bere nabigatzailea konfigura dezake zerbitzari batek Cookie bat gorde nahi
					duenean abisua jasotzeko moduan:</p>
				<ol style="list-style-type: lower-alpha;">
					<li>
						<p>Microsoft Internet Explorer erabiltzen baduzu, hemen duzu aukera: Tresnak > Interneteko
							aukerak > Pribatutasuna > Konfigurazioa. Informazio gehiago eskuratzeko, sartu hemen: <a
								target="_blank"
								href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
							eta <a target="_blank"
								href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.
						</p>
					</li>
					<li>
						<p>Firefox erabiltzen baduzu, hemen duzu aukera: Tresnak > Aukerak > Pribatutasuna > Cookieak.
							Informazio gehiago eskuratzeko, sartu hemen: <a target="_blank"
								href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.
						</p>
					</li>
					<li>
						<p>Chrome erabiltzen baduzu, hemen: Aukerak > Aukera aurreratuak > Pribatutasuna. Informazio
							gehiago eskuratzeko, sartu hemen: <a target="_blank"
								href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a>
						</p>
					</li>
					<li>
						<p>Opera erabiltzen baduzu, Segurtasuna eta Pribatutasuna aukeran konfiguratu ahal izango duzu
							nabigatzailea. Informazio gehiago eskuratzeko, sartu hemen: <a target="_blank"
								href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a>
						</p>
					</li>
					<li>
						<p>Safari erabiltzen baduzu, Lehenespenak/Pribatutasuna menuan duzu aukera. Informazio gehiago
							eskuratzeko, sartu hemen: <a target="_blank"
								href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a>
						</p>
					</li>
				</ol>
			</li>
		</ol>
		<p>Azken eguneratzea: 2019ko urtarrilak 21.</p>
	</div>
</div>`;

export default iframeCode;