export const gl = {
  accept: "Aceptar",
  annotations: "Notas",
  areas: "Áreas",
  authentication_error:
    "Os datos parecen incorrectos. Revísaos e volve intentalo.",
  book_abstract: "Resumo do libro",
  catalog_header: "Catálogo",
  clear_filters: "Limpar filtros",
  collections: "Coleccións",
  bookmarks: "Marcadores",
  books: "Libros",
  filter_catalog_option: "Catálogo",
  filter_my_books_option: "Os meus libros",
  filter_teacher_books_option: "Libros da clase",
  filters_title: "Filtrar",
  genres: "Xénero",
  languages: "Idiomas",
  login_with_google: "Iniciar sesión con Google",
  login_legend: "INICIAR SESIÓN",
  login_submit: "Iniciar sesión",
  logo_login: "imgs/login/logo.png",
  logout: "Pechar sesión",
  no_results: "Non se encontraron resultados",
  open: "Ler",
  password_lost: "Esquecín o meu contrasinal",
  password_placeholder: "Contrasinal",
  password_recovery_header: "¿Olvidaste tu contraseña?",
  password_recovery_legend:
    "Escribe tu dirección de correo electrónico y te enviaremos los datos de acceso.",
  password_recovery_submit: "Recuperar",
  password_recovery_ok:
    "En breve recibirás un correo electrónico con los datos de acceso",
  password_recovery_error:
    "El correo electrónico proporcionado no corresponde a ningún usuario registrado",
  publishers: "Editoras",
  read: "Lido",
  ages: "Idade recomendada",
  remove_from_my_books: 'Quitar de "Os meus libros"',
  search: "Buscar",
  search_input: "Buscar ...",
  signin_legend: "Se aínda non tes unha conta",
  signin_link: "suscríbete aquí;",
  subjects: "Temáticas",
  username_placeholder: "Correo electrónico",
  values: "Valores",
  education_levels: "Niveles educativos",
  typologies: "Recursos",
  view_abstract: "Resumo",
  requestCase: "Votar",
  addClass: "Engadir á miña clase",
  addBook: "Engadir libros",
  add: "Engadir",
  selectClasses: "Selecciona unha ou máis clases para engadir o libro",
  wait: "Espera...",
  addWithSuccess: "Libro engadido correctamente á clase.",
  cancel: "Cancelar",
  detective: "detective",
  detectives: "detectives",
  noDetectives: "Sen detectives",
  requestCaseSuccess: "Votado",
  requestCaseDescription: (
    <>
      <p>
        <strong>Gustaríache dispoñer dun caso sobre este libro?</strong>
      </p>
      <p>
        Se queres unha proposta didáctica para traballar esta lectura co teu
        alumnado, fai clic en “Votar”.
      </p>
      <p>
        O reconto dos votos axúdanos a decidir cales serán os próximos casos
        dispoñibles en Ta-tum.
      </p>
      <p>
        Se finalmente creamos este caso, enviarémosche un correo electrónico
        para informarte.
      </p>
      sendWithSuccess: "Recibimos o teu voto.<p>Moitas grazas!</p>
    </>
  ),
  requestTitle: "Vota!",
  moreReadings: "Máis lidos",
  moreRecents: "Máis recentes",
  orderAsc: "Ordenar A-Z",
  orderDesc: "Ordenar Z-A",
  orderBy: "Ordenar por",
  urlSuscribe: "https://family.ta-tum.com/#subscribe",
  applyFilters: "Aplicar filtros",
  legal: "Aviso legal",
  privacy: "Política de privacidade",
  cookies: "Política de cookies",
  edelvives: "Grupo Edelvives",
  edelvivesLink: "https://www.edelvives.com/es/index",
};
