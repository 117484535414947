import Button from "../../../../commons/buttons/Button";
import Flex from "../../../../commons/flex/Flex";
import Logout from "../logout/Logout";
import useAvatar from "./useAvatar";

interface IAvatar {
  path: string;
  move: boolean;
  t: any;
}

export default function Avatar(props: IAvatar) {
  const { onClick, open } = useAvatar(props);
  return (
    <>
      <Flex customClass={`avatar${props.move ? " move" : ""}`}>
        <Button onClick={onClick} imgClass="radius" icon={props.path} />
      </Flex>
      {open && <Logout text={props.t("logout")} />}
    </>
  );
}
