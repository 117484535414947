import { nanoid } from "nanoid";
import Button from "../../../../../commons/buttons/Button";
import Flex from "../../../../../commons/flex/Flex";
import useTabs from "./useTabs";

interface ITabs {
  t: any;
  bookClassClick: () => void;
  catalogClick: () => void;
  myBooksClick: () => void;
}

export default function Tabs(props: ITabs) {
  const { tabs, onClickTab } = useTabs(props);

  const tabList = tabs.map((item, index) => {
    return (
      <Button
        key={nanoid()}
        selected={item.selected}
        onClick={() => onClickTab(item.index)}
        text={item.text}
      />
    );
  });

  return <Flex customClass={"tabs"}>{tabList}</Flex>;
}
