import Flex from "../flex/Flex";
import useBullets from "./useBullets";
import IBullets from "../../types/IBullets";

export default function Bullets(props: IBullets) {
  const { list } = useBullets(props);

  const listDom = list.map((item, index) => {
    return (
      <Flex
        key={index}
        customClass={`bullet${props.defaultSelect === index ? " current" : ""}`}
      />
    );
  });

  return <Flex customClass="bullets">{listDom}</Flex>;
}
