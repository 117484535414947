import { Routes, Route, BrowserRouter } from "react-router-dom";

import Login from "./pages/login/Login";
import Toast from "./commons/toast/Toast";
import userContext from "./context/Context";
import Guest from "./pages/guest/Guest";
import Home from "./pages/home/Home";
import ProtectedRoute from "./manager/ProtectRoutes";
import { useState } from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";
import { RemoveTrailingSlash } from "./utils/RemoveTrailingSlash";

export default function App() {
  const [user, setUser] = useState({});

  return (
    <I18nextProvider i18n={i18n}>
      <userContext.Provider
        value={{
          user,
          setUser,
        }}
      >
        <BrowserRouter>
          <Toast />
          <RemoveTrailingSlash />
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route path="/guest" element={<Guest />} />
            <Route path="/login" element={<Login />} />
          </Routes>
        </BrowserRouter>
      </userContext.Provider>
    </I18nextProvider>
  );
}
