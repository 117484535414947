type custom =
  | "orange"
  | "gray"
  | "transparent"
  | "gold"
  | "brown"
  | "brown2"
  | "cream"
  | "circle"
  | "link"
  | undefined;

interface IButton {
  onClick?: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) => void;
  text?: string;
  type?: "submit" | "reset" | "button" | undefined;
  icon?: any;
  custom?: custom;
  disabled?: boolean;
  selected?: boolean;
  imgClass?: string | "";
  iconLeft?: any;
  iconRight?: any;
  qty?: number;
  hide?: boolean;
}
export default function Button(props: IButton) {
  const custom = props.custom ? props.custom : "";
  const stringSrc = typeof props.icon === "string";
  let cls: string = props.selected ? "selected" : "";
  cls += props.icon ? " icon" : "";
  cls += props.custom ? ` ${custom}` : "";

  return (
    <button
      disabled={props.disabled || false}
      className={cls}
      onClick={props.onClick}
      type={props.type || "button"}
    >
      {props.iconLeft && props.iconLeft}
      {props.text ||
        (stringSrc ? (
          <img className={props.imgClass} src={props.icon} />
        ) : (
          props.icon
        ))}
      {props.iconRight && props.iconRight}
    </button>
  );
}
