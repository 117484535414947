import Button from "../../../../commons/buttons/Button";
import Flex from "../../../../commons/flex/Flex";
import useLogout from "./useLogout";
import LogoutImg from "../../../../assets/imgs/logout.png";

interface ILogout {
  text: string;
}

export default function Logout(props: ILogout) {
  const { close, myRef } = useLogout();

  return (
    <div className="logout" ref={myRef}>
      <Flex>
        <Button
          onClick={close}
          text={props.text}
          iconLeft={<img src={LogoutImg} title="logout" />}
        />
      </Flex>
    </div>
  );
}
