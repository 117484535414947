import { nanoid } from "nanoid";
import Flex from "../flex/Flex";
import IFilter from "../../types/IFIlter";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron.svg";
import { ReactComponent as ChevronIconExpand } from "../../assets/icons/chevronExpand.svg";
import ILanguage from "../../types/ILanguage";

interface IFIlterSidebar {
  onChange: (filter: IFilter) => {};
  title: string;
  tag: string;
  data: any;
  onClickHead: () => void;
  langGuid: string;
}

export default function Filter(props: IFIlterSidebar) {
  const data = props.data.data
    .filter((item: any) =>
      item.deleted_at ? item.deleted_at === null : item.show
    )
    .sort((a: any, b: any) => {
      return a.name.localeCompare(b.name);
    });

  const extras = ["collapsible", "column"];
  if (props.data.collapse) {
    extras.push("collapse");
  }

  const sanitize = (input: string) => {
    input = input.replace(/&ntilde;/, "ñ");
    input = input.replace(/&agrave;/, "à");
    input = input.replace(/&eacute;/, "é");
    return input;
  };

  return (
    <Flex customClass={"column gap2 blockFilter"}>
      <Flex onClick={props.onClickHead} customClass={"spaceBtw head"}>
        <div className="item title">{props.title}</div>
        <div className="chevron">
          {props.data.collapse ? <ChevronIcon /> : <ChevronIconExpand />}
        </div>
      </Flex>
      <Flex customClass={extras.join(" ").toString()}>
        {data.map((item: any) => {
          let name = "";

          try {
            name = item.translations.find(
              (lang: any) => lang.language_guid === props.langGuid
            ).name;
            if (!name.length) {
              name = item.name;
            }
          } catch (err) {
            name = item.name;
          }

          return (
            <div key={item.guid} className="item selectable">
              <label>
                <input
                  onChange={(e) => {
                    item.click = !item.click;
                    props.onChange({
                      guid: item.guid,
                      add: item.click,
                      tag: props.tag,
                    });
                  }}
                  type="checkbox"
                  defaultChecked={item.click}
                />

                {sanitize(name)}
                <span className="checkmark"></span>
              </label>
            </div>
          );
        })}
      </Flex>
    </Flex>
  );
}
