import { useEffect, useState } from "react";

interface IImage {
  width?: number;
  height?: number;
  isWide?: boolean;
}

const _HEIGHT = 275;

export default function useImage(imageUrl: string) {
  const [imageDimensions, setImageDimensions] = useState<IImage>({});

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
        isWide: _HEIGHT > img.height,
      });
    };
  }, []);

  return { imageDimensions };
}
