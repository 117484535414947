import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./_index.scss";

console.log(
  "%c~ Ta-tum library",
  "color: #3b1d0a; font-size: 12px; font-weight:bold",
  `\nversion: ${process.env.REACT_APP_VERSION}`
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
