import INotify from "../types/INotify";

export default class Observable {
  observers: any[] = [];

  constructor() {
    this.observers = [];
  }

  addObserver(elem: any) {
    if (!this.observers.includes(elem)) this.observers.push(elem);
  }

  removeObserver(elem: any) {
    this.observers = this.observers.filter((item) => item !== elem);
  }

  notifyObservers(props: INotify) {
    this.observers.forEach((item: any) => {
      item.onEvent(props);
    });
  }
}
