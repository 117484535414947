interface ICheckbox {
  guid: string;
  checked: boolean;
  onChange: () => void;
}
export default function Checkbox(props: ICheckbox) {
  return (
    <div className="checkBox">
      <input
        onChange={props.onChange}
        checked={props.checked}
        id={props.guid}
        type="checkbox"
      />
      <label htmlFor={props.guid}></label>
    </div>
  );
}
