import { useContext, useEffect, useState } from "react";
import IRequestStatus from "../../types/IRequestStatus";
import { useTranslation } from "react-i18next";
import ILogin from "../../types/ILogin";
import IUser from "../../types/IUser";
import { useNavigate } from "react-router-dom";
import globalContext from "../../context/Context";
import { useForm } from "react-hook-form";
import useManager from "../../hooks/useManager";
import authz from "../../api/services/authz";

interface IState {
  requestStatus: IRequestStatus;
  serverError: string;
}

export default function useLogin() {
  const navigate = useNavigate();
  const { setUser } = useContext(globalContext);
  const { register, handleSubmit } = useForm<ILogin>();
  const manager = useManager();

  const { t, i18n } = useTranslation();
  const [state, dispatch] = useState<IState>({
    requestStatus: IRequestStatus.WAIT,
    serverError: "",
  });

  useEffect(() => {
    authz
      .session()
      .then((result) => {
        setUserResponse(result.data.data.user);
      })
      .catch((err) => {
        const localUser = localStorage.getItem("tatum_rps");
        if (localUser) {
          setUser && setUser(JSON.parse(localUser) as IUser);
          navigate("/");
        } else {
          dispatch({
            requestStatus: IRequestStatus.SUCCESS,
            serverError: "",
          });
        }
      });
  }, []);

  async function submit(props: ILogin) {
    dispatchStatus(IRequestStatus.PENDING);
    authz
      .post(props)
      .then((result) => {
        setUserResponse(result.data.data.user);
      })
      .catch((err) => {
        dispatchStatus(IRequestStatus.SUCCESS);
        manager.notify({
          msg: t("authentication_error").toString(),
          hideAfter: true,
        });
      });
  }

  function setUserResponse(data: IUser) {
    localStorage.setItem("tatum_rps", JSON.stringify(data));
    if (!data.language) {
      data.language = "ES";
    }
    setUser && setUser(data);
    i18n.changeLanguage(data.language.toLowerCase());
    navigate("/");
  }

  function dispatchStatus(status: IRequestStatus) {
    dispatch({
      requestStatus: status,
      serverError: "",
    });
  }

  return {
    requestStatus: state.requestStatus,
    serverError: state.serverError,
    t: t,
    register,
    handleSubmit,
    submit: submit,
  };
}
