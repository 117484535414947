import { useEffect, useRef, useState } from "react";
import useOutside from "../../../../hooks/useOutside";
import Observer from "../../../../manager/Observer";
import Manager from "../../../../manager/Manager";
import INotify from "../../../../types/INotify";
import useManager from "../../../../hooks/useManager";

export default function useAvatar(props: any) {
  const myRef = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  const outSide = useOutside({ el: myRef });
  const manager = useManager();

  useEffect(() => {
    const observer = new Observer();
    manager.get().addObserver(observer);
    observer.callback = (props: INotify) => {
      setOpen(false);
    };
    return () => {
      manager.get().removeObserver(observer);
    };
  }, []);

  async function onClick(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) {
    event.stopPropagation();
    setOpen(!open);
  }

  return {
    myRef,
    onClick,
    open,
  };
}
