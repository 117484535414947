import { IRequestClasses } from "../../types/IRequestClasses";
import request from "../client";

interface IClassRoom {
  book: string;
  session: string;
}

const get = (props: IClassRoom) => {
  return request.get(`/user_classrooms?book=${props.book}`, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": props.session,
    },
  });
};

const put = (params: any, session: string) => {
  return request.put(`/book_classrooms`, params, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": session,
    },
  });
};

export default {
  get,
  put,
};
