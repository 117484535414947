import Flex from "../../../../commons/flex/Flex";

interface IBody {
  title?: string;
  author?: string;
}

export default function Body(props: IBody) {
  return (
    <Flex customClass={"column"}>
      <p title={props.title} className="title">
        {props.title}
      </p>
      <p>{props.author}</p>
    </Flex>
  );
}
