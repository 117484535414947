import request from "../client";

const get = (session: string) => {
  return request.get(`/packages_metadata`, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": session,
    },
  });
};

const del = (guid: string, session: string) => {
  return request.delete(`/user_packages/${guid}`, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": session,
    },
  });
};

const post = (guid: string, session: string) => {
  return request.post(
    `/user_packages`,
    {
      package_guid: guid,
    },
    {
      headers: {
        "Content-type": "application/json",
        "X-i2c-session": session,
      },
    }
  );
};

export default {
  get,
  del,
  post,
};
