/*
primero debe aparecer el autor (viene informado con el atributo role en null). Si hay varios, por orden alfabético del apellido.

segundo, el o los ilustradores (viene informado con el atributo role en 'ill'). Si hay varios, por orden alfabético del apellido.

en último lugar, el adaptador (viene informado con el atributo role en 'adp'). Si hay varios, por orden alfabético del apellido.
*/
export const getAuthorsInOrder = (creators:Array<any>) =>{
    // Filtrar por rol
    const authors = creators.filter(creator => creator.role === null);
    const illustrators = creators.filter(creator => creator.role === 'ill');
    const adapters = creators.filter(creator => creator.role === 'adp');
  
    // Función para ordenar por apellido
    const sortByLastName = (a:any, b:any) => {
      const lastNameA = a.name.split(' ').slice(-1)[0];
      const lastNameB = b.name.split(' ').slice(-1)[0];
      return lastNameA.localeCompare(lastNameB);
    };
  
    // Ordenar cada grupo
    authors.sort(sortByLastName);
    illustrators.sort(sortByLastName);
    adapters.sort(sortByLastName);
  
    // Concatenar los grupos en el orden especificado
    return [...authors, ...illustrators, ...adapters];
  }