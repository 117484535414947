import useToast from "./useToast";
import { ReactComponent as TipsIcon } from "../../assets/icons/tips.svg";

export default function Toast() {
  const { data } = useToast();
  return (
    <div
      className={`el-toast ${data.type ? data.type : ""} ${
        data && data.msg && data.msg?.length > 0 ? "" : " off"
      }`}
    >
      <div className="message">
        <span>{data && data.msg}</span>
      </div>
    </div>
  );
}
