import { createContext } from "react";
import IUser from "../types/IUser";

interface UInterface {
  user?: any;
  setUser?: (user: IUser) => void;
}

const globalContext = createContext<UInterface>({ user: {} });

export default globalContext;
