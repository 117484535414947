import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export default (el: any) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const callback = (entry: any) => {
    setIsVisible(entry[0].isIntersecting);
  };

  useEffect(() => {
    if (el) {
      const watch = new IntersectionObserver(callback);
      watch.observe(el);
      return () => watch.unobserve(el);
    }
  }, [el]);

  return isVisible && !!el;
};
