import Button from "../../../../commons/buttons/Button";
import Flex from "../../../../commons/flex/Flex";
import HtmlView from "../../../../commons/htmlView/HtmlView";
import useFooter from "./useFooter";

interface IFooter {
  t: any;
  lang: string;
}

export default function Footer(props: IFooter) {
  const {
    myRef,
    cookieRef,
    open,
    closeView,
    privacyHandler,
    legalHandler,
    cookieHandler,
    showCookie,
    html,
  } = useFooter(props.lang);
  return (
    <>
      <Flex customClass="footerTatum">
        <Flex customClass="middle gap20">
          <Button
            onClick={privacyHandler}
            custom="link"
            text={props.t("privacy")}
          />
          <Button
            onClick={legalHandler}
            custom="link"
            text={props.t("legal")}
          />
          <Button
            onClick={cookieHandler}
            custom="link"
            text={props.t("cookies")}
          />
          <a href={props.t("edelvivesLink")} target="_blank">
            {props.t("edelvives")}
          </a>
        </Flex>
      </Flex>

      <div ref={myRef}>
        <HtmlView
          show={open}
          onClickAccept={closeView}
          accept={props.t("accept")}
        >
          <div ref={cookieRef} className={`cookieBot ${showCookie}`} />
          {!showCookie && (
            <div className="html" dangerouslySetInnerHTML={{ __html: html }} />
          )}
        </HtmlView>
      </div>
    </>
  );
}
