export const en = {
  accept: "Aceptar",
  cancel: "Cancelar",
  annotations: "Notas",
  areas: "Áreas",
  authentication_error:
    "Los datos parecen incorrectos. Revísalos y vuelve a intentarlo.",
  book_abstract: "Resumen del libro",
  catalog_header: "Catálogo",
  clear_filters: "Limpiar filtros",
  collections: "Colecciones",
  bookmarks: "Marcadores",
  books: "Libros",
  filter_catalog_option: "Catálogo",
  filter_my_books_option: "Mis libros",
  filter_teacher_books_option: "Libros de la clase",
  filters_title: "Filtrar",
  genres: "Género",
  languages: "Idiomas",
  login_with_google: "Iniciar sesión con Google",
  login_legend: "INICIAR SESIÓN",
  login_submit: "Iniciar sesión",
  logo_login: "imgs/login/logo.png",
  logout: "Cerrar sesión",
  no_results: "No se han encontrado resultados",
  open: "Leer",
  password_lost: "He olvidado mi contraseña",
  password_placeholder: "Contraseña",
  password_recovery_header: "¿Olvidaste tu contraseña?",
  password_recovery_legend:
    "Escribe tu dirección de correo electrónico y te enviaremos los datos de acceso.",
  password_recovery_submit: "Recuperar",
  password_recovery_ok:
    "En breve recibirás un correo electrónico con los datos de acceso",
  password_recovery_error:
    "El correo electrónico proporcionado no corresponde a ningún usuario registrado",
  publishers: "Editoriales",
  read: "Leído",
  ages: "Edad recomendada",
  remove_from_my_books: 'Quitar de "Mis libros"',
  search: "Buscar",
  search_input: "Buscar ...",
  signin_legend: "Si aún no tienes una cuenta",
  signin_link: "suscríbete aquí",
  subjects: "Temáticas",
  username_placeholder: "Correo electrónico",
  values: "Valores",
  education_levels: "Niveles educativos",
  typologies: "Recursos",
  view_abstract: "Resumen",
  requestCase: "Solicitar caso",
  addClass: "Añadir a mi clase",
  addBook: "Añadir libro",
  add: "Añadir",
  selectClasses: "Selecciona una o más clases para añadir el libro",
  wait: "Espera...",
  addWithSuccess: "Libro añadido correctamente a la clase.",
  detective: "detective",
  detectives: "detectives",
  noDetectives: "Sin detectives",
  requestCaseSuccess: "Caso solicitado",
  requestCaseDescription: (
    <>
      <p>
        <strong>¿Te gustaría disponer de un caso sobre este libro?</strong>
      </p>
      <p>
        Si quieres una propuesta didáctica para trabajar esta lectura con tu
        alumnado, haz clic en “Votar”.
      </p>
      <p>
        El recuento de los votos nos ayuda a decidir cuáles serán los próximos
        casos disponibles en Ta-tum.
      </p>
      <p>
        Si finalmente creamos este caso, te enviaremos un correo electrónico
        para informarte.
      </p>
    </>
  ),
  sendWithSuccess: (
    <>
      <p>Hemos recibido tu voto.</p>
      <p>¡Muchas gracias!</p>
    </>
  ),
  requestTitle: "¡Vota!",
  moreReadings: "Más leídos",
  moreRecents: "Más recientes",
  orderAsc: "Ordenar A-Z",
  orderDesc: "Ordenar Z-A",
  orderBy: "Ordenar por",
  urlSuscribe: "https://family.ta-tum.com/#subscribe",
  applyFilters: "Aplicar filtros",
  legal: "Aviso legal",
  privacy: "Política de privacidad",
  cookies: "Política de cookies",
  edelvives: "Grupo Edelvives",
  edelvivesLink: "https://www.edelvives.com/es/index",
};
