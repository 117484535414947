const iframeCode = `<div class="tyc_legal">
	<h1>AVÍS LEGAL</h1>
	<p>En compliment de l’article 10 de la Llei 34/2002, d’11 de juliol, de Serveis de la Societat de la Informació i
		deComerç Electrònic, a continuació s’exposen les dades identificatives del titular del lloc web <a target="_blank"
		 href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> (en endavant, el <strong>“Lloc Web”</strong>):</p>
	<p><strong>TITULAR</strong>: Grupo Editorial Luis Vives (en endavant, “GRUPO EDELVIVES”)</p>
	<p><strong>CIF</strong>: R 5000274 J</p>
	<p><strong>Domicili</strong>: Ctra. de Madrid km. 315,700; C.P. 50.012 Saragossa (Espanya)</p>
	<p>Entitat pertinent a l’Instituto de Hermanos Maristas de la Enseñanza; Inscrita en el Registre d’Entitats
		Religiosesdel Ministeri de Justícia, amb el número 1334-i/28-SE/B.</p>
	<p>L’accés, navegació i utilització del Lloc Web implica l’acceptació expressa i sense reserves de tots els termes
		deles presents condicions d’ús, tenint la mateixa validesa i eficàcia que qualsevol contracte formalitzat per escrit
		isignat.</p>
	<p>La seva observança i compliment serà exigible respecte de qualsevol persona que accedeixi, navegui o utilitzi la
		Web.Si vostè no està d’acord amb els termes exposats, no accedeixi, navegui o utilitzi cap pàgina del lloc web.</p>
	<h3>CONDICIONS GENERALS D’ÚS</h3>
	<p>Les presents condicions d’ús regulen l’accés, navegació i utilització del Lloc Web, sense perjudici que <strong>GRUPO
			EDELVIVES</strong>
		es reserva el dret a modificar la presentació, configuració i contingut de la Web, així com lescondicionsrequerides
		per al seu accés i/o utilització. L’accés i utilització dels continguts del Lloc Web després de l’entradaen vigor de
		les seves modificacions o canvis suposen l’acceptació d’aquests.</p>
	<p>No obstant això, l’accés a determinats continguts i la utilització de determinats serveis pot trobar-se sotmès
		adeterminades condicions particulars, que seran, en tot cas, clarament mostrades i hauran de ser
		acceptadesexpressament per part dels usuaris. Aquestes condicions particulars podran substituir, completar o, en el
		seu cas,modificar les presents condicions d’ús particulars.</p>
	<p><strong>GRUPO EDELVIVES</strong> es reserva el dret a modificar els termes i condicions aquí estipulades, total
		oparcialment,publicant qualsevol canvi en la mateixa forma en què apareixen aquestes condicions d’ús o a través de
		qualsevol menade comunicació dirigida als usuaris.</p>
	<h3>ACCÉS I SEGURETAT</h3>
	<p>Amb caràcter general, els usuaris podran accedir al lloc web de forma lliure i gratuïta. No obstant això,
		GrupoEditorial Luis Vives es reserva conforme a la legislació vigent el dret de limitar l’accés a determinades àrees
		dellloc web per les quals l’usuari haurà de registrar-se facilitant tota la informació sol·licitada, de
		formaactualitzada i real (vegeu en cada cas la política de privacitat).</p>
	<p>Queda prohibit l’accés al Lloc Web per part de menors d’edat.</p>
	<p>En cap concepte <strong>GRUPO EDELVIVES</strong> es responsabilitzarà de la veracitat de les dades de
		registrefacilitades pelsusuaris finals, per la qual cosa cada un d’aquests són els responsables de les possibles
		conseqüències, errors ifallades que posteriorment puguin derivar-se de la falta de veracitat de les dades.</p>
	<h3>DRETS DE PROPIETAT INTEL·LECTUAL I INDUSTRIAL</h3>
	<p><strong>GRUPO EDELVIVES</strong> és titular o, en el seu cas, compta amb les llicències corresponents sobre els
		dretsd’explotació depropietat intel·lectual i industrial del Lloc Web, així com dels drets de propietat intel·lectual
		i industrial sobrela informació, materials i continguts d’aquesta.</p>
	<p>En cap cas s’entendrà que l’accés, navegació i utilització del Lloc Web per part de l’usuari implica una
		renúncia,transmissió, llicència o cessió total o parcial d’aquests drets per part de <strong>GRUPO EDELVIVES</strong>.L’usuari
		disposa d’undret d’ús dels continguts i/o serveis del Lloc Web dins d’un àmbit estrictament domèstic.</p>
	<p>Les referències a marques o noms comercials registrats, o altres signes distintius, siguin titularitat de <strong>GRUPOEDELVIVES</strong>
		o de terceres empreses, porten implícites la prohibició sobre el seu ús sense el consentiment de<strong>GRUPO
			EDELVIVES</strong> o dels seus legítims titulars. En cap moment, llevat de manifestació expressa en cascontrari,
		l‘accés,navegació o utilització del Lloc Web i/o dels seus continguts, confereix a l’usuari cap dret sobre signes
		distintiusen ell inclosos.</p>
	<p>Queden reservats tots els drets de propietat intel·lectual i industrial sobre els continguts i/o serveis del Lloc
		Webi, en particular, queda prohibit modificar, copiar, reproduir, comunicar públicament, transformar o distribuir,
		perqualsevol mitjà i sota qualsevol forma, la totalitat o part dels continguts inclosos en la Web, per propòsits
		públicso comercials, si no es compta amb l’autorització prèvia, expressa i per escrit de <strong>GRUPOEDELVIVES</strong>
		o, en el seu cas,del titular dels drets corresponents.</p>
	<p>En el cas que l’usuari enviï informació de qualsevol mena a <strong>GRUPO EDELVIVES</strong> a través qualsevol
		delscanals habilitatsa l’efecte, l’usuari declara, garanteix i accepta que té dret a fer-ho lliurement, que aquesta
		informació noinfringeix cap dret de propietat intel·lectual, industrial, secret comercial o qualssevol altres drets
		de tercers, ique aquesta informació no té caràcter confidencial ni és perjudicial per a tercers.</p>
	<p>L’usuari reconeix assumir la responsabilitat, deixant indemne <strong>GRUPO EDELVIVES</strong> per
		qualsevolcomunicació quesubministri personalment o al seu nom, assolint aquesta responsabilitat sense cap restricció
		l’exactitud, legalitat,originalitat i titularitat d’aquesta.</p>
	<h3>MARQUES</h3>
	<p>Les marques, logotips i qualssevol altres de propietat industrial que apareixen en aquest lloc són propietat de<strong>GRUPO
			EDELVIVES</strong> o de terceres parts. Està prohibida la utilització d’aquestes marques sense laprèvia autorització
		escritade <strong>GRUPO EDELVIVES</strong>, o de les terceres parts propietàries de les marques.</p>
	<h3>ENLLAÇOS</h3>
	<h4>ENLLAÇOS A ALTRES PÀGINES WEB</h4>
	<p>En cas que en el Lloc Web es mostressin enllaços a altres pàgines web mitjançant diferents botons, enllaços, bàners
		ocontinguts incrustats, <strong>GRUPO EDELVIVES</strong> informa que aquests es troben directament gestionats
		pertercers, no tenint<strong>GRUPO EDELVIVES</strong> ni mitjans humans, ni tècnics per conèixer de forma prèvia i/o
		controlar i/o aprovartota lainformació, continguts, productes o serveis facilitats per altres llocs web als quals es
		puguin establir enllaços desdel present Lloc Web.</p>
	<p>En conseqüència, <strong>GRUPO EDELVIVES</strong> no podrà assumir cap mena de responsabilitat per qualsevol
		aspecterelatiu a lapàgina web a la qual es pogués establir un enllaç des del Lloc Web, en concret, a títol enunciatiu
		i no limitador,sobre el seu funcionament, accés, dades, informació, arxius, qualitat i fiabilitat dels seus productes
		i serveis, elsseus propis enllaços i/o qualsevol dels seus continguts, en general.</p>
	<p>En aquest sentit, si els usuaris tinguessin coneixement efectiu que les activitats desenvolupades a través
		d’aquestespàgines web de tercers són il·legals o contravenen la moral i/o l’ordre públic, hauran de comunicar-ho
		immediatamenta <strong>GRUPO EDELVIVES</strong> als efectes que es procedeixi a deshabilitar l’enllaç d’accés a
		aquestes, accióque es durà a termeen el menor temps possible.</p>
	<p>En qualsevol cas, l’establiment de qualsevol mena d’enllaç des del Lloc Web a una altra pàgina web aliena
		noimplicarà que existeixi algun tipus de relació, col·laboració o dependència entre GRUPO EDELVIVES i el responsable
		dela pàgina web aliena.</p>
	<h3>ENLLAÇOS DE TERCERS</h3>
	<p><strong>GRUPO EDELVIVES</strong> posa a disposició dels usuaris, a través de diferents eines i aplicacions,
		mitjansd’enllaç quepermeten als usuaris accedir a canals i pàgines en diferents webs. La instal·lació d’aquestes
		aplicacions en el Lloc Web té per únic objectiu facilitar als usuaris l’accés a aquests canals en les diferents
		plataformes i xarxessocials.</p>
	<p>L’establiment d’aquestes aplicacions no implica l’existència de cap relació entre <strong>GRUPO EDELVIVES</strong>
		iel propietari,fabricant o distribuïdor de la plataforma enllaçada, com tampoc l’acceptació i aprovació per part de
		<strong>GRUPOEDELVIVES</strong>dels seus continguts i/o serveis, sent el seu propietari, fabricant o distribuïdor
		l’únic responsable d’aquests.</p>
	<p>En cap cas, <strong>GRUPO EDELVIVES</strong> comparteix amb tals propietaris de tals pàgines webs externes, cap
		menad’informacióprivada sobre els seus usuaris, sent la seva única finalitat facilitar l’accés a elles per part dels
		usuaris. Enaquest sentit, tota la informació que el mateix usuari desitgi proporcionar a aquestes plataformes i/o
		pàgines websexternes, serà sota la seva pròpia responsabilitat, no intervenint en aquest procés <strong>GRUPO
			EDELVIVES</strong>.</p>
	<p>Com que <strong>GRUPO EDELVIVES</strong> no té cap control sobre el contingut allotjat en aquests canals,
		l’usuarireconeix i acceptaque <strong>GRUPO EDELVIVES</strong> no assumeix cap responsabilitat pel contingut ni pels
		serveis als quals l’usuaripugui accedir enaquestes pàgines ni per qualsevol contingut, productes, serveis,
		publicitat, ni qualsevol altre material disponibleen aquests. Per tal motiu, l’usuari ha d’extremar la prudència en
		la valoració i utilització de la informació,continguts i serveis existents en els canals enllaçats, i sobre la
		informació pròpia o de tercers que vulguicompartir en aquests canals.</p>
	<h3>ENLLAÇOS EN ALTRES PÀGINES WEB AMB DESTÍ AL LLOC WEB</h3>
	<p><strong>GRUPO EDELVIVES</strong> no autoritza l’establiment d’un enllaç al present Lloc Web des d’altres pàgines
		webque continguinmaterials, informació o continguts il·lícits, il·legals, degradants, obscens i, en general, que
		contravinguin leslleis, la moral o l’ordre públic, o les normes socials generalment acceptades.</p>
	<p>En tot cas, els usuaris podran establir enllaços en les seves respectives pàgines web sempre que
		sol·licitinl’autorització prèvia i expressa a <strong>GRUPO EDELVIVES</strong>.</p>
	<p><strong>GRUPO EDELVIVES</strong> no té facultat ni mitjans humans i tècnics per conèixer, controlar ni aprovar
		totala informació,continguts, productes o serveis facilitats per altres llocs web que tinguin establerts enllaços amb
		destí al presentlloc Web. <strong>GRUPO EDELVIVES</strong> no assumeix cap mena de responsabilitat per qualsevol
		aspecte relatiu ales pàgines web queestableixin l’enllaç amb destí al present Lloc Web, en concret, a títol
		enunciatiu i no taxatiu, sobre el seufuncionament, accés, dades, informació, arxius, qualitat i fiabilitat dels seus
		productes i serveis, els seus propisenllaços i/o qualsevol dels seus continguts, en general.</p>
	<h3>CONDICIONS D’ÚS DEL LLOC WEB</h3>
	<p>No està permès i, per tant, les seves conseqüències seran de l’exclusiva responsabilitat de l’usuari, l’accés o
		lautilització del Lloc Web amb finalitats il•legals o no autoritzades, amb finalitat econòmica o sense, i,
		mésespecíficament i sense que el següent llistat tingui caràcter absolut, queda prohibit:</p>
	<ol>
		<li>
			<p>Utilitzar el Lloc Web en cap forma que pugui provocar danys, interrupcions, ineficiències o defectes en la
				sevafuncionalitat o en l’ordinador d’un tercer;</p>
		</li>
		<li>
			<p>Utilitzar el Lloc Web per a la transmissió, la instal•lació o la publicació de qualsevol virus, codi maliciós
				oaltres programes o arxius perjudicials;</p>
		</li>
		<li>
			<p>Utilitzar el Lloc Web per recollir dades de caràcter personal d’altres usuaris;</p>
		</li>
		<li>
			<p>Registrar-se a través del Lloc Web amb una identitat falsa, suplantant a tercers o utilitzant un perfil
				orealitzant qualsevol altra acció que pugui confondre altres usuaris sobre la identitat de l’origen d’un missatge;</p>
		</li>
		<li>
			<p>Accedir sense autorització a qualsevol secció del Lloc Web, a altres sistemes o xarxes connectades a la
				Plataforma,a cap servidor de <strong>GRUPO EDELVIVES</strong> ni als serveis oferts a través del Lloc Web, per
				mitjà depirateria ofalsificació, extracció de contrasenyes o qualsevol altre mitjà il·legítim;</p>
		</li>
		<li>
			<p>Crebantar, o intentar crebantar, les mesures de seguretat o autenticació del Lloc Web o de qualsevol
				xarxaconnectada a aquest, o les mesures de seguretat o protecció inherents als continguts oferts en el Lloc Web;</p>
		</li>
		<li>
			<p>Dur a terme alguna acció que provoqui una saturació desproporcionada o innecessària en la infraestructura del
				Lloc Web o en els sistemes o xarxes de <strong>GRUPO EDELVIVES</strong>, així com en els sistemes i xarxes
				connectats alLloc Web;</p>
		</li>
		<li>
			<p>Impedir el normal desenvolupament d’un esdeveniment, concurs, promoció o qualsevol altra activitat disponible
				através del Lloc Web o qualssevol de les seves funcionalitats, sigui alterant o tractant d’alterar, il·legalment
				ode qualsevol altra forma, l’accés, participació o funcionament d’aquells, o falsejant el resultat dels mateixos
				i/outilitzant mètodes de participació fraudulents, mitjançant qualsevol procediment, i/o a través de
				qualsevolpràctica que atempti o vulneri de qualsevol manera les presents Condicions d’ús.</p>
		</li>
	</ol>
	<p>L’incompliment de qualsevol de les anteriors obligacions per part de l’usuari, podrà portar aparellada l’adopció
		per<strong>GRUPO EDELVIVES</strong> de les mesures oportunes emparades en dret i en l’exercici dels seus drets
		oobligacions, podentarribar a l’eliminació o bloqueig del compte de l’usuari infractor, sense que hi hagi
		possibilitat de capindemnització pels danys i perjudicis causats.</p>
	<h3>RESPONSABILITATS I GARANTIES</h3>
	<p><strong>GRUPO EDELVIVES</strong> no pot garantir la fiabilitat, utilitat o veracitat d’absolutament tota
		lainformació i/o delsserveis del Lloc Web, ni tampoc de la utilitat o veracitat de la documentació posada a
		disposició a través de lamateixa.</p>
	<p>En conseqüència, <strong>GRUPO EDELVIVES</strong> no garanteix ni es fa responsable de: (i) la continuïtat
		delscontinguts del Lloc Web; (ii) l’absència d’errors en aquests continguts; (iii) l’absència de virus i/o altres
		components danyosos en elLloc Web o en el servidor que el subministra; (iv) la invulnerabilitat del Lloc Web i/o la
		impossibilitat de vulnerarles mesures de seguretat que s’adopten en aquesta; (v) la falta d’utilitat o rendiment dels
		continguts del Lloc Web;i (vi) els danys o perjudicis que causi, a si mateix o a un tercer, qualsevol persona que
		infringís les condicions,normes i instruccions que <strong>GRUPO EDELVIVES</strong> estableix en el Lloc Web o a
		través de la vulneració delssistemes deseguretat del Lloc Web.</p>
	<p>No obstant això, <strong>GRUPO EDELVIVES</strong> declara que ha adoptat totes les mesures necessàries, dins de
		lessevespossibilitats i de l’estat de la tècnica, per garantir el funcionament del Lloc Web i reduir al mínim els
		errors delsistema, tant des del punt de vista tècnic, com dels continguts publicats en el Lloc Web.</p>
	<p>Si l’usuari tingués coneixement de l’existència d’algun contingut il·lícit, il·legal, contrari a les lleis o
		quepogués suposar una infracció de drets de propietat intel·lectual o industrial, de la normativa aplicable en
		materialde protecció de dades personals i/o qualsevol altre dret, haurà de notificar-ho immediatament a <strong>GRUPO
			EDELVIVES</strong>perquè
		aquest pugui procedir a l’adopció de les mesures oportunes.</p>
	<p><strong>GRUPO EDELVIVES</strong> no garanteix la licitud, fiabilitat i utilitat dels continguts subministrats
		pertercers a través delLloc Web.</p>
	<p><strong>GRUPO EDELVIVES</strong> no serà responsable sobre la veracitat, integritat o actualització de
		lesinformacions publicades alLloc Web provinents de fonts alienes al mateix (notícies externes, informes de
		professionals externs, etc.), així comtampoc de les contingudes en altres portals web mitjançant enllaç des del Lloc
		Web.</p>
	<p><strong>GRUPO EDELVIVES</strong> no assumirà responsabilitat quant a hipotètics perjudicis que poguessin
		originar-seper l’ús de lescitades informacions.</p>
	<p>En tot caso, <strong>GRUPO EDELVIVES</strong> es reserva el dret a suspendre, modificar, restringir o
		interrompre,temporal opermanentment, l’accés, navegació, ús, allotjament i/o descàrrega del contingut i/o ús de
		serveis del Lloc Web, ambprèvia notificació o sense, als usuaris que contravinguin qualsevol de les disposicions
		detallades en les presentscondicions d’ús, sense que hi hagi possibilitat de l’usuari d’exigir cap indemnització per
		aquesta causa.</p>
	<div class="pprivacy">
		<p>De conformitat amb el que disposa la legislació vigent i aplicable en matèria de protecció de dades de
			caràcterpersonal, totes les dades de caràcter personal facilitades durant la utilització del Lloc Web seran
			tractades deconformitat amb els disposats en la Política de Privacitat que tot usuari ha d’acceptar expressament per
			poderutilitzar i registrar-se en el Lloc Web.</p>
		<p>Tot usuari que accepti les presents condicions d’ús, accepta de forma informada, expressa i inequívoca la
			nostraPolítica de Privacitat, podent exercir els drets que en aquesta matèria li corresponen, segons informa la
			mencionadaPolítica de Privacitat.</p>
		<h3>LLEI APLICABLE I JURISDICCIÓ COMPETENT</h3>
		<p>Aquestes Condicions d’ús es regeixen per la llei espanyola. Les parts se sotmeten expressament, per la
			resoluciódels conflictes i amb renúncia a qualsevol altre fur, als jutjats i tribunals de Madrid capital.</p>
		<p>Per presentar reclamacions en l’ús dels nostres serveis el client pot dirigir-se per correu a l’adreça
			electrònicao física indicada en l’apartat “Identificació”, comprometent-nos a buscar en tot moment una solució
			amistosa delconflicte.</p>
		<p>Última actualització: 21 de gener de 2019</p>
		<p>© 2019 GRUPO EDELVIVES Tots els drets reservats</p>
		<h3><a name="punto3a"></a>POLÍTICA DE PRIVACITAT</h3>
		<ol>
			<li>
				<h3>IDENTIFICACIÓ</h3>
				<ul>
					<li>Identitat: <strong>GRUPO EDITORIAL LUIS VIVES</strong> (en endavant, <strong>"GRUPO EDELVIVES"</strong>)</li>
					<li>Domicili: Carretera de Madrid, Km 315,700, 50001-Saragossa</li>
					<li>NIF: R5000274J</li>
					<li>Delegat de Protecció de Dades (DPO): pot contactar amb el nostre DPO a través dels següents mitjans:- E-mail:
						dpo@edelvives.es- Adreça: Carrer Xaudaró, 25, 28034, Madrid.</li>
				</ul>Indicant en la referència “Delegado de Protección de Datos”.
			</li>
			<li>
				<h3>INFORMACIÓ I CONSENTIMENT.</h3>
				<p>Mitjançant l’acceptació de la present Política de Privacitat, l’usuari queda informat i presta el seu
					consentimentlliure, informat, específic i inequívoc perquè les dades personals que faciliti a través de la pàgina
					web situadaen l’URL <a target="_blank" href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> (en
					endavant, el <strong>“Lloc Web”</strong>) siguin tractades per <strong>GRUPO EDELVIVES</strong>, així com les dades
					derivades de la sevanavegació i aquelles altres dades que pugui facilitar en un futur a <strong>GRUPO EDELVIVES</strong>.</p>
				<p>L’usuari ha de llegir amb atenció aquesta Política de Privacitat, que ha estat redactada de forma clara
					isenzilla, per a facilitar la seva comprensió, determinant lliure i voluntàriament si desitja facilitar les
					sevesdades personals a <strong>GRUPO EDELVIVES</strong>.</p>
				<p>Si ets <strong>MENOR D’EDAT</strong>, només pots aportar dades personals amb el previ consentiment de pares
					otutors, enviat degudament signat i amb còpia del DNI del pare o tutor signant a l’adreça rgpd@edelvives.es.<strong>GRUPO
						EDELVIVES</strong> norespon de les dades de menors que sense poder conèixer aquest fet, s’hagin facilitat sense
					consentiment de pare otutor.</p>
			</li>
			<li>
				<h3>OBLIGATORIETAT DE FACILITAR LES DADES.</h3>
				<p>Les dades sol·licitades en els formularis del Lloc web són amb caràcter general, obligatòries (tret que en el
					camprequerit s’especifiqui el contrari) per complir amb les finalitats establertes.</p>
				<p>Per tant, si aquestes no es faciliten o no es faciliten correctament no podran atendre’s les mateixes,
					senseperjudici que podrà visualitzar lliurement el contingut del Lloc web.</p>
			</li>
			<li>
				<h3>AMB QUINA FINALITAT TRACTARÀ GRUPO EDELVIVES LES DADES PERSONALS DE L’USUARI?</h3>
				<p>Les dades personals facilitades a través del Lloc Web seran tractades per <strong>GRUPO EDELVIVES</strong>conforme
					a les següentsfinalitats:</p>
				<ol>
					<li>Dades facilitades per al registre del <strong><u>Portal d’usuari</u></strong>:
						<ul>
							<li>
								<p>Gestionar el seu registre com a usuari en la plataforma en línia propietat de <strong>GRUPO EDELVIVES</strong>i
									allotjada enel present Lloc Web.</p>
							</li>
							<li>
								<p>Gestionar el contingut del Portal.</p>
							</li>
							<li>
								<p>Efectuar anàlisis sobre la utilització del Lloc Web i comprovar les preferències i comportament delsusuaris.</p>
							</li>
						</ul>
					</li>
					<li>
						<p>Dades facilitades en els <strong><u>formularis de contacte i altres canals posats a disposició dels usuaris</u></strong>:</p>
						<ul>
							<li>
								<p>Gestionar les sol·licituds de contacte i informació de l’usuari a través dels canals disposats per a això
									enels llocs web de <strong>GRUPO EDELVIVES</strong>.</p>
							</li>
							<li>
								<p>Gestió de la petició plantejada.</p>
							</li>
							<li>
								<p>Efectuar anàlisis sobre la utilització del Lloc Web i comprovar les preferències i comportament delsusuaris.</p>
							</li>
						</ul>
					</li>
				</ol>
			</li>
			<li>
				<h3>QUINES DADES DE L’USUARI TRACTARÀ GRUPO EDELVIVES?</h3>
				<p><strong>GRUPO EDELVIVES</strong> tractarà les següents categories de dades de l’usuari:</p>
				<ol>
					<li>
						<p>Dades facilitades per al registre del <strong><u>Portal d’usuari</u></strong></p>
						<ul>
							<li>
								<p>Dades identificatives: nom, cognoms.</p>
							</li>
							<li>
								<p>Dades de contacte: domicili, adreça de correu electrònic, número de telèfon.</p>
							</li>
							<li>
								<p>Altres dades: dades facilitades pels mateixos interessats en els camps oberts.</p>
							</li>
							<li>
								<p>Dades de navegació.</p>
							</li>
						</ul>
					</li>
					<li>
						<p>Dades facilitades en els <strong><u>formularis de contacte i altres canals posats a disposició dels usuaris</u></strong>:</p>
						<ul>
							<li>
								<p>Dades identificatives: nom, cognoms.</p>
							</li>
							<li>
								<p>Dades de contacte: adreça de correu electrònic, número de telèfon.</p>
							</li>
							<li>
								<p>Dades de transacció de béns i serveis.</p>
							</li>
							<li>
								<p>Altres dades: dades facilitades pels mateixos interessats en els camps oberts dels formularis disposats enel
									Lloc Web o en els documents adjunts.</p>
							</li>
							<li>
								<p>Dades de navegació.</p>
							</li>
						</ul>
						<p>En cas que l’usuari faciliti dades de tercers, manifesta comptar amb el consentiment d’aquests i es compromet
							atraslladar-los la informació continguda en la Política de Privacitat, eximint a <strong>GRUPO EDELVIVES</strong>de
							qualsevolresponsabilitat en aquest sentit. No obstant això, <strong>GRUPO EDELVIVES</strong> podrà dur a terme
							lesverificacionsperiòdiques per constatar aquest fet, adoptant les mesures de diligència deguda que
							corresponguin, conforme a lanormativa de protecció de dades.</p>
					</li>
				</ol>
			</li>
			<li>
				<h3>QUINA ÉS LA LEGITIMACIÓ DEL TRACTAMENT DE LES DADES DE L’USUARI?</h3>
				<p>La legitimació del tractament de les seves dades personals, seran les següents:</p>
				<ol>
					<li>
						<p>Dades facilitades per al registre de la <strong><u>Portal d’usuari</u></strong>: el consentiment que se
							lisol·licita i que potretirar en qualsevol moment. No obstant això, en el cas de retirar el seu consentiment,
							això no afectarà lalicitud dels tractaments efectuats amb anterioritat. Per a l’execució d’anàlisis sobre la
							utilització de la web,l’interès legitimo de <strong>GRUPO EDELVIVES</strong>.</p>
					</li>
					<li>
						<p>Per als <strong><u>formularis de contacte i altres canals posats a disposició dels usuaris</u></strong>:
							elconsentiment que se lisol·licita i que pot retirar en qualsevol moment. No obstant això, en cas de retirar el
							seu consentiment, aixòno afectarà la licitud dels tractaments efectuats amb anterioritat. Per a l’execució
							d’anàlisis sobre lautilització de la web, l’interès legítim de <strong>GRUPO EDELVIVES</strong>.</p>
					</li>
				</ol>
				<p>Els consentiments obtinguts per a les finalitats mencionades són independents, per la qual cosa l’usuari
					podràrevocar només un d’ells sense afectar els altres.</p>
				<p>Per revocar aquest consentiment, l’usuari podrà contactar amb <strong>GRUPO EDELVIVES</strong>, en
					qualsevolmoment, a través delscanals següents: <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
					indicant en l’assumpte de referència“Datos Personales”.</p>
			</li>
			<li>
				<h3>AMB QUINS DESTINATARIS ES COMPARTIRAN LES DADES DE L’USUARI? </h3>
				<p>Les dades de l’usuari podran ser comunicades a:</p>
				<ol>
					<li>
						<p>Dades facilitades per al registre de la <strong><u>Portal d’usuari</u></strong>: Empreses del grup al
							qualpertany <strong>GRUPO EDELVIVES</strong>,únicament per a finalitats administratives internes i/o per les
							finalitats anteriorment indicades.</p>
					</li>
					<li>
						<p>Dades facilitades per als <strong><u>formularis de contacte i altres canals posats a disposició dels usuaris</u></strong>:
							Empreses del
							grup al qual pertany <strong>GRUPO EDELVIVES</strong>, únicament per a finalitats administratives internesi/o
							per a lesfinalitats anteriorment indicades.</p>
					</li>
				</ol>
				<p>Addicionalment, les dades podran ser accessibles per proveïdors de <strong>GRUPO EDELVIVES</strong>, sent
					aquestaccés el necessaripara l’adequat compliment de les obligacions legals i/o de les finalitats anteriorment
					indicades. Aquestsproveïdors no tractaran les seves dades per finalitats pròpies que no hagin estat prèviament
					informades per<strong>GRUPO EDELVIVES</strong>.</p>
			</li>
			<li>
				<h3>CONSERVACIÓ DE LES DADES</h3>
				<p>Les seves dades seran conservades durant els següents terminis:</p>
				<ol>
					<li>
						<p>Dades facilitades per al registre de la <strong><u>Portal d’usuari</u></strong>: seran conservades fins
							quel’usuari no sol·liciti labaixa d’aquesta i, un cop sol•licitada, durant el termini de prescripció d’accions
							legals derivades de l’úsd’aquesta.</p>
					</li>
					<li>
						<p>Dades facilitades per als <strong><u>formularis de contacte i altres canals posats a disposició dels usuaris</u></strong>:
							seran conservades
							durant el termini necessari per donar tràmit i contestació a la seva sol·licitud i, un copfinalitzat aquest,
							durant el termini de prescripció de les accions legals derivades de la citada sol·licitud.</p>
					</li>
				</ol>
			</li>
			<li>
				<h3>RESPONSABILITAT DE L’USUARI.</h3>
				<p>L’usuari:</p>
				<ul>
					<li>
						<p>Garanteix que és major de divuit (18) anys i que les dades que facilita a <strong>GRUPO EDELVIVES</strong>
							sónveritables,exactes, completes i actualitzades. A aquests efectes, l’usuari respon de la veracitat de totes
							les dades quecomuniqui i mantindrà convenientment actualitzada la informació facilitada, de tal forma que
							respongui a la sevasituació real.</p>
					</li>
					<li>
						<p>Garanteix que ha informat els tercers dels quals faciliti les seves dades, en cas de fer-ho, dels
							aspectescontinguts en aquest document. Així mateix, garanteix que ha obtingut la seva autorització para
							facilitar lesseves dades a <strong>GRUPO EDELVIVES</strong> per a les finalitats assenyalades.</p>
					</li>
					<li>
						<p>Serà responsable de les informacions falses o inexactes que proporcioni a través del Lloc Web i dels danys
							iperjudicis, directes o indirectes, que això causi a <strong>GRUPO EDELVIVES</strong> o a tercers.</p>
					</li>
				</ul>
			</li>
			<li>
				<h3>EXERCICI DE DRETS.</h3>
				<p>L’usuari pot enviar un escrit a <strong>GRUPO EDELVIVES</strong>, a l’adreça indicada en l’encapçalament de
					lapresent Política, obé mitjançant un correu electrònic a l’adreça <a target="_blank" href="mailto:rgpd@edelvives.es">rgpd@edelvives.es</a>,
					adjuntantfotocòpia del seu document d’identitat,en qualsevol moment i de manera gratuïta, para:</p>
				<ul>
					<li>
						<p>Revocar els consentiments atorgats.</p>
					</li>
					<li>
						<p>Obtenir confirmació sobre si a <strong>GRUPO EDELVIVES</strong> s’estan tractant dades personals
							queconcerneixen l’usuari o no.</p>
					</li>
					<li>
						<p>Accedir a les seves dades personals.</p>
					</li>
					<li>
						<p>Rectificar les dades inexactes o incompletes.</p>
					</li>
					<li>
						<p>Sol·licitar la supressió de les seves dades quan, entre altres motius, les dades ja no siguin necessàries pera
							les finalitats per les quals van ser recollides.</p>
					</li>
					<li>
						<p>Obtenir de <strong>GRUPO EDELVIVES</strong> la limitació del tractament de les dades quan es compleixi alguna
							deles condicionsprevistes en la normativa de protecció de dades.</p>
					</li>
					<li>
						<p>Sol·licitar la portabilitat de les dades facilitades per l’usuari en aquells casos previstos en la normativa.</p>
					</li>
					<li>
						<p>Posar-se en contacte amb el DPO de <strong>GRUPO EDELVIVES</strong> a través de la següent adreça: <a target="_blank"
							 href="mailto:dpo@edelvives.es">dpo@edelvives.es</a></p>
					</li>
					<li>
						<p>Interposar una reclamació relativa a la protecció de les seves dades personals davant l’Agència Espanyola
							deProtecció de Dades en l’adreça Carrer de Jorge Juan, 6, 28001 Madrid, quan l’interessat consideri que <strong>GRUPO
								EDELVIVES</strong>
							ha vulnerat els drets que li són reconeguts per la normativa aplicable en protecció dedades.</p>
					</li>
				</ul>
			</li>
			<li>
				<h3>MESURES DE SEGURETAT.</h3>
				<p><strong>GRUPO EDELVIVES</strong> tractarà les dades de l’usuari, en tot moment, de forma absolutament
					confidenciali guardant elpreceptiu deure de secret respecte d’aquests, de conformitat amb el que preveu la
					normativa d’aplicació, adoptanta l’efecte les mesures d’índole tècnica i organitzatives necessàries que
					garanteixen la seguretat de les sevesdades i eviten la seva alteració, pèrdua, tractament o accés no autoritzat,
					tenint en compte l’estat de latecnologia, la naturalesa de les dades emmagatzemades i els riscos a què estan
					exposades.</p>
				<p>Última actualització: 21 de gener de 2019.</p>
			</li>
		</ol>
	</div>
	<div class="ccokies">
		<h3><a name="punto3a"></a>POLÍTICA DE COOKIES</h3>
		<p>Aquesta Política de Cookies és part integrant de l’Avís legal i la Política de Privacitat de la pàgina web <a
			 target="_blank" href="https://edelvivesdigital.com/">https://edelvivesdigital.com/</a> (en endavant, el <strong>“Lloc
				web”</strong>).L’accés i la navegació en el Lloc Web, o l’ús delsserveis d’aquest, impliquen l’acceptació dels
			termes i condicions recollits a l’Avís Legal i a la Política dePrivacitat.</p>
		<p>Amb la finalitat de facilitar la navegació pel Lloc Web, <strong>GRUPO EDITORIAL LUIS VIVES</strong>, com a
			titulard’aquest (enendavant, <strong>“GRUPO EDELVIVES”</strong>) amb domicili social a la Ctra. de Madrid km.
			315,700,50.012,Saragossa, amb N.I.F. R5000274 J, li comunica que utilitza cookies o altres dispositius
			d’emmagatzemament i recuperació de dades defuncionalitat similar (en endavant, les <strong>“Cookies”</strong>).</p>
		<p>En aquest sentit i amb l’objectiu de garantir a l’usuari tota la informació necessària per a la seva
			correctanavegació, posem a disposició de l’usuari el següent text informatiu sobre què són les cookies, quina
			tipologia decookies existeixen a la nostra Pàgina Web i com és possible configurar-les o deshabilitar-les.</p>
		<ol>
			<li>
				<p>¿Què és una Cookie?</p>
				<p>Les Cookies són arxius que contenen petites quantitats d’informació que es descarreguen en el dispositiu
					del’usuari quan visita una pàgina web. La seva finalitat principal és reconèixer a l’usuari cada cop que accedeix
					alLloc Web permetent, a més a més, millorar la qualitat i oferir un millor ús del Lloc Web.</p>
				<p>Les Cookies són essencials pel funcionament d’Internet, ja que ajuden, entre altres funcions, a identificar
					iresoldre possibles errors de funcionament del Lloc Web.</p>
			</li>
			<li>
				<p>Tipologia de cookies</p>
				<p>En el present Lloc Web s’utilitzen cookies pròpies i de tercers. Les cookies utilitzades s’emmagatzemen en elseu
					ordinador durant un temps determinat, aquestes poden considerar-se cookies de sessió (si caduquen quans’abandona
					el nostre Lloc Web) o persistents (si la caducitat de la cookies dura més temps). Les cookiesutilitzades en la
					present Pàgina Web són:</p>
				<p><strong>Cookies “estrictament necessàries”</strong>Són aquelles cookies considerades imprescindibles per la
					navegació per la Pàgina Web, ja que faciliten a l’usuarila utilització de les seves prestacions o eines com, per
					exemple, identificar la sessió, accedir a parts d’accésrestringit, recordar els elements que integren una comanda,
					realitzar el procés de compra d’una comanda, entrealtres. Sense aquestes cookies, les funcions mencionades
					quedarien inhabilitades.</p>
				<p><strong>Cookies sobre comportament</strong>
					<p>Són aquelles cookies, objectiu de les quals és la recopilació d’informació relativa als usos de navegació
						del’usuari, com per exemple les pàgines més visitades, els missatges d’error, entre d’altres, amb l’objectiu,
						perpart del responsable, d’introduir millores a la pàgina web base a la informació recopilada per aquestes
						cookies.</p>
					<p>La informació recopilada per aquestes cookies és anònima no podent identificar a l’usuari personalment i,
						pertant, s’utilitza exclusivament pel correcte funcionament de la pàgina web.</p>
				</p>
				<p><strong>Cookies de funcionalitat</strong>
					<p>Són aquelles cookies que permeten recordar a la pàgina web les decisions que l’usuari pren, per
						exemple:s’emmagatzema la ubicació geogràfica de l’usuari per assegurar que es mostra al lloc web indicat per la
						sevaregió, el nom d’usuari, l’idioma, el tipus de navegador mitjançant el qual s’accedeix a la pàgina web,
						entred’altres. L’objectiu és la presentació d’un servei més personalitzat.</p>
					<p>La informació recopilada per aquestes cookies és anònima no podent identificar l’usuari personalment i, per
						tant,s’utilitza exclusivament pel funcionament de la pàgina web.</p>
				</p>
				<p><strong>Cookies de personalització i de publicitat</strong>
					<p>Són aquelles cookies que s’utilitzen per als operadors publicitaris amb el permís del titular de la pàgina
						web,els quals recullen informació sobre les preferències i eleccions de l’usuari en la seva navegació per llocs
						webs,Es dirigeixen a les xarxes socials de publicitat, qui les utilitza per després mostrar a l’usuari
						anuncispersonalitzats a altres llocs web.</p>
				</p>
			</li>
			<li>
				<p>Quadre resum de cookies utilitzades en el Lloc Web</p>
				<p><strong>GRUPO EDELVIVES</strong> utilitza cookies per personalitzar la navegació de l’usuari pels Llocs Web de
					laseva titularitat,cookies d’entrada d’usuari i cookies de sessió per equilibrar la càrrega, les quals estan
					excloses de l’àmbitd’aplicació de l’article 22.2 de la LSSI.</p>
				<p>D’altra banda, <strong>GRUPO EDELVIVES</strong> utilitza cookies d’analítica web per mesurar i analitzar
					lanavegació dels usuarisen els Llocs Web. <strong>GRUPO EDELVIVES</strong> utilitza les dades obtingudes amb la
					finalitat d’introduirmillores en funció del’anàlisi de les dades d’ús que fan els usuaris. L’analítica web no
					permet obtenir informació sobre el nom,cognoms o adreça de correu electrònic o postal de l’usuari. La informació
					obtinguda és la relativa a les visites,la seva durada, el navegador utilitzat, l’operador que presta el serveu,
					l’idioma, el terminal utilitzat o laciutat a què està assignada l’adreça IP.</p>
				<p>De la mateixa manera, <strong>GRUPO EDELVIVES</strong> utilitza cookies de publicitat comportamental per la
					gestiódels espaispublicitaris d’acord amb criteris determinats i cookies de complement (plug-in) dels usuaris
					obtinguts a través del’observació continuada dels seus hàbits de navegació, cosa que permet mostrar publicitat en
					funció del mateix.</p>
				<table>
					<thead>
						<tr>
							<td>NOMBRE</td>
							<td>TITULAR</td>
							<td>FINALIDAD</td>
							<td>DURACIÓN</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td><strong>_ga</strong></td>
							<td>Google</td>
							<td>Aquesta cookie d’origen conté un identificador anònim utilitzat per distingir usuaris.</td>
							<td>2 anys o actualització</td>
						</tr>
						<tr>
							<td><strong>_gat</strong></td>
							<td>Google</td>
							<td>Aquesta cookie d’origen conté un identificador per diferenciar d’entre els diversos objectes de
								seguimentcreats en la sessió.</td>
							<td>10 minuts des de creació o modificació</td>
						</tr>
						<tr>
							<td><strong>_gid</strong></td>
							<td>Google</td>
							<td>Aquesta cookie s’utilitza per distingir usuaris.</td>
							<td>24 hores</td>
						</tr>
						<tr>
							<td><strong>NID</strong></td>
							<td>Google</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>G_ENABLED_IDPS</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>ci_session_i2c</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>SIDCC</strong></td>
							<td>Google</td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>OCAK</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>GAPS</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>CheckConnectionTempCookie529</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>clusterBAK</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>clusterD</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>edelvives</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>PrestaShop-b78d98b21a70688c13d5c9c7f255a804</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>PHPSESSID</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
						<tr>
							<td><strong>endprom</strong></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</li>
			<li>
				<p>Configuració de l’usuari per evitar Cookies</p>
				<p>En compliment de la normativa legal vigent, <strong>GRUPO EDELVIVES</strong> posa a disposició dels usuaris
					delLloc Web lainformació que els permeti configurar el seu navegador/navegadors d’Internet per mantenir la seva
					privacitat iseguretat en relació a les Cookies. Per això, es facilita la informació i els enllaços als llocs de
					suportoficials dels principals navegadors perquè cada usuari pugui decidir si desitja o no acceptar l’ús de
					Cookies.</p>
				<p>Així, és possible bloquejar les Cookies a través de les eines de configuració dels navegadors, o bé, cada
					usuaripot configurar el seu navegador perquè l’avisi quan un servidor vulgui guardar una Cookie:</p>
				<ol style="list-style-type: lower-alpha;">
					<li>
						<p>Si utilitza Microsoft Internet Explorer, trobarà l’opció en el menú Eines > Opcions d’Internet > Privacitat
							>Configuració. Per saber més visiti <a target="_blank" href="http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies">http://windows.microsoft.com/es-es/windows-vista/block-or-allow-Cookies</a>
							i <a target="_blank" href="http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9">http://windows.microsoft.com/es-es/windows7/how-to-manage-Cookies-in-internet-explorer-9</a>.</p>
					</li>
					<li>
						<p>Si utilitza Firefox, trobarà l’opció en el menú Eines > Opcions > Privacitat > Cookies. Per saber més visiti
							<a target="_blank" href="http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we">http://support.mozilla.org/es/kb/habilitar-y-deshabilitar-Cookies-que-los-sitios-we</a>.</p>
					</li>
					<li>
						<p>Si utilitza Chrome, a la secció d’Opcions > Opcions avançades > Privacitat. Per saber més <a target="_blank"
							 href="https://support.google.com/accounts/answer/61416?hl=es">https://support.google.com/accounts/answer/61416?hl=es</a></p>
					</li>
					<li>
						<p>Si utilitza Opera, a l’opció de Seguretat i Privacitat, podrà configurar el navegador. Per saber més visiti <a
							 target="_blank" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></p>
					</li>
					<li>
						<p>Si utilitza Safari, trobarà l’opció en el menú de Preferències/Privacitat. Més informació a: <a target="_blank"
							 href="http://support.apple.com/kb/HT1677?viewlocale=es_ES">http://support.apple.com/kb/HT1677?viewlocale=es_ES</a></p>
					</li>
				</ol>
			</li>
		</ol>
		<p>Última actualització: 21 de gener de 2019.</p>
	</div>
</div>`;

export default iframeCode;
