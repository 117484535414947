import { useContext, useEffect } from "react";
import globalContext from "../context/Context";
import { useTranslation } from "react-i18next";

export default () => {
  const { user } = useContext(globalContext);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (Object.keys(user).length) {
      if (!user.language) {
        user.language = "ES";
      }
      user.exists = true;
      i18n.changeLanguage(user.language.toLowerCase());
    }
    return () => {};
  }, []);

  function getCodeLanguage() {
    let code = "es";
    switch (user.language.toLowerCase()) {
      case "ar":
        code = "es-ar";
        break;
      case "ca":
        code = "ca-es";
        break;
      case "mx":
        code = "es-mx";
        break;
      case "vsv":
        code = "vsv";
        break;
      case "gl":
        code = "gl-es";
        break;
      case "eu":
        code = "eu-es";
        break;
      case "en":
        code = "en-us";
        break;
    }
    return code;
  }

  return { exists: Object.keys(user).length > 0, getCodeLanguage };
};
