import INotify from "../types/INotify";
import Observable from "./Observable";

export default class Observer extends Observable {
  callback(props: INotify) {}

  onEvent(props: INotify) {
    this.callback(props);
  }

  notify(props: INotify) {
    this.notifyObservers(props);
  }
}
