export default function Paragraph(props: any) {
  const extras = props.extras ? props.extras.split(",").join(" ") : "";
  const link = props.link ? (
    <a target="_blank" href={props.link}>
      {props.promptLink}
    </a>
  ) : (
    ""
  );
  return (
    <p className={`paragraph-text ${extras}`}>
      {props.text} {link}
    </p>
  );
}
