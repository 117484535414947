import { ChangeEvent, useEffect, useState } from "react";
import useManager from "../../../../../hooks/useManager";
import useWindowDimensions from "../../../../../hooks/useWindowDimensions";

export default function useFind(props: any) {
  const [expand, setExpand] = useState<boolean>(false);
  const [rotate, setRotate] = useState<boolean>(false);
  const manager = useManager();
  const { isPortrait } = useWindowDimensions();

  async function onChange(e: ChangeEvent<HTMLInputElement>) {
    props.onFindChange(e.target.value);
  }

  async function onClear() {
    props.onFindChange("");
  }

  async function onClickExpand() {
    setExpand(!expand);

    manager.notify({
      expand: expand,
    });
  }

  useEffect(() => {
    setRotate(isPortrait);
    if (!isPortrait) {
      setExpand(false);
    }
  }, [isPortrait]);

  return { onChange, onClear, onClickExpand, expand, rotate };
}
