import Flex from "../flex/Flex";
import { ReactComponent as MenuIcon } from "../../assets/icons/mini.svg";
import Button from "../buttons/Button";
import useMenuCard from "./useMenuCard";
import React from "react";

interface IMenuCard {
  t: any;
  onClickRemoveFromMyBooks: () => void;
}

export default function MenuCard(props: IMenuCard) {
  const { onClick, open, onClickRemove, myRef } = useMenuCard(props);
  return (
    <Flex customClass={"menuCard"}>
      <Button onClick={onClick} icon={<MenuIcon />} />
      {open && (
        <div ref={myRef}>
          <Flex customClass={"mt10 mini"}>
            <Button
              onClick={onClickRemove}
              text={props.t("remove_from_my_books")}
            />
          </Flex>
        </div>
      )}
    </Flex>
  );
}
