import Button from "../buttons/Button";
import Flex from "../flex/Flex";

interface IHtmlView {
  accept: string;
  onClickAccept?: () => void;
  children?: any;
  show: Boolean;
}

export default function HtmlView(props: IHtmlView) {
  return (
    <Flex customClass={`htmlview ${props.show}`}>
      <Flex customClass={"middle"}>
        <Flex customClass={`column view`}>
          <Flex>{props.children}</Flex>
          <Flex customClass={"footer"}>
            <Button
              onClick={props.onClickAccept}
              custom="orange"
              text={props.accept}
            />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}
