import Button from "../../../commons/buttons/Button";
import Flex from "../../../commons/flex/Flex";
import Form from "../../../commons/form/Form";
import Paragraph from "../../../commons/paragraph/Paragraph";
import ILogin from "../../../types/ILogin";
import IRequestStatus from "../../../types/IRequestStatus";

interface IUserLogin {
  submit: (data: ILogin) => void;
  handleSubmit: any;
  register: any;
  t: any;
  requestStatus: IRequestStatus;
}

export default function View(props: IUserLogin) {
  const onSubmitHandle = (data: ILogin) => {
    props.submit(data);
  };

  return (
    <Flex customClass={"texture center"}>
      <Flex customClass={"center"}>
        <div className="login">
          <div className="form">
            <Form gap="8px" onSubmit={props.handleSubmit(onSubmitHandle)}>
              <div className="title">{props.t("login_legend")}</div>
              <div className="wrap user">
                <input
                  disabled={props.requestStatus === IRequestStatus.PENDING}
                  {...props.register("username", { required: true })}
                  type="text"
                  placeholder={props.t("username_placeholder")}
                />
              </div>
              <div className="wrap password">
                <input
                  disabled={props.requestStatus === IRequestStatus.PENDING}
                  {...props.register("password", { required: true })}
                  type="password"
                  placeholder={props.t("password_placeholder")}
                />
              </div>

              <Button
                disabled={props.requestStatus === IRequestStatus.PENDING}
                type="submit"
                text={props.t("login_submit")}
              />
              <div className="divider"></div>
              <Paragraph
                link={props.t("urlSuscribe")}
                promptLink={props.t("signin_link")}
                text={props.t("signin_legend")}
              />
            </Form>
          </div>
        </div>
      </Flex>
    </Flex>
  );
}
