import IUser from "../../types/ILogin";
import request from "../client";

const post = (data: IUser) => {
  return request.post(`/login`, data);
};

const get = (session: string) => {
  return request.get(`/user`, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": session,
    },
  });
};

const session = () => {
  return request.get(`/sessions`, {
    headers: {
      "Content-type": "application/json",
    },
  });
};

const logout = (session: string) => {
  return request.post(`/logout`, {
    headers: {
      "Content-type": "application/json",
      "X-i2c-session": session,
    },
  });
};

export default {
  get,
  post,
  session,
  logout,
};
