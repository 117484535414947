import es from "../../assets/imgs/flags/es.png";
import en from "../../assets/imgs/flags/en.png";
import ca from "../../assets/imgs/flags/ca.png";
import gl from "../../assets/imgs/flags/gl.png";
import ar from "../../assets/imgs/flags/ar.png";
import mx from "../../assets/imgs/flags/mx.png";
import pr from "../../assets/imgs/flags/pr.png";
import va from "../../assets/imgs/flags/va.png";
import eu from "../../assets/imgs/flags/eu.png";
import word from "../../assets/imgs/flags/wo.png";

interface IFlag {
  lang?: string;
}

enum Flags {
  es = "es-ES",
  en = "en-US",
  ca = "ca-ES",
  gl = "gl-ES",
  ar = "es-AR",
  mx = "es-MX",
  pr = "es-PR",
  eu = "eu-ES",
  vsv = "vsv",
}

export default function Flag(props: IFlag) {
  const getImg = () => {
    switch (props.lang) {
      case Flags.es:
        return es;
      case Flags.en:
        return en;
      case Flags.ca:
        return ca;
      case Flags.gl:
        return gl;
      case Flags.ar:
        return ar;
      case Flags.mx:
        return mx;
      case Flags.pr:
        return pr;
      case Flags.eu:
        return eu;
      case Flags.vsv:
        return va;
      default:
        return word;
    }
  };

  return (
    <div className="flag">
      <img src={getImg()} alt="image" />
    </div>
  );
}
