import { useState } from "react";

interface ITab {
  index: number;
  selected: boolean;
  text: string;
}

interface ITabs {
  t: any;
  bookClassClick: () => void;
  catalogClick: () => void;
  myBooksClick: () => void;
}

export default function useTabs(props: ITabs) {
  const [tabs, setTabs] = useState<ITab[]>([
    { index: 0, selected: true, text: props.t("catalog_header") },
    { index: 1, selected: false, text: props.t("filter_teacher_books_option") },
    { index: 2, selected: false, text: props.t("filter_my_books_option") },
  ]);

  async function onClickTab(index: number) {
    tabs.forEach((item) => (item.selected = false));
    tabs[index].selected = true;
    switch (index) {
      case 0:
        props.catalogClick();
        break;
      case 1:
        props.bookClassClick();
        break;
      default:
        props.myBooksClick();
    }
  }

  async function onClickLeftArrow(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) {
    const temp = tabs.find((item) => item.selected)!.index - 1;
    onClickTab(temp < 0 ? tabs.length - 1 : temp);
  }

  async function onClickRightArrow(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) {
    const temp = tabs.find((item) => item.selected)!.index + 1;
    onClickTab(temp >= tabs.length ? 0 : temp);
  }

  return {
    tabs,
    onClickTab,
    onClickLeftArrow,
    onClickRightArrow,
    current: tabs.find((item) => item.selected)!.index,
  };
}
