import { useEffect, useRef, useState } from "react";
import useOutside from "../../hooks/useOutside";

interface IMenuCard {
  t: any;
  onClickRemoveFromMyBooks: () => void;
}

export default function useMenuCard(props: IMenuCard) {
  const myRef = useRef(null);
  const outSide = useOutside({ el: myRef });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (outSide) {
      setOpen(false);
    }
  }, [outSide]);

  async function onClick(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) {
    setOpen(!open);
    event.stopPropagation();
  }

  async function onClickRemove(
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.TouchEvent<HTMLButtonElement>
  ) {
    event.preventDefault();
    event.stopPropagation();
    setOpen(false);
    props.onClickRemoveFromMyBooks();
  }

  return { onClick, open, onClickRemove, myRef };
}
