import useLogin from "./useLogin";
import View from "./components/view";
import IRequestStatus from "../../types/IRequestStatus";
import Loader from "../../commons/loader/Loader";

export default function Login() {
  const { t, submit, register, handleSubmit, requestStatus } = useLogin();

  if (requestStatus === IRequestStatus.WAIT) {
    return <Loader />;
  }
  return (
    <View
      t={t}
      submit={submit}
      register={register}
      handleSubmit={handleSubmit}
      requestStatus={requestStatus}
    />
  );
}
