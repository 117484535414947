import { useEffect, useState } from "react";

interface IOutSide {
  el: any;
  cls?: string;
}

export default (props: IOutSide) => {
  const [outSide, setOutSide] = useState<boolean>(false);
  useEffect(() => {
    function handleClickOutside(event: TouchEvent | MouseEvent) {
      setOutSide(props.el.current && !props.el.current.contains(event.target));
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props.el]);

  return outSide;
};
