import IClassRoom from "../../types/IClassRoom";
import Checkbox from "../checkbox/Checkbox";
import Flex from "../flex/Flex";
import useClassList from "./useClassList";
import defaultImg from "../../assets/imgs/avatar_clase.jpg";

interface IClass {
  data: IClassRoom;
  guid: string;
}

export default function ClassList(props: IClass) {
  const { onChange, data } = useClassList(props);
  return (
    <Flex customClass={"list"}>
      <Flex customClass={"gap10"}>
        <div className="icon">
          <img src={data.avatar || defaultImg} title="avatar" />
        </div>
        <div>
          <Flex customClass={"column"}>
            <p className="data">{data.name}</p>
            <p>{data.education_level}</p>
            <p>{data.amount_students}</p>
          </Flex>
        </div>
      </Flex>
      <div>
        <Checkbox
          onChange={() => onChange(data)}
          checked={data.is_classroom_book}
          guid={props.guid}
        />
      </div>
    </Flex>
  );
}
