import { PackagesMetada } from "../types/IPackages";
import { RequestedBook } from "../types/IRequestBook";
import IUser from "../types/IUser";

export default function useSort() {
  function sortPacakgesByMoreRecents(data: PackagesMetada[]) {
    return data.sort((a: PackagesMetada, b: PackagesMetada) => {
      const faa = new Date(a.created_at).getTime();
      const fbb = new Date(b.created_at).getTime();

      return faa > fbb ? -1 : faa < fbb ? 1 : 0;
    });
  }

  function sortPacakgesByMoreReadings(data: PackagesMetada[]) {
    return data.sort((a: PackagesMetada, b: PackagesMetada) => {
      const faa = parseInt(a.readings);
      const fbb = parseInt(b.readings);

      return faa > fbb ? -1 : faa < fbb ? 1 : 0;
    });
  }

  function sortPacakgesAlphabetical(data: PackagesMetada[], type: string) {
    return data.sort((a: PackagesMetada, b: PackagesMetada) => {
      if (!a.title) a.title = "A";
      if (!b.title) b.title = "A";

      const fa = a.title
        .toLowerCase()
        .trim()
        .replace(/[!@#$%^&*?¿!¡]/g, "");
      const fb = b.title
        .toLowerCase()
        .trim()
        .replace(/[!@#$%^&*?¿!¡]/g, "");

      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: "base",
      });

      return type === "2" ? collator.compare(fa, fb) : collator.compare(fb, fa);
    });
  }

  function preparePackageMetadata(resCase: any, result: any, user: IUser) {
    result.data.data.packages_metadata.forEach((item: PackagesMetada) => {
      const isVoted = resCase.data.requestedBooks.filter(
        (res: RequestedBook) => {
          return res.guid === item.package_identifier;
        }
      );

      item.voted = isVoted.length > 0;

      item.withCase =
        item.typologies.filter(
          (typo) =>
            typo.guid ===
            process.env[
              `REACT_APP_BOOK_WITH_CASES_GUID_${process.env.REACT_APP_ENVIROMENT}`
            ]
        ).length > 0;

      item.userPackage =
        (result.data.data.user_packages &&
          result.data.data.user_packages.filter(
            (upack: any) => upack.package_guid === item.guid
          ).length > 0) ||
        false;
      item.classBook = user.recommended_books.includes(item.package_identifier);

      const commons = [
        "education_levels",
        "typologies",
        "values",
        "subjects",
      ] as const;

      commons.forEach((common) => {
        item[common].forEach((a: any) => {
          result.data.data[common].forEach((c: any) => {
            if (a.guid === c.guid) {
              c.show = true;
            }
          });
        });
      });

      result.data.data.genres.forEach((c: any) => {
        if (item.genre_guid === c.guid) {
          c.show = true;
        }
      });

      result.data.data.ages.forEach((c: any) => {
        if (item.age_guid === c.guid) {
          c.show = true;
        }
      });

      result.data.data.languages.forEach((c: any) => {
        if (item.language_guid === c.guid) {
          c.show = true;
        }
      });
    });

    return result.data.data;
  }

  return {
    sortPacakgesByMoreRecents,
    sortPacakgesAlphabetical,
    sortPacakgesByMoreReadings,
    preparePackageMetadata,
  };
}
