import { useContext, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import globalContext from "../../context/Context";
import IUser from "../../types/IUser";
import IRequestStatus from "../../types/IRequestStatus";

export default function useGuest() {
  const navigate = useNavigate();
  const { setUser } = useContext(globalContext);

  useEffect(() => {
    const user = {
      guid: "guest",
      is_verified: false,
      name: "Guest",
      surname: "",
      avatar:
        "https://plan-lector-dev.ink2cloud.com/i2c/imgs/default-profile.png",
      rol: "guest",
      session_id: "guest",
      recommended_books: [],
    };
    localStorage.setItem("tatum_rps", JSON.stringify(user));
    setUser && setUser(user as IUser);
    navigate("/");
  }, []);
  return { status: IRequestStatus.WAIT };
}
