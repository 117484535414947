import { useEffect, useState } from "react";
import INotify from "../../types/INotify";
import useManager from "../../hooks/useManager";
import Observer from "../../manager/Observer";

export default function useToast() {
  const [message, setMessage] = useState({} as INotify);
  const manager = useManager();

  useEffect(() => {
    const observer = new Observer();
    manager.get().addObserver(observer);
    observer.callback = (props: INotify) => {
      setMessage(props);
      if (props.hideAfter) {
        setTimeout(() => {
          setMessage({ msg: "" });
        }, props.time || 2000);
      }
      if (props.wait) {
        setTimeout(() => {
          if (props.callback) props.callback();
        }, props.time || 200);
      }
    };
    return () => {
      manager.get().removeObserver(observer);
    };
  }, []);

  return {
    data: message,
  };
}
