import Input from "../../../../../commons/input/Input";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import { ReactComponent as FindIcon } from "../../../../../assets/icons/find.svg";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow.svg";
import Button from "../../../../../commons/buttons/Button";
import Flex from "../../../../../commons/flex/Flex";
import useFind from "./useFind";

interface IFind {
  onFindChange: (value: string) => {};
  value: string;
  placeHolder?: string;
}

export default function Find(props: IFind) {
  const { onChange, onClear, onClickExpand, expand, rotate } = useFind(props);

  return (
    <Flex customClass="find">
      <Button
        disabled={(rotate && !expand) || !rotate}
        onClick={onClickExpand}
        type="button"
        icon={<ArrowIcon />}
      />
      <Flex customClass={`wrapper${expand ? " expand" : ""}`}>
        <Button
          disabled={expand}
          onClick={() => rotate && onClickExpand()}
          type="button"
          icon={<FindIcon width={30} />}
        />

        <Input
          placeHolder={props.placeHolder || ""}
          type="text"
          value={props.value}
          onChange={onChange}
        />
        <Button
          onClick={onClear}
          disabled={!props.value.length}
          type="button"
          icon={<CloseIcon width={24} />}
        />
      </Flex>
    </Flex>
  );
}
