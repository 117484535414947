import { useEffect, useRef, useState } from "react";
import ca from "./legal/ca";
import es from "./legal/es";
import eu from "./legal/eu";
import gl from "./legal/gl";
import vsv from "./legal/vsv";

export default function useFooter(lang: string) {
  const myRef = useRef<HTMLDivElement>(null);
  const cookieRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState<Boolean>(false);
  const [showCookie, setShowCookie] = useState<Boolean>(false);
  const [html, setHtml] = useState<any>();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env[
      `REACT_APP_TATUM_COOKIES_${process.env.REACT_APP_ENVIROMENT}`
    ] as string;

    script.id = "CookieDeclaration";
    script.async = true;
    if (cookieRef.current) {
      cookieRef.current.appendChild(script);
    }

    return () => {
      try {
        cookieRef.current?.removeChild(script);
      } catch (err) {}
    };
  }, []);

  useEffect(() => {
    switch (lang.toLowerCase()) {
      case "es":
        setHtml(es);
        break;
      case "eu":
        setHtml(eu);
        break;
      case "gl":
        setHtml(gl);
        break;
      case "ca":
        setHtml(ca);
        break;
      default:
        setHtml(vsv);
    }
  }, []);

  function privacyHandler() {
    setOpen(true);

    setTimeout(() => {
      if (myRef.current) {
        const box = myRef.current.querySelector(".pprivacy") as HTMLDivElement;
        moveScroll(box.offsetTop + 200);
      }
    }, 200);
  }

  function legalHandler() {
    setOpen(true);
    moveScroll(0);
  }

  function moveScroll(top: number) {
    setTimeout(() => {
      if (myRef.current) {
        myRef.current.querySelector(".view")!.scrollTo({
          top: top,
          behavior: "smooth",
        });
      }
    }, 200);
  }

  function cookieHandler() {
    setOpen(true);
    setShowCookie(true);
  }

  function closeView() {
    setOpen(false);
    setShowCookie(false);
  }

  return {
    myRef,
    cookieRef,
    privacyHandler,
    closeView,
    open,
    legalHandler,
    cookieHandler,
    showCookie,
    html,
  };
}
